<template>
  <header class="header-menu" :class="{ 'dark-theme': isDark }">
    <!-- 左侧品牌区域 -->
    <div class="header-brand" @click="handleNavigate('/main/cardMenu')" style="cursor: pointer;">
      <div class="logo">
        <img src="@/assets/logo.png" alt="Logo" class="logo-img" />
        <span class="system-name">沐昀网上阅卷平台</span>
      </div>
    </div>

    <!-- 中间导航区域 - 在移动端隐藏 -->
    <nav class="header-nav" :class="{ 'hidden-on-mobile': true }">
      <div 
        v-for="(item, index) in treeMenu" 
        :key="item.permission_id"
        class="nav-item"
        :class="{ 'active': isMenuActive(item.permission_name) }"
        @click="handleNavigate(updateRoutePath(item.permission_url))"
      >
        <!-- 添加图标 -->
        <i-menu-unfold v-if="!getMenuIcon(item.permission_name)" theme="outline" size="18" :fill="isMenuActive(item.permission_name) ? 'var(--primary-color)' : getIconColor(item.permission_name)"/>
        <component :is="getMenuIcon(item.permission_name)" v-else theme="outline" size="18" :fill="isMenuActive(item.permission_name) ? 'var(--primary-color)' : getIconColor(item.permission_name)"/>
        <span class="nav-item-text">{{ item.permission_name }}</span>
        
        <!-- 子菜单项 -->
        <div class="submenu" v-if="item.children && item.children.length > 0">
          <div 
            v-for="child in item.children" 
            :key="child.permission_id"
            class="submenu-item"
            @click.stop="handleNavigate(updateRoutePath(child.permission_url))"
          >
            <!-- 添加子菜单图标 -->
            <component :is="getSubMenuIcon(child.permission_name, item.permission_name)" theme="outline" size="16" :fill="getSubMenuIconColor(child.permission_name, item.permission_name)"/>
            {{ child.permission_name }}
          </div>
        </div>
      </div>
      
      <!-- 考试管理菜单项 -->
      <div 
        v-if="examMgeMenuExist"
        class="nav-item"
        :class="{ 'active': isExamMenuActive() }"
        @click="handleNavigate('/main/exam')"
      >
        <i-bookmark theme="outline" size="18" :fill="isExamMenuActive() ? 'var(--primary-color)' : '#eb2f96'"/>
        <span class="nav-item-text">考试管理</span>
      </div>
      
      <!-- 教师阅卷菜单项 -->
      <div 
        v-if="teacherMarkingMenuExist"
        class="nav-item"
        :class="{ 'active': isMarkingMenuActive() }"
        @click="handleNavigate('/main/marking')"
      >
        <i-read-book theme="outline" size="18" :fill="isMarkingMenuActive() ? 'var(--primary-color)' : '#722ed1'"/>
        <span class="nav-item-text">教师阅卷</span>
      </div>
    </nav>

    <!-- 右侧功能区 -->
    <div class="header-actions">
      <!-- 移动端菜单按钮 -->
      <div class="mobile-menu-btn" @click="isDrawerOpen = true">
        <i-hamburger-button theme="outline" size="24" fill="currentColor"/>
      </div>
      
      <!-- 主题切换按钮 -->
      <div class="action-item" @click="handleToggleTheme">
        <i-brightness v-if="!isDark" theme="outline" size="24" fill="currentColor"/>
        <i-sleep v-else theme="outline" size="24" fill="currentColor"/>
      </div>
      
      <!-- 用户信息和下拉菜单 -->
      <div class="user-info" ref="userInfo">
        <div class="avatar-wrapper" @click="isUserMenuOpen = !isUserMenuOpen">
          <div class="avatar">{{ user.name ? user.name.substring(0, 1) : 'U' }}</div>
          <div class="user-info-text">
            <span class="user-name">{{ user.name }}</span>
            <div class="role-switch-hint">
              <span class="current-role">{{ getCurrentRoleText() }}</span>
              <span class="role-switch-text">点击切换角色</span>
            </div>
          </div>
          <i-down theme="outlined" size="16" fill="currentColor" />
        </div>
        
        <!-- 用户下拉菜单 -->
        <div class="user-dropdown" v-show="isUserMenuOpen" @click.stop>
          <!-- 角色切换下拉菜单 -->
          <div class="dropdown-section" v-if="unitRoles && unitRoles.length > 1">
            <div class="dropdown-title">切换角色</div>
            <div 
              v-for="unitRole in unitRoles" 
              :key="`${unitRole.user_type}-${unitRole.unit_id}`"
              class="dropdown-item role-item"
              :class="{ 'active': `${unitRole.user_type}-${unitRole.unit_id}` === nowUserUnitRole }"
              @click="handleChangeRole(unitRole.user_type, unitRole.unit_id)"
            >
              <i-user theme="outline" size="18" fill="currentColor" />
              {{ getUserTypeName(unitRole.user_type) }} - {{ unitRole.unit_name || unitRole.name || '无单位' }}
            </div>
          </div>
          
          <div class="dropdown-divider"></div>
          
          <!-- 账户操作 -->
          <div class="dropdown-section">
            <div class="dropdown-item" @click="handleEditPassword">
              <i-lock theme="outline" size="18" fill="currentColor" />
              修改密码
            </div>
            <div class="dropdown-item logout-item" @click="handleLogout">
              <i-logout theme="outline" size="18" fill="currentColor" />
              退出登录
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>

  <!-- 移动端侧边抽屉菜单 -->
  <el-drawer
    v-model="isDrawerOpen"
    title="菜单导航"
    direction="ltr"
    size="80%"
    :with-header="true"
    :class="{ 'dark-drawer': isDark }"
  >
    <div class="drawer-content">
      <!-- 抽屉中的菜单列表 -->
      <div class="drawer-menu">
        <div
          v-for="item in treeMenu"
          :key="item.permission_id"
          class="drawer-menu-item"
          :class="{ 'active': isMenuActive(item.permission_name) }"
        >
          <div 
            class="drawer-item-header" 
            @click="item.children && item.children.length > 0 ? (expandedMenus[item.permission_id] = !expandedMenus[item.permission_id]) : handleNavigateAndClose(updateRoutePath(item.permission_url))"
          >
            <!-- 菜单项图标 -->
            <div class="drawer-item-icon">
              <component :is="getMenuIcon(item.permission_name)" v-if="getMenuIcon(item.permission_name)" theme="outline" size="22" :fill="isMenuActive(item.permission_name) ? 'var(--primary-color)' : getIconColor(item.permission_name)"/>
              <i-menu-unfold v-else theme="outline" size="22" :fill="isMenuActive(item.permission_name) ? 'var(--primary-color)' : getIconColor(item.permission_name)"/>
            </div>
            <span class="drawer-item-title">{{ item.permission_name }}</span>
            <!-- 展开/收起箭头 -->
            <i-down 
              v-if="item.children && item.children.length > 0" 
              class="expand-icon" 
              :class="{ 'expanded': expandedMenus[item.permission_id] }"
              theme="outline" 
              size="16" 
              fill="currentColor"
            />
          </div>
          
          <!-- 子菜单 -->
          <div 
            v-if="item.children && item.children.length > 0" 
            class="drawer-submenu"
            :class="{ 'expanded': expandedMenus[item.permission_id] }"
          >
            <div
              v-for="child in item.children"
              :key="child.permission_id"
              class="drawer-submenu-item"
              @click="handleNavigateAndClose(updateRoutePath(child.permission_url))"
            >
              <component :is="getSubMenuIcon(child.permission_name, item.permission_name)" theme="outline" size="18" :fill="getSubMenuIconColor(child.permission_name, item.permission_name)"/>
              <span>{{ child.permission_name }}</span>
            </div>
          </div>
        </div>
        
        <!-- 考试管理菜单项 -->
        <div
          v-if="examMgeMenuExist"
          class="drawer-menu-item"
          :class="{ 'active': isExamMenuActive() }"
          @click="handleNavigateAndClose('/main/exam')"
        >
          <div class="drawer-item-header">
            <div class="drawer-item-icon">
              <i-bookmark theme="outline" size="22" :fill="isExamMenuActive() ? 'var(--primary-color)' : '#eb2f96'"/>
            </div>
            <span class="drawer-item-title">考试管理</span>
          </div>
        </div>
        
        <!-- 教师阅卷菜单项 -->
        <div
          v-if="teacherMarkingMenuExist"
          class="drawer-menu-item"
          :class="{ 'active': isMarkingMenuActive() }"
          @click="handleNavigateAndClose('/main/marking')"
        >
          <div class="drawer-item-header">
            <div class="drawer-item-icon">
              <i-read-book theme="outline" size="22" :fill="isMarkingMenuActive() ? 'var(--primary-color)' : '#722ed1'"/>
            </div>
            <span class="drawer-item-title">教师阅卷</span>
          </div>
        </div>
      </div>
    </div>
  </el-drawer>
</template>

<script>
export default {
  name: 'HeaderMenu',
  props: {
    isDark: {
      type: Boolean,
      default: false
    },
    user: {
      type: Object,
      required: true
    },
    unit: {
      type: Object,
      required: true
    },
    unitRoles: {
      type: Array,
      default: () => []
    },
    userTypesMap: {
      type: Object,
      default: () => ({})
    },
    treeMenu: {
      type: Array,
      default: () => []
    },
    activeRoute: {
      type: String,
      default: ''
    },
    examMgeMenuExist: {
      type: Boolean,
      default: false
    },
    teacherMarkingMenuExist: {
      type: Boolean,
      default: false
    },
    nowUserUnitRole: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isUserMenuOpen: false,
      activeParentMenus: [],
      isDrawerOpen: false,
      expandedMenus: {}, // 控制抽屉菜单展开/收起状态
      iconMap: {
        // 主菜单图标映射
        '成绩分析': 'i-analysis',
        '信息管理': 'i-info',
        '系统管理': 'i-setting',
        '考试管理': 'i-bookmark',
        '教师阅卷': 'i-read-book',
        '首页': 'i-home',
        '主页': 'i-home',
        '卡片菜单': 'i-menu',
        // 可以根据需要添加更多菜单图标映射
      },
      // 子菜单图标映射
      subMenuIconMap: {
        // 为 "信息管理" 下的子菜单配置不同图标
        '信息管理': {
          '学校管理': 'i-school',
          '经销商管理': 'i-building-two',
          '经销商及学校管理': 'i-city',
          '班级管理': 'i-people',
          '教师管理': 'i-user',
          '用户管理': 'i-people-plus',
          '学生管理': 'i-peoples',
          '年级管理': 'i-stethoscope',
          '科目管理': 'i-book-open',
          '学生成绩': 'i-chart-pie',
          '试卷上传': 'i-upload',
          '阅卷分析': 'i-chart-histogram',
          '报表导出': 'i-export',
          '其他设置': 'i-setting-config'
        },
        // 为 "系统管理" 下的子菜单配置不同图标
        '系统管理': {
          '权限菜单管理': 'i-list-add',
          '角色管理': 'i-user-business',
          '角色权限分配': 'i-permissions',
          '用户管理': 'i-user-positioning',
          '用户标签分配': 'i-tag-one',
          '标签设置': 'i-tag',
          '菜单管理': 'i-list-add',
          '权限管理': 'i-permissions',
          '登录日志': 'i-login',
          '系统日志': 'i-log',
          '日志管理': 'i-log',
          'Redis监控信息': 'i-api',
          '测试页面': 'i-experiment',
          '系统配置': 'i-config',
          '字典管理': 'i-book-one',
          '数据备份': 'i-database-lock',
          '数据恢复': 'i-database-enter',
          '缓存管理': 'i-storage-card-one',
          '系统监控': 'i-cpu'
        },
        // 为 "成绩分析" 下的子菜单配置不同图标
        '成绩分析': {
          '班级分析': 'i-chart-graph',
          '成绩趋势': 'i-trend',
          '数据导出': 'i-download-four',
          '成绩排名': 'i-ranking',
          '成绩分布': 'i-chart-pie-one',
          '对比分析': 'i-chart-stock',
          '综合评价': 'i-chart-ring',
          '成绩分析详情': 'i-chart-line',
          '常用数据报表': 'i-chart-histogram'
        },
        // 为 "考试管理" 下的子菜单配置图标
        '考试管理': {
          '试卷扫描': 'i-scan',
          '科目模板配置': 'i-template',
          '科目题干配置': 'i-bookmark-one',
          '客观题答案设置': 'i-check',
          '分配权限': 'i-permissions',
          '阅卷数据校核与分析': 'i-chart-line-area',
          '参考学生管理': 'i-user-business',
          '考场管理': 'i-classroom',
          '考场编排': 'i-layout',
          '试卷上传': 'i-upload-web',
          '学生参考情况校核': 'i-check-correct',
          '缓冲区管理': 'i-memory',
          '数据管理': 'i-data-all',
          '成品卷抽查': 'i-document-folder',
          '异常卷管理': 'i-error-computer',
          '异常卷初始化': 'i-refresh',
          '标准卷管理': 'i-file-display',
          '样卷管理': 'i-document-folder',
          '学生试题管理': 'i-bookmark-one',
          '评卷进度': 'i-schedule',
          '题组工作量': 'i-workbench',
          '用户工作量': 'i-user-business',
          '用户分配建议': 'i-application-menu',
          '质量监控统计': 'i-chart-histogram',
          '复评率统计': 'i-percent',
          '分数分布统计': 'i-distribution',
          '自评卷查看': 'i-eyes',
          '标准卷查看': 'i-preview-open',
          '用户考核统计': 'i-user-to-user',
          '成绩校核': 'i-check-correct',
          '成绩管理': 'i-chart-graph',
          '客观题校核': 'i-check-one'
        },
        // 为 "教师阅卷" 下的子菜单配置图标 
        '教师阅卷': {
          // 相应的子菜单图标
          '阅卷页面': 'i-read',
          '阅卷统计': 'i-chart-pie'
        }
      },
      // 图标颜色映射
      iconColorMap: {
        '成绩分析': '#2f54eb',
        '信息管理': 'green', // 绿色
        '系统管理': '#fa8c16',
        '考试管理': '#eb2f96',
        '教师阅卷': '#722ed1',
        '主页': '#52c41a'
      },
      // 子菜单图标颜色映射
      subMenuColorMap: {
        '信息管理': {
          '学校管理': '#1890ff',
          '经销商管理': '#1677ff',
          '经销商及学校管理': '#0958d9',
          '班级管理': '#0958d9',
          '教师管理': '#1677ff',
          '用户管理': '#0958d9',
          '学生管理': '#1677ff',
          '年级管理': '#0958d9',
          '科目管理': '#1677ff',
          '学生成绩': '#0958d9',
          '试卷上传': '#1890ff',
          '阅卷分析': '#0958d9',
          '报表导出': '#1677ff',
          '其他设置': '#0958d9'
        },
        '系统管理': {
          '权限菜单管理': '#fa8c16',
          '角色管理': '#d46b08',
          '角色权限分配': '#fa8c16',
          '用户管理': '#d46b08',
          '用户标签分配': '#fa8c16',
          '标签设置': '#d46b08',
          '菜单管理': '#fa8c16',
          '权限管理': '#d46b08',
          '登录日志': '#fa8c16',
          '系统日志': '#d46b08',
          '日志管理': '#fa8c16',
          'Redis监控信息': '#d46b08',
          '测试页面': '#fa8c16',
          '系统配置': '#d46b08',
          '字典管理': '#fa8c16',
          '数据备份': '#d46b08',
          '数据恢复': '#fa8c16',
          '缓存管理': '#d46b08',
          '系统监控': '#fa8c16'
        },
        '成绩分析': {
          '班级分析': '#2f54eb',
          '成绩趋势': '#1d39c4',
          '数据导出': '#2f54eb',
          '成绩排名': '#1d39c4',
          '成绩分布': '#2f54eb',
          '对比分析': '#1d39c4',
          '综合评价': '#2f54eb',
          '成绩分析详情': '#1d39c4',
          '常用数据报表': '#2f54eb'
        },
        '考试管理': {
          '试卷扫描': '#eb2f96',
          '科目模板配置': '#c41d7f',
          '科目题干配置': '#eb2f96',
          '客观题答案设置': '#c41d7f',
          '分配权限': '#eb2f96',
          '阅卷数据校核与分析': '#c41d7f',
          '参考学生管理': '#eb2f96',
          '考场管理': '#c41d7f',
          '考场编排': '#eb2f96',
          '试卷上传': '#c41d7f',
          '学生参考情况校核': '#eb2f96',
          '缓冲区管理': '#c41d7f',
          '数据管理': '#eb2f96',
          '成品卷抽查': '#c41d7f',
          '异常卷管理': '#eb2f96',
          '异常卷初始化': '#c41d7f',
          '标准卷管理': '#eb2f96',
          '样卷管理': '#c41d7f',
          '学生试题管理': '#eb2f96',
          '评卷进度': '#c41d7f',
          '题组工作量': '#eb2f96',
          '用户工作量': '#c41d7f',
          '用户分配建议': '#eb2f96',
          '质量监控统计': '#c41d7f',
          '复评率统计': '#eb2f96',
          '分数分布统计': '#c41d7f',
          '自评卷查看': '#eb2f96',
          '标准卷查看': '#c41d7f',
          '用户考核统计': '#eb2f96',
          '成绩校核': '#c41d7f',
          '成绩管理': '#eb2f96',
          '客观题校核': '#c41d7f'
        },
        '教师阅卷': {
          '阅卷页面': '#722ed1',
          '阅卷统计': '#531dab'
        }
      }
    };
  },
  watch: {
    activeRoute: {
      immediate: true,
      handler(newRoute) {
        this.updateActiveMenus(newRoute);
      }
    },
    // 监听treeMenu的变化，当用户角色切换后，菜单权限发生变化时更新UI
    treeMenu: {
      immediate: true,
      deep: true,
      handler() {
        console.log('菜单数据已更新，重新计算高亮状态');
        this.updateActiveMenus(this.activeRoute);
      }
    },
    // 监听examMgeMenuExist和teacherMarkingMenuExist的变化
    examMgeMenuExist: {
      handler() {
        console.log('考试管理菜单状态变化:', this.examMgeMenuExist);
        this.updateActiveMenus(this.activeRoute);
      }
    },
    teacherMarkingMenuExist: {
      handler() {
        console.log('教师阅卷菜单状态变化:', this.teacherMarkingMenuExist);
        this.updateActiveMenus(this.activeRoute);
      }
    }
  },
  mounted() {
    document.addEventListener('click', this.closeUserMenu);
    this.updateActiveMenus(this.activeRoute);
    
    // 添加路由变化监听器，确保菜单高亮状态及时更新
    this.$router.afterEach((to) => {
      console.log('路由变化，重新计算高亮状态:', to.path);
      this.updateActiveMenus(to.path);
    });
    
    // 添加窗口大小变化监听，在大屏幕上自动关闭抽屉
    window.addEventListener('resize', this.handleResize);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.closeUserMenu);
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    // 获取菜单图标
    getMenuIcon(menuName) {
      return this.iconMap[menuName] || '';
    },
    // 获取子菜单图标
    getSubMenuIcon(childName, parentName) {
      if (this.subMenuIconMap[parentName] && this.subMenuIconMap[parentName][childName]) {
        return this.subMenuIconMap[parentName][childName];
      }
      
      // 如果没有特定的图标，根据菜单名称智能匹配图标
      if (childName.includes('管理')) {
        // 为不同父菜单下的"管理"类菜单提供不同图标
        if (parentName === '信息管理') return 'i-application-one';
        if (parentName === '系统管理') return 'i-application-menu';
        return 'i-application';
      }
      if (childName.includes('设置')) return 'i-setting-config';
      if (childName.includes('分析')) return 'i-chart-line';
      if (childName.includes('导出')) return 'i-export';
      if (childName.includes('导入')) return 'i-import-and-export';
      if (childName.includes('上传')) return 'i-upload';
      if (childName.includes('下载')) return 'i-download';
      if (childName.includes('学生')) return 'i-peoples';
      if (childName.includes('教师')) return 'i-user';
      if (childName.includes('班级')) return 'i-people';
      if (childName.includes('成绩')) return 'i-chart-pie';
      if (childName.includes('试卷')) return 'i-file-pdf';
      if (childName.includes('日志')) return 'i-log';
      if (childName.includes('权限')) return 'i-permissions';
      if (childName.includes('配置')) return 'i-config';
      
      // 默认图标
      return 'i-branch-one';
    },
    // 获取子菜单图标颜色
    getSubMenuIconColor(childName, parentName) {
      // 先检查是否有特定的颜色配置
      if (this.subMenuColorMap[parentName] && this.subMenuColorMap[parentName][childName]) {
        return this.subMenuColorMap[parentName][childName];
      }
      
      // 如果没有特定颜色配置，使用父菜单的颜色，如果是子菜单，使用深色变体
      const parentColor = this.iconColorMap[parentName] || '#555555';
      
      // 返回更深的颜色版本，确保在浅色模式下也清晰可见
      return parentColor;
    },
    // 获取图标颜色
    getIconColor(menuName) {
      return this.iconColorMap[menuName] || '#909399'; // 默认为浅灰色
    },
    // 处理窗口大小变化
    handleResize() {
      if (window.innerWidth > 768 && this.isDrawerOpen) {
        this.isDrawerOpen = false;
      }
    },
    // 导航并关闭抽屉菜单
    handleNavigateAndClose(route) {
      this.handleNavigate(route);
      this.isDrawerOpen = false;
    },
    // 添加新方法来检查菜单是否应该高亮
    isMenuActive(menuName) {
      console.log('检查菜单是否高亮:', menuName, this.activeParentMenus.includes(menuName));
      return this.activeParentMenus.includes(menuName);
    },
    
    updateActiveMenus(route) {
      if (!route) return;
      
      // 清空之前的活动菜单
      this.activeParentMenus = [];
      
      console.log('当前活动路由:', route);
      console.log('当前菜单结构:', JSON.stringify(this.treeMenu.map(item => ({name: item.permission_name, url: item.permission_url}))));
      
      // 创建路由路径与菜单名称的映射，用于处理URL为null的情况
      const routeToMenuMap = {
        '/main/analysis': '成绩分析',
        '/main/info': '信息管理',
        '/main/sys': '系统管理'
      };
      
      // 特殊处理某些路由
      // 考试管理和教师阅卷路由特殊处理
      if (route.includes('/exam') && this.examMgeMenuExist) {
        this.activeParentMenus.push('/exam/mgeexam');
        console.log('特殊路由匹配 - 考试管理');
        return; // 特殊路由已匹配，不需要继续处理
      }
      
      if (route.includes('/marking') && this.teacherMarkingMenuExist) {
        this.activeParentMenus.push('/marking');
        console.log('特殊路由匹配 - 教师阅卷');
        return; // 特殊路由已匹配，不需要继续处理
      }
      
      // 如果没有特殊路由匹配，继续处理常规菜单
      // 尝试使用路径前缀映射找到匹配的顶级菜单
      let found = false;
      
      for (const [routePath, menuName] of Object.entries(routeToMenuMap)) {
        if (route.startsWith(routePath)) {
          console.log('通过名称映射匹配到菜单:', menuName);
          this.activeParentMenus.push(menuName); // 直接使用菜单名称
          found = true;
          break; // 找到匹配就停止循环
        }
      }
      
      console.log('活动菜单列表:', this.activeParentMenus);
    },
    updateRoutePath(route) {
      if (!route) return route;
      
      if (route.startsWith('/main/') || route === '/') return route;
      
      if (route === '/exam/mgeexam') return '/main/exam';
      if (route === '/marking') return '/main/marking';
      
      if (route.startsWith('/info/') || route.startsWith('/sys/')) {
        return `/main${route}`;
      }
      
      return `/main${route}`;
    },
    handleNavigate(route) {
      if (!route) return;
      this.$emit('navigate', route);
    },
    handleToggleTheme() {
      this.$emit('toggle-theme');
    },
    handleRefreshView() {
      this.$emit('refresh-view');
    },
    handleChangeRole(userType, unitId) {
      this.$emit('change-role', userType, unitId);
      this.isUserMenuOpen = false;
    },
    handleLogout() {
      this.$emit('logout');
      this.isUserMenuOpen = false;
    },
    handleEditPassword() {
      this.$emit('edit-password');
      this.isUserMenuOpen = false;
    },
    closeUserMenu(event) {
      if (this.isUserMenuOpen && this.$refs?.userInfo && !this.$refs.userInfo.contains(event.target)) {
        this.isUserMenuOpen = false;
      }
    },
    getUserTypeName(userType) {
      return this.userTypesMap[userType]?.code_name || this.userTypesMap[userType]?.name || '未知';
    },
    getCurrentRoleText() {
      const roleName = this.getUserTypeName(this.user.type);
      const unitName = this.unit.unit_name || this.unit.name || '无单位';
      return `${unitName} - ${roleName}`;
    },
    // 添加特殊菜单高亮检查方法
    isExamMenuActive() {
      return this.activeRoute.includes('/exam') || this.activeParentMenus.includes('/exam/mgeexam');
    },
    isMarkingMenuActive() {
      return this.activeRoute.includes('/marking') || this.activeParentMenus.includes('/marking');
    }
  }
};
</script>

<style scoped>
.header-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--header-height);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 var(--content-padding);
  background-color: var(--background-color);
  box-shadow: 0 2px 8px var(--shadow-color);
  z-index: 1000;
  transition: all var(--transition-speed) ease;
}

.header-menu.dark-theme {
  background-color: var(--background-color);
  color: var(--text-color);
  box-shadow: 0 2px 8px var(--shadow-color);
}

/* 品牌区域样式 */
.header-brand {
  display: flex;
  align-items: center;
  transition: all var(--transition-speed) ease;
}

.header-brand:hover {
  opacity: 0.8;
}

.logo {
  display: flex;
  align-items: center;
  gap: 10px;
}

.logo-img {
  height: 32px;
  width: auto;
}

.system-name {
  font-size: 18px;
  font-weight: 600;
  color: var(--primary-color);
}

/* 导航区域样式 */
.header-nav {
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0 20px;
}

.nav-item {
  height: 100%;
  padding: 0 16px;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  transition: all var(--transition-speed) ease;
  font-weight: 500;
  color: var(--text-color);
  gap: 6px;  /* 为图标和文字添加间距 */
}

.nav-item:hover {
  color: var(--primary-color);
  background-color: var(--hover-color);
}

.nav-item.active {
  color: var(--primary-color);
  font-weight: 600;
}

.nav-item.active::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 10%;
  width: 80%;
  height: 3px;
  background-color: var(--primary-color);
  border-radius: 3px 3px 0 0;
}

/* 子菜单样式 */
.submenu {
  position: absolute;
  top: 100%;
  left: 0;
  min-width: 160px;
  background-color: var(--background-color);
  box-shadow: 0 4px 12px var(--shadow-color);
  border-radius: var(--border-radius);
  padding: 8px 0;
  display: none;
  z-index: 10;
}

.dark-theme .submenu {
  background-color: var(--background-secondary);
  box-shadow: 0 4px 12px var(--shadow-color);
}

.nav-item:hover .submenu {
  display: block;
  animation: fadeIn var(--transition-speed) ease;
}

.submenu-item {
  padding: 10px 16px;
  cursor: pointer;
  transition: all var(--transition-speed) ease;
  color: var(--text-color);
  display: flex;
  align-items: center;
  gap: 8px;
}

.submenu-item:hover {
  background-color: var(--hover-color);
  color: var(--primary-color);
}

/* 功能区域样式 */
.header-actions {
  display: flex;
  align-items: center;
  gap: 16px;
}

.action-item {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  transition: all var(--transition-speed) ease;
  color: var(--text-color);
}

.action-item:hover {
  background-color: var(--hover-color);
  color: var(--primary-color);
}

/* 用户信息区域样式 */
.user-info {
  position: relative;
}

.avatar-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 6px 12px;
  border-radius: 30px;
  cursor: pointer;
  transition: all var(--transition-speed) ease;
}

.avatar-wrapper:hover {
  background-color: var(--hover-color);
}

.avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: var(--primary-color);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
}

.user-info-text {
  display: flex;
  flex-direction: column;
  max-width: 350px;
}

.user-name {
  font-size: 14px;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--text-color);
}

.role-switch-hint {
  display: flex;
  align-items: center;
  gap: 5px;
}

.current-role {
  font-size: 12px;
  color: var(--text-secondary);
  white-space: nowrap;
}

.role-switch-text {
  font-size: 12px;
  color: var(--primary-color);
  opacity: 0.8;
  background-color: rgba(var(--primary-rgb), 0.1);
  padding: 1px 4px;
  border-radius: 4px;
  white-space: nowrap;
}

.avatar-wrapper:hover .role-switch-text {
  opacity: 1;
}

/* 用户下拉菜单样式 */
.user-dropdown {
  position: absolute;
  top: calc(100% + 8px);
  right: 0;
  width: 240px;
  max-height: 70vh; /* 限制最大高度为视口高度的70% */
  background-color: var(--background-color);
  box-shadow: 0 4px 12px var(--shadow-color);
  border-radius: var(--border-radius);
  overflow-y: auto; /* 启用垂直滚动 */
  z-index: 100;
  animation: fadeIn var(--transition-speed) ease;
  display: flex;
  flex-direction: column; /* 确保内容垂直排列 */
}

.dark-theme .user-dropdown {
  background-color: var(--background-secondary);
  box-shadow: 0 4px 12px var(--shadow-color);
}

/* 添加滚动条样式 */
.user-dropdown::-webkit-scrollbar {
  width: 6px;
}

.user-dropdown::-webkit-scrollbar-thumb {
  background-color: var(--border-color);
  border-radius: 3px;
}

.user-dropdown::-webkit-scrollbar-track {
  background-color: transparent;
}

/* 确保在小屏幕上也能显示完整的下拉菜单 */
@media (max-height: 600px) {
  .user-dropdown {
    max-height: 80vh; /* 在更小的屏幕上增加可见区域比例 */
  }
}

.dropdown-info {
  padding: 16px;
  background-color: var(--background-secondary);
}

.user-details {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.user-role {
  font-weight: 600;
  font-size: 14px;
  color: var(--text-color);
}

.user-unit {
  font-size: 12px;
  opacity: 0.8;
  color: var(--text-secondary);
}

/* 角色切换区域样式优化 */
.dropdown-section {
  padding: 8px 0;
}

/* 当角色过多时，这个区域单独滚动 */
.dropdown-section:first-of-type {
  max-height: 40vh; /* 限制角色列表的最大高度 */
  overflow-y: auto; /* 启用独立滚动 */
}

/* 底部操作区始终可见 */
.dropdown-section:last-of-type {
  margin-top: auto; /* 推到底部 */
  border-top: 1px solid var(--border-color);
}

.dropdown-title {
  padding: 6px 16px;
  font-size: 12px;
  font-weight: 600;
  opacity: 0.6;
  text-transform: uppercase;
  color: var(--text-secondary);
}

.dropdown-item {
  padding: 10px 16px;
  cursor: pointer;
  transition: all var(--transition-speed) ease;
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--text-color);
}

.dropdown-item:hover {
  background-color: var(--hover-color);
}

.role-item.active {
  background-color: var(--hover-color);
  color: var(--primary-color);
  font-weight: 500;
}

.dropdown-divider {
  display: none; /* 隐藏分隔线，使用底部区域的顶部边框替代 */
}

.dark-theme .dropdown-divider {
  background-color: var(--border-color);
}

.logout-item {
  color: var(--error-color);
}

.logout-item:hover {
  background-color: rgba(244, 67, 54, 0.1);
}

/* 移动端菜单按钮 */
.mobile-menu-btn {
  display: none;
  width: 36px;
  height: 36px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  transition: all var(--transition-speed) ease;
}

.mobile-menu-btn:hover {
  background-color: var(--hover-color);
}

/* 抽屉菜单样式 */
.drawer-content {
  padding: 0;
  height: 100%;
  overflow-y: auto;
}

.drawer-menu {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.drawer-menu-item {
  border-bottom: 1px solid var(--border-color);
}

.drawer-item-header {
  display: flex;
  align-items: center;
  padding: 15px;
  cursor: pointer;
  transition: background-color var(--transition-speed) ease;
}

.drawer-item-header:hover {
  background-color: var(--hover-color);
}

.drawer-menu-item.active .drawer-item-header {
  color: var(--primary-color);
  background-color: var(--hover-color);
  font-weight: 500;
}

.drawer-item-icon {
  margin-right: 12px;
  display: flex;
  align-items: center;
}

.drawer-item-title {
  flex: 1;
  font-size: 16px;
}

.expand-icon {
  transition: transform var(--transition-speed) ease;
}

.expand-icon.expanded {
  transform: rotate(180deg);
}

.drawer-submenu {
  max-height: 0;
  overflow: hidden;
  transition: max-height var(--transition-speed) ease;
  background-color: var(--background-secondary);
}

.drawer-submenu.expanded {
  max-height: 500px; /* 足够容纳子菜单 */
}

.drawer-submenu-item {
  padding: 12px 15px 12px 45px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  transition: background-color var(--transition-speed) ease;
}

.drawer-submenu-item:hover {
  background-color: var(--hover-color);
  color: var(--primary-color);
}

/* 暗色主题抽屉 */
.dark-drawer.el-drawer {
  background-color: var(--background-color);
  color: var(--text-color);
}

.dark-drawer .el-drawer__header {
  color: var(--text-color);
  border-bottom: 1px solid var(--border-color);
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* 响应式调整 */
@media (max-width: 768px) {
  .header-menu {
    padding: 0 10px;
  }
  
  .system-name {
    display: none;
  }
  
  .header-nav.hidden-on-mobile {
    display: none;
  }
  
  .mobile-menu-btn {
    display: flex;
  }
  
  .action-item {
    width: 32px;
    height: 32px;
  }
  
  .user-info-text {
    display: none;
  }
  
  /* 在移动端下确保下拉菜单不会超出屏幕 */
  .user-dropdown {
    right: 0;
    width: 220px;
    max-height: 80vh;
  }
  
  /* 简化移动端下的角色切换区域 */
  .dropdown-section:first-of-type {
    max-height: 50vh;
  }
}

/* 角色切换区域滚动条样式 */
.dropdown-section:first-of-type::-webkit-scrollbar {
  width: 4px;
}

.dropdown-section:first-of-type::-webkit-scrollbar-thumb {
  background-color: var(--border-color);
  border-radius: 3px;
}

.dropdown-section:first-of-type::-webkit-scrollbar-track {
  background-color: transparent;
}
</style> 