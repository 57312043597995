<template>
  <button 
    class="custom-button" 
    :class="{ 
      'is-primary': type === 'primary', 
      'is-text': type === 'text',
      'is-disabled': disabled 
    }"
    :disabled="disabled"
    @click="$emit('click')"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'CustomButton',
  props: {
    type: {
      type: String,
      default: 'primary',
      validator: value => ['primary', 'text'].includes(value)
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
.custom-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  height: 48px;
  padding: 0 24px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  outline: none;
  border: none;
  white-space: nowrap;
  user-select: none;
}

.custom-button.is-primary {
  background-color: #2196f3;
  color: white;
}

.custom-button.is-primary:hover:not(.is-disabled) {
  background-color: #1976d2;
}

.custom-button.is-primary:active:not(.is-disabled) {
  background-color: #0d47a1;
}

.custom-button.is-text {
  background-color: transparent;
  color: #2196f3;
  padding: 8px 16px;
}

.custom-button.is-text:hover:not(.is-disabled) {
  background-color: rgba(33, 150, 243, 0.1);
}

.custom-button.is-text:active:not(.is-disabled) {
  background-color: rgba(33, 150, 243, 0.2);
}

.custom-button.is-disabled {
  opacity: 0.6;
  cursor: not-allowed;
}
</style> 