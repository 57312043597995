<template>
  <div id="app" :class="isDark ? 'dark-theme' : 'light-theme'">
    <router-view />
  </div>
</template>

<script>
import { useDark, useToggle } from '@vueuse/core';
import { provide, onMounted } from 'vue';

export default {
  name: 'App',
  setup() {
    // 设置深色模式
    const isDark = useDark({
      valueDark: 'dark',
      valueLight: 'light',
      storageKey: 'theme-mode',
    });
    const toggleDark = useToggle(isDark);
    
    // 提供给所有子组件
    provide('isDark', isDark);
    provide('toggleDark', toggleDark);
    
    onMounted(() => {
      // 初始化时根据当前主题设置文档根元素的类
      if (isDark.value) {
        document.documentElement.classList.add('dark-theme');
        document.documentElement.classList.remove('light-theme');
      } else {
        document.documentElement.classList.add('light-theme');
        document.documentElement.classList.remove('dark-theme');
      }
    });
    
    return { isDark, toggleDark };
  }
};

// 解决ERROR ResizeObserver loop completed with undelivered notifications.

//问题的

const debounce = (fn, delay) => {

  let timer = null;

  return function () {

    let context = this;

    let args = arguments;

    clearTimeout(timer);

    timer = setTimeout(function () {

      fn.apply(context, args);

    }, delay);

  }

}

// 解决ERROR ResizeObserver loop completed with undelivered notifications.

//问题的

const _ResizeObserver = window.ResizeObserver;

window.ResizeObserver = class ResizeObserver extends _ResizeObserver {

  constructor(callback) {

    callback = debounce(callback, 16);

    super(callback);

  }

}

</script>

<style>
/* 全局CSS变量 - 亮色主题（默认） */
:root {
  /* 主色调 */
  --primary-color: #2196f3;
  --primary-light: #64b5f6;
  --primary-dark: #1976d2;
  
  /* 文本颜色 */
  --text-color: #333;
  --text-secondary: #666;
  --text-light: #999;
  
  /* 背景颜色 */
  --background-color: #ffffff;
  --background-secondary: #f5f9ff;
  --background-tertiary: #edf5ff;
  
  /* 边框和分割线 */
  --border-color: #e0e7ff;
  --divider-color: #f0f4ff;
  
  /* 交互颜色 */
  --hover-color: #f0f7ff;
  --active-color: #e1f1ff;
  
  /* 阴影 */
  --shadow-color: rgba(0, 0, 0, 0.1);
  --shadow-light: rgba(0, 0, 0, 0.05);
  
  /* 元素背景 */
  --card-bg: #ffffff;
  --input-bg: #ffffff;
  --modal-bg: #ffffff;
}

/* 暗色主题变量 */
.dark-theme {
  /* 主色调 */
  --primary-color: #2196f3;
  --primary-light: #0d47a1; /* 在暗色模式下，亮色变深色 */
  --primary-dark: #64b5f6; /* 在暗色模式下，深色变亮色 */
  
  /* 文本颜色 */
  --text-color: #e0e0e0;
  --text-secondary: #b0b0b0;
  --text-light: #808080;
  
  /* 背景颜色 */
  --background-color: #1a1a1a;
  --background-secondary: #242424;
  --background-tertiary: #2c2c2c;
  
  /* 边框和分割线 */
  --border-color: #3a3a3a;
  --divider-color: #333333;
  
  /* 交互颜色 */
  --hover-color: #2a2a2a;
  --active-color: #303030;
  
  /* 阴影 */
  --shadow-color: rgba(0, 0, 0, 0.3);
  --shadow-light: rgba(0, 0, 0, 0.2);
  
  /* 元素背景 */
  --card-bg: #242424;
  --input-bg: #2c2c2c;
  --modal-bg: #242424;
}

/* 亮色主题类 */
.light-theme {
  color-scheme: light;
}

/* 暗色主题类 */
.dark-theme {
  color-scheme: dark;
}

/* 全局样式 */
html, body {
  margin: 0;
  padding: 0;
  font-family: 'Helvetica Neue', Helvetica, 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', '微软雅黑', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: background-color 0.3s ease, color 0.3s ease;
}

body {
  background-color: var(--background-color);
  color: var(--text-color);
}

#app {
  width: 100%;
  height: 100vh;
  transition: all 0.3s ease;
}

/* 覆盖Element Plus的一些默认样式，使其适应我们的主题 */
.dark-theme .el-menu {
  --el-menu-bg-color: var(--background-color) !important;
  --el-menu-text-color: var(--text-color) !important;
  --el-menu-hover-bg-color: var(--hover-color) !important;
  --el-menu-active-color: var(--primary-color) !important;
  --el-menu-item-height: 60px;
}

.dark-theme .el-button {
  --el-button-bg-color: var(--background-secondary);
  --el-button-text-color: var(--text-color);
  --el-button-border-color: var(--border-color);
}

.dark-theme .el-input__wrapper {
  background-color: var(--input-bg);
  box-shadow: 0 0 0 1px var(--border-color) inset !important;
}

.dark-theme .el-input__inner {
  color: var(--text-color);
}

.dark-theme .el-dialog {
  background-color: var(--modal-bg);
  border: 1px solid var(--border-color);
}

.dark-theme .el-dialog__title {
  color: var(--text-color);
}
</style>
