<template>
  <div class="verification-code-container">
    <div class="code-label">请输入4位验证码</div>
    <div class="verification-code-wrapper">
      <el-input 
        v-for="(input, index) in codeInputs" 
        :key="index"
        ref="codeInputs"
        v-model="codeInputs[index]"
        class="code-input"
        maxlength="1"
        @input="handleInput(index)"
        @keydown.native="handleKeyDown($event, index)"
        @focus="handleFocus(index)"
        @paste.native="handlePaste"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'VerificationCodeInput',
  props: {
    modelValue: {
      type: String,
      default: ''
    },
    phone: {
      type: String,
      required: true
    }
  },
  emits: ['update:modelValue', 'error', 'success', 'code-completed'],
  data() {
    return {
      codeInputs: ['', '', '', '']
    }
  },
  watch: {
    codeInputs: {
      handler(val) {
        const code = val.join('')
        this.$emit('update:modelValue', code)
        
        // 当所有输入框都填充完毕后，触发完成事件
        if (code.length === 4) {
          this.$nextTick(() => {
            this.$emit('code-completed')
          })
        }
      },
      deep: true
    },
    modelValue: {
      handler(newVal) {
        if (newVal) {
          const valueArray = newVal.split('')
          for (let i = 0; i < 4; i++) {
            this.codeInputs[i] = valueArray[i] || ''
          }
        } else {
          this.codeInputs = ['', '', '', '']
        }
      },
      immediate: true
    }
  },
  methods: {
    handleInput(index) {
      const input = this.codeInputs[index]
      
      // 确保输入是单个字符
      if (input && input.length >= 1 && index < 3) {
        this.$nextTick(() => {
          if (this.$refs.codeInputs && this.$refs.codeInputs[index + 1]) {
            this.$refs.codeInputs[index + 1].focus()
          }
        })
      }
    },
    handleKeyDown(event, index) {
      // 处理退格键
      if (event.key === 'Backspace') {
        if (!this.codeInputs[index] && index > 0) {
          this.$nextTick(() => {
            if (this.$refs.codeInputs && this.$refs.codeInputs[index - 1]) {
              this.$refs.codeInputs[index - 1].focus()
            }
          })
        }
      }
    },
    handleFocus(index) {
      // 选中内容
      this.$nextTick(() => {
        if (this.$refs.codeInputs && this.$refs.codeInputs[index]) {
          this.$refs.codeInputs[index].select()
        }
      })
    },
    handlePaste(event) {
      event.preventDefault()
      const pastedText = (event.clipboardData || window.clipboardData).getData('text')
      
      if (pastedText) {
        const digits = pastedText.replace(/\D/g, '').split('').slice(0, 4)
        digits.forEach((digit, index) => {
          if (index < 4) {
            this.codeInputs[index] = digit
          }
        })
        
        // 聚焦到下一个空输入框或最后一个
        const nextEmptyIndex = this.codeInputs.findIndex(input => !input)
        if (nextEmptyIndex !== -1) {
          this.$nextTick(() => {
            if (this.$refs.codeInputs && this.$refs.codeInputs[nextEmptyIndex]) {
              this.$refs.codeInputs[nextEmptyIndex].focus()
            }
          })
        } else {
          this.$nextTick(() => {
            if (this.$refs.codeInputs && this.$refs.codeInputs[3]) {
              this.$refs.codeInputs[3].focus()
            }
          })
        }
      }
    }
  }
}
</script>

<style scoped>
.verification-code-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 18px;
  background-color: #f5f9ff;
  border-radius: 12px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.08);
  border: 1px solid #e6f0fd;
  margin-top: 8px;
  box-sizing: border-box;
  margin-left: 0;
  margin-right: 0;
}

.code-label {
  font-size: 15px;
  color: #2196f3;
  font-weight: 500;
  margin-bottom: 14px;
}

.verification-code-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 8px 0;
}

.code-input {
  width: calc(25% - 6px);
  height: 60px;
  transition: all 0.3s ease;
}

/* 输入框基本样式 */
:deep(.el-input__wrapper) {
  padding: 0;
  box-shadow: 0 0 0 1px #d0e3fa inset !important;
  border-radius: 12px;
  background-color: #fff;
  transition: all 0.3s ease;
  overflow: hidden;
}

/* 输入框内部文字样式 */
:deep(.el-input__inner) {
  height: 100%;
  text-align: center;
  font-size: 28px;
  font-weight: 600;
  color: #333;
  transition: all 0.3s ease;
}

/* 聚焦状态 */
:deep(.el-input.is-focus .el-input__wrapper) {
  box-shadow: 0 0 10px rgba(33, 150, 243, 0.4), 0 0 0 2px #2196f3 inset !important;
  transform: translateY(-2px);
}

/* 输入完成状态 */
:deep(.el-input.is-filled .el-input__wrapper),
:deep(.el-input.is-focus.is-filled .el-input__wrapper) {
  background-color: #f0f8ff;
  box-shadow: 0 0 6px rgba(33, 150, 243, 0.3), 0 0 0 2px #2196f3 inset !important;
}

/* 添加输入框内容动画效果 */
@keyframes pulse {
  0% { transform: scale(0.8); opacity: 0; }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); opacity: 1; }
}

:deep(.el-input.is-filled .el-input__inner) {
  animation: pulse 0.3s ease-in-out;
}

/* 添加悬停效果 */
:deep(.el-input__wrapper:hover) {
  box-shadow: 0 0 0 2px rgba(33, 150, 243, 0.5) inset !important;
}

/* 添加按下效果 */
:deep(.el-input.is-focus .el-input__wrapper:active) {
  transform: translateY(0);
}

/* 添加响应式样式 */
@media (max-width: 480px) {
  .code-input {
    width: calc(25% - 6px);
    height: 50px;
  }
  
  :deep(.el-input__inner) {
    font-size: 24px;
  }
}
</style> 