<script>
import {useUserStoreWithOut} from "@/store/modules/user";
import {useCodeStoreWithOut} from "@/store/modules/code";
import { ref, onBeforeMount, computed, watch } from 'vue';
import { useDark, useToggle } from '@vueuse/core';
import HeaderMenu from '@/components/common/HeaderMenu.vue';
import { ArrowRight } from '@element-plus/icons-vue';

export default {
  name: "PageMain",
  components: {
    HeaderMenu
  },
  computed: {
    ArrowRight() {
      return ArrowRight;
    },
    userTypesMap(){
      const res = {}
      if (this.userTypes){
        this.userTypes.forEach(i => {
          res[i.code] = i
        })
      }

      return res
    },
    nowUserUnitRole() {
      if (this.userStore.unitRoles) {
        return this.userStore.user.type + '-' + this.userStore.unit.unit_id
      }
      return ''
    },
    treeMenu(){
      // 去除掉"考试管理"的菜单，因为里面子菜单都在一个页面里面控制
      // 去掉"教师阅卷"的菜单
      // 去掉"扫描接口权限"的菜单
      return this.userStore.treePages.filter(item => {
        return item.permission_name !== '考试管理' && item.permission_name !== '教师阅卷' && item.permission_name !== '扫描接口权限'
      })
    },
    // eslint-disable-next-line vue/no-arrow-functions-in-watch
    nowUser(){
      let res = {}
      if (this.userStore.unitRoles){
        const user = this.userStore.user
        const unit = this.userStore.unit
        this.userStore.unitRoles.forEach((unitRole) => {
          if (user.type == unitRole.user_type && unit.unit_id == unitRole.unit_id){
            res = unitRole
          }
        })
      }

      return res
    }
  },
  setup() {
    const userStore = useUserStoreWithOut()
    const codeStore = useCodeStoreWithOut()
    
    // 设置深色模式
    const isDark = useDark({
      valueDark: 'dark',
      valueLight: 'light',
      storageKey: 'theme-mode',
    })
    const toggleDark = useToggle(isDark)
    
    return {userStore, codeStore, isDark, toggleDark}
  },
  data() {
    return {
      load: false,
      routerViewKey: 0,
      loading: false,
      userTypes:[],
      examMgeMenuExist: false,
      teacherMarkingMenuExist: false,
      editPasswordVisi: false,
      editPasswordFormData: {
        oldPassword:"",
        newPass1: "",
        newPass2: ""
      }
    }
  },
  methods:{
    changeUnitRole(userType,unitId){
      this.userStore.changeUnitRole(userType,unitId)
      // 立即更新菜单权限
      this.$nextTick(() => {
        this.refreshMenuPermissions();
      });
    },
    async confirmEditPassword(){
      // oldPassword不能为空
      if (!this.editPasswordFormData.oldPassword){
        this.$message.warning('旧密码不能为空')
        return
      }

      // newPass不能为空
      if (!this.editPasswordFormData.newPass1 || !this.editPasswordFormData.newPass2) {
        this.$message.warning('新密码不能为空')
        return
      }
      // 两次密码一致
      if (this.editPasswordFormData.newPass1 !== this.editPasswordFormData.newPass2) {
        this.$message.warning('两次密码不一致')
        return
      }
      const {data} = await this.axios.post("/api/login/editPassword",this.editPasswordFormData)
      if(data.success){
        this.$message.success('修改成功,请重新登录')
        setTimeout(()=>{
          this.userStore.logout()
        },1100)
      }
    },
    refreshView() {
      this.loading = true;
      this.routerViewKey += 1;
      this.$nextTick(() => {
        setTimeout(() => {
          this.loading = false;
        }, 500);
      });
    },
    // 导航菜单事件处理方法
    handleNavigate(route) {
      this.$router.push(route);
    },
    handleToggleTheme() {
      this.toggleDark();
    },
    handleChangeRole(userType, unitId) {
      this.changeUnitRole(userType, unitId);
    },
    handleLogout() {
      this.userStore.logoutConfirm();
    },
    handleEditPassword() {
      this.editPasswordVisi = true;
    },
    // 刷新菜单权限状态
    refreshMenuPermissions() {
      // 确保userStore.pages已加载
      if (!this.userStore.pages || this.userStore.pages.length === 0) {
        return;
      }
      
      // 重置菜单权限标志
      this.examMgeMenuExist = false;
      this.teacherMarkingMenuExist = false;
      
      // 检查权限
      for (let i = 0; i < this.userStore.pages.length; i++) {
        const p = this.userStore.pages[i];
        if (p.permission_url === "/exam/mgeexam") {
          this.examMgeMenuExist = true;
        }
        if (p.permission_url === "/marking") {
          this.teacherMarkingMenuExist = true;
        }
      }
      
      console.log('菜单权限已更新 - 考试管理:', this.examMgeMenuExist, '教师阅卷:', this.teacherMarkingMenuExist);
    }
  },
  watch:{
    'userStore.pages': function (newVal) {
      this.load = true
      this.examMgeMenuExist = false
      this.teacherMarkingMenuExist = false
      for (let i = 0; i < newVal.length; i++) {
        const p = newVal[i]
        if (p.permission_url == "/exam/mgeexam"){
          this.examMgeMenuExist = true
        }
        if (p.permission_url == "/marking"){
          this.teacherMarkingMenuExist = true
        }
      }
    },
    // 监听用户角色变化，立即更新菜单状态
    'userStore.user.type': function() {
      console.log('用户类型已变更，重新检查菜单权限');
      this.refreshMenuPermissions();
    },
    // 监听单位变化，立即更新菜单状态
    'userStore.unit.unit_id': function() {
      console.log('用户单位已变更，重新检查菜单权限');
      this.refreshMenuPermissions();
    },
    'nowUserUnitRole': function() {
      console.log('用户角色组合已变更，重新检查菜单权限');
      this.refreshMenuPermissions();
    },
    isDark: {
      handler(val) {
        // 当主题切换时修改根元素的样式
        if (val) {
          document.documentElement.classList.add('dark-theme');
          document.documentElement.classList.remove('light-theme');
        } else {
          document.documentElement.classList.add('light-theme');
          document.documentElement.classList.remove('dark-theme');
        }
      },
      immediate: true
    }
  },
  async beforeMount() {
    this.userStore.loadUserInfo()
    this.userTypes = await this.codeStore.getCode("user_type")
  }
}
</script>

<template>
  <div class="main-container" :class="isDark ? 'dark-theme' : 'light-theme'">
    <!-- 修改密码对话框 -->
    <el-dialog v-model="editPasswordVisi" title="修改密码" width="500" :class="{ 'dark-dialog': isDark }">
      <el-form :model="editPasswordFormData" label-width="150">
        <el-form-item label="旧密码" >
          <el-input v-model="editPasswordFormData.oldPassword" type="password" show-password />
        </el-form-item>
        <el-form-item label="新密码" >
          <el-input v-model="editPasswordFormData.newPass1" type="password" show-password />
        </el-form-item>
        <el-form-item label="再输一遍新密码" >
          <el-input v-model="editPasswordFormData.newPass2" type="password" show-password />
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="editPasswordVisi = false">取消</el-button>
          <el-button type="primary" @click="confirmEditPassword">确认修改</el-button>
        </div>
      </template>
    </el-dialog>
    
    <!-- 自定义顶部菜单 -->
    <HeaderMenu 
      :is-dark="isDark"
      :user="userStore.user"
      :unit="userStore.unit"
      :unit-roles="userStore.unitRoles"
      :user-types-map="userTypesMap"
      :tree-menu="treeMenu"
      :active-route="$route.path"
      :exam-mge-menu-exist="examMgeMenuExist"
      :teacher-marking-menu-exist="teacherMarkingMenuExist"
      :now-user="nowUser"
      :now-user-unit-role="nowUserUnitRole"
      @navigate="handleNavigate"
      @toggle-theme="handleToggleTheme"
      @refresh-view="refreshView"
      @change-role="handleChangeRole"
      @logout="handleLogout"
      @edit-password="handleEditPassword"
    />
    
    <!-- 主内容区 -->
    <div class="main-content" id="maincontainer">
      <!-- 面包屑导航 -->
      <el-breadcrumb :separator-icon="ArrowRight" class="page-breadcrumb" style="margin-bottom: 15px; padding: 5px 0; padding-left: 10px;">
        <el-breadcrumb-item
            v-for="(item, index) in userStore.pagePath"
            :key="index"
            :to="item.fullPath"
        >
          {{item.title}}
          <span v-show="item.otherInfo?.examName" 
                style="color: var(--el-color-primary); cursor: pointer;"
                @click="refreshView">
            [{{item.otherInfo?.examName ? item.otherInfo.examName : ''}}{{item.otherInfo?.subjectName ? ' '+ item.otherInfo.subjectName : ''}}]
          </span>
        </el-breadcrumb-item>
      </el-breadcrumb>
      
      <div class="router-view-container">
        <router-view 
          :key="routerViewKey"
          v-loading="loading" 
          element-loading-text="重载页面中..."
          element-loading-background="rgba(255, 255, 255, 0.7)"
        ></router-view>
      </div>
      
      <el-backtop target="#maincontainer"></el-backtop>
    </div>
  </div>
</template>

<style scoped>
.main-container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: var(--background-color);
  color: var(--text-color);
  transition: all var(--transition-speed) ease;
}

.main-content {
  flex: 1;
  padding: calc(var(--header-height) + 20px) var(--content-padding) var(--content-padding); /* 顶部导航栏高度 + 内容区域的内边距 */
  overflow: auto;
  transition: padding var(--transition-speed) ease;
  height: calc(100vh - var(--header-height)); /* 确保内容区域高度正确 */
  display: flex;
  flex-direction: column;
}

.page-breadcrumb {
  flex-shrink: 0; /* 防止面包屑被压缩 */
  margin-bottom: 0 !important;
}

.router-view-container {
  flex: 1;
  height: calc(100% - 45px); /* 留出面包屑的空间 */
  overflow: hidden; /* 防止双滚动条 */
  position: relative;
  display: flex;
  flex-direction: column;
}

/* 对话框样式 */
:deep(.dark-dialog .el-dialog) {
  background-color: var(--background-color);
  border: 1px solid var(--border-color);
}

:deep(.dark-dialog .el-dialog__title) {
  color: var(--text-color);
}

:deep(.dark-dialog .el-form-item__label) {
  color: var(--text-color);
}

:deep(.dark-dialog .el-input__wrapper) {
  background-color: var(--background-secondary);
  box-shadow: 0 0 0 1px var(--border-color) inset !important;
}

:deep(.dark-dialog .el-input__inner) {
  color: var(--text-color);
}

/* 响应式调整 */
@media (max-width: 768px) {
  .main-content {
    padding: calc(var(--header-height) + 10px) 10px 10px; /* 小屏幕上减少内边距 */
  }
  
  .router-view-container {
    height: calc(100% - 40px); /* 移动端面包屑高度略小 */
  }
}
</style>