<script>
import {useUserStoreWithOut} from "@/store/modules/user";
import axios from "axios";
import { inject } from 'vue';

export default {
  name: "CardMenu",
  setup() {
    const userStore = useUserStoreWithOut();
    // 获取主题模式（从父组件注入）
    const isDark = inject('isDark', false);
    
    return { userStore, isDark };
  },
  data() {
    return {
      load: false,
      userTypes:[],
      examMgeMenuExist: false,
      teacherMarkingMenuExist: false,
      bindAccountFormVisi: false,
      accountTypelist: ["我是学生","我是老师"],
      accountCurrentType: "我是学生",
      studentFormData:{
        unit_code: "",
        student_number: "",
        student_password: ""
      },

      changeNormalUserVisi: false
    }
  },
  methods:{
    // 修改当前用户角色为普通用户
    changeNormalUserRole(){
      this.changeNormalUserVisi = true
    },
    // 绑定账号
    async bindAccount(){
      if (this.accountCurrentType === "我是学生"){
        if(!this.studentFormData.unit_code){
          this.$message.warning('请填写学校编号')
          return
        }
        if(!this.studentFormData.student_number){
          this.$message.warning('请填写学号')
          return
        }
        if(!this.studentFormData.student_password){
          this.$message.warning('请填写密码')
          return
        }
        const {data} = await axios.post("/api/login/bindAccount", {...this.studentFormData,type:"1"})
        if (data.success === true){
          this.$message.success("绑定成功！请重新登录")
          this.userStore.logout()
          this.$router.push({name:"login"})
        }
      }
    }
  },
  watch:{
    'userStore.pages':{
      handler (newVal) {
        this.load = false
        this.examMgeMenuExist = false
        this.teacherMarkingMenuExist = false
        for (let i = 0; i < newVal.length; i++) {
          const p = newVal[i]
          if (p.permission_url == "/exam/mgeexam"){
            this.examMgeMenuExist = true
          }
          if (p.permission_url == "/marking"){
            this.teacherMarkingMenuExist = true
          }
        }
        this.load = true
      },
      immediate: true
    },
    'userStore.user': {
      handler(user){
        if (user.type == '-1'){
          this.bindAccountFormVisi = true
        }
      },
      immediate: true
    }
  },
  computed:{
    normalUserUnits(){
      const unitUsers = this.userStore?.unitRoles
      let res = []
      if(!unitUsers) return unitUsers
      else{
        res = unitUsers.filter(i => i.user_type == 4)
      }
      return res
    }
  }
}
</script>

<template>
  <div class="card-menu-container">
    <el-space wrap v-if="load && userStore.user.type != -1" class="card-space">
      <div class="card primary-card"
           :class="{'disabled-card': !examMgeMenuExist}"
           @click="() =>{
             if(examMgeMenuExist) $router.replace({name:'exam'})
           }"
      >
        <div class="card-icon">
          <i class="fas fa-clipboard-list"></i>
        </div>
        <div class="card-content">
          <h3 class="card-heading">考试管理</h3>
          <p class="card-description">从考试答题卡配置开始，到分配权限、评卷进度质量监控一系列流程的管理</p>
        </div>
        <div class="card-wave"></div>
      </div>
      
      <div class="card success-card"
           :class="{'disabled-card': !teacherMarkingMenuExist}"
           @click="() => {
             if(teacherMarkingMenuExist)$router.replace({name:'marking'})
           }"
      >
        <div class="card-icon">
          <i class="fas fa-tasks"></i>
        </div>
        <div class="card-content">
          <h3 class="card-heading">教师评卷</h3>
          <p class="card-description">提高评卷效率，提供更加灵活和安全的评卷环境</p>
          <p class="card-description2" v-if="!teacherMarkingMenuExist" @click.stop.prevent="changeNormalUserRole">
            <i-attention class="attention-icon" /> 当前角色暂无阅卷任务，请切换至阅卷教师的角色
          </p>
        </div>
        
        <div class="card-wave"></div>
      </div>
      
      <div class="card purple-card"
           :class="{'disabled-card': ['4'].includes(userStore.user.type)}"
           @click="() =>{
             if(!['4'].includes(userStore.user.type)){
               $router.replace({name:'analysis'})
             }
           }"
      >
        <div class="card-icon">
          <i class="fas fa-chart-bar"></i>
        </div>
        <div class="card-content">
          <h3 class="card-heading">成绩分析</h3>
          <p class="card-description">支持按市、区、县等多地区的统计与分析，对学生多学科生成统计报表</p>
        </div>
        <div class="card-wave"></div>
      </div>
    </el-space>


    <!-- 切换普通用户dialog -->
    <el-dialog
      v-model="changeNormalUserVisi"
      title="切换至阅卷教师"
      width="500" 
      :close-on-click-modal="false"
      :class="{ 'dark-dialog': isDark }"
      class="role-switch-dialog"
    >
      <el-space direction="vertical" style="width: 100%;">
        <el-button v-for="(item,index) in normalUserUnits" 
          :key="index"
          style="width: 100%;"
          type="primary"
          :disabled="item.user_type == userStore.user.type && item.unit_id == userStore.unit.unit_id"
          @click="()=>{
             this.userStore.changeUnitRole(item.user_type,item.unit_id)
             this.changeNormalUserVisi = false
          }"  
          class="role-button"
        >
          <span class="unit-name">{{ item.unit_name }}</span>
          <el-text type="success" class="role-tag">阅卷教师</el-text>
        </el-button>
      </el-space>

    </el-dialog>
    
    <!-- 绑定账号dialog -->
    <el-dialog 
      v-model="bindAccountFormVisi" 
      title="绑定账号" 
      width="500" 
      :show-close="false" 
      :close-on-click-modal="false"
      class="bind-account-dialog"
      :class="{ 'dark-dialog': isDark }"
    >
      <el-segmented 
        v-model="accountCurrentType" 
        :options="accountTypelist" 
        size="large" 
        class="account-type-selector"
      />
      
      <el-form 
        :model="studentFormData" 
        v-show="accountCurrentType === '我是学生'" 
        label-width="100"
        class="student-form"
      >
        <el-form-item label="学校编号">
          <el-input v-model="studentFormData.unit_code" autocomplete="off" />
        </el-form-item>
        <el-form-item label="学号">
          <el-input v-model="studentFormData.student_number" autocomplete="off" />
        </el-form-item>
        <el-form-item label="密码">
          <el-input v-model="studentFormData.student_password" type="password" show-password autocomplete="off" />
        </el-form-item>
      </el-form>
      
      <template #footer>
        <div class="dialog-footer">
          <el-button type="primary" @click="bindAccount()" class="bind-button">
            <i class="fas fa-link"></i> 绑定
          </el-button>
          <el-button @click="() => userStore.logoutConfirm()" class="logout-button">
            <i class="fas fa-sign-out-alt"></i> 退出
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<style scoped>
.card-menu-container {
  padding: 20px;
}

.card-space {
  --el-space-x-gap: 24px;
  --el-space-y-gap: 24px;
}

.card {
  width: 320px;
  height: 200px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  padding: 24px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  background-color: var(--background-color, #fff);
  color: var(--text-color, #fff);
  backdrop-filter: blur(5px);
}

.primary-card {
  background: linear-gradient(135deg, #2196f3, #0c7cd5);
}

.success-card {
  background: linear-gradient(135deg, #4caf50, #2e7d32);
}

.purple-card {
  background: linear-gradient(135deg, #6a62d9, #4553b8);
}

.disabled-card {
  background: linear-gradient(135deg, #9e9e9e, #616161);
  cursor: not-allowed;
  opacity: 0.8;
  filter: grayscale(30%);
}

.card:not(.disabled-card):hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
}

.card:not(.disabled-card):active {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.card-icon {
  font-size: 28px;
  color: rgba(255, 255, 255, 0.95);
  margin-bottom: 12px;
  transition: transform 0.3s ease;
}

.card:not(.disabled-card):hover .card-icon {
  transform: scale(1.1);
}

.card-content {
  z-index: 1;
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

.card-heading {
  font-size: 20px;
  font-weight: 600;
  margin: 0 0 8px 0;
  color: white;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.card-description {
  font-size: 13px;
  line-height: 1.4;
  margin: 0;
  color: rgba(255, 255, 255, 0.9);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.card-description2 {
  font-size: 12px;
  line-height: 1.3;
  margin-top: 12px;
  padding: 6px 10px;
  background-color: rgba(255, 255, 255, 0.15);
  border-radius: 6px;
  color: rgb(255, 220, 180);
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  transition: all 0.3s ease;
  border-left: 3px solid rgb(255, 140, 0);
  max-width: 100%;
  box-sizing: border-box;
  word-break: break-word;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-description2:hover {
  background-color: rgba(255, 255, 255, 0.25);
  color: rgb(255, 240, 200);
  transform: translateX(3px);
}

.attention-icon {
  margin-right: 6px;
  font-size: 14px;
  color: rgb(255, 180, 120);
  flex-shrink: 0;
}

.card-wave {
  position: absolute;
  top: -40px;
  right: -40px;
  width: 180px;
  height: 180px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  z-index: 0;
  transition: all 0.5s ease;
}

.card:not(.disabled-card):hover .card-wave {
  transform: scale(1.2);
}

.primary-card .card-wave {
  background: radial-gradient(circle, rgba(255, 255, 255, 0.25) 0%, rgba(33, 150, 243, 0) 70%);
}

.success-card .card-wave {
  background: radial-gradient(circle, rgba(255, 255, 255, 0.25) 0%, rgba(76, 175, 80, 0) 70%);
}

.purple-card .card-wave {
  background: radial-gradient(circle, rgba(255, 255, 255, 0.25) 0%, rgba(156, 39, 176, 0) 70%);
}

/* 绑定账号对话框样式 */
.account-type-selector {
  width: 100%;
  margin-bottom: 24px;
}

.student-form {
  margin-top: 24px;
}

.dialog-footer {
  display: flex;
  justify-content: center;
  gap: 16px;
  margin-top: 16px;
}

.bind-button, .logout-button {
  padding: 12px 24px;
  font-weight: 500;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.bind-button:hover, .logout-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.bind-button i, .logout-button i {
  margin-right: 8px;
}

/* 切换用户对话框样式 */
:deep(.el-dialog) {
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15);
}

:deep(.el-dialog__header) {
  padding: 20px 24px;
  margin-right: 0;
  border-bottom: 1px solid var(--el-border-color-lighter);
}

:deep(.el-dialog__body) {
  padding: 24px;
}

:deep(.el-dialog__title) {
  font-weight: 600;
  font-size: 18px;
}

:deep(.el-button) {
  border-radius: 8px;
  transition: all 0.3s ease;
}

:deep(.el-space--vertical) {
  width: 100%;
}

:deep(.el-space--vertical .el-button) {
  margin-bottom: 10px;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: auto;
}

:deep(.el-space--vertical .el-button:hover) {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

:deep(.role-switch-dialog .el-dialog__title) {
  position: relative;
  padding-left: 28px;
}

:deep(.role-switch-dialog .el-dialog__title)::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%234CAF50"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm-1-13h2v6h-2zm0 8h2v2h-2z"/></svg>');
  background-repeat: no-repeat;
  background-size: contain;
}

.role-button {
  position: relative;
  overflow: hidden;
}

.role-button::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, transparent 50%, rgba(255, 255, 255, 0.1) 50%);
  z-index: 0;
}

.unit-name {
  font-weight: 500;
  font-size: 15px;
}

:deep(.el-text.el-text--success) {
  font-weight: 500;
  padding: 4px 8px;
  background-color: rgba(103, 194, 58, 0.1);
  border-radius: 4px;
}

.role-tag {
  display: inline-flex;
  align-items: center;
  position: relative;
}

.role-tag::before {
  content: "";
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: #67c23a;
  border-radius: 50%;
  margin-right: 6px;
}

/* 暗黑模式下的样式调整 */
:deep(.dark-dialog .el-dialog) {
  background-color: var(--el-bg-color);
  border: 1px solid var(--el-border-color-darker);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.3);
}

:deep(.dark-dialog .el-dialog__header) {
  border-bottom: 1px solid var(--el-border-color-dark);
}

:deep(.dark-dialog .el-dialog__title) {
  color: var(--el-text-color-primary);
}

:deep(.dark-dialog .el-form-item__label) {
  color: var(--el-text-color-primary);
}

:deep(.dark-dialog .el-input__wrapper) {
  background-color: var(--el-bg-color-overlay);
}

:deep(.dark-dialog .el-input__inner) {
  color: var(--el-text-color-primary);
}

:deep(.dark-dialog .el-text.el-text--success) {
  background-color: rgba(103, 194, 58, 0.15);
}

/* 响应式调整 */
@media (max-width: 768px) {
  .card {
    width: 100%;
    height: auto;
    min-height: 200px;
    max-width: none;
    padding: 20px;
  }
  
  .card-menu-container {
    padding: 12px;
  }
  
  .card-space {
    --el-space-x-gap: 0;
    --el-space-y-gap: 16px;
    width: 100%;
  }
  
  .card-description2 {
    margin-top: 12px;
    width: 100%;
    text-align: left;
    justify-content: flex-start;
    font-size: 12px;
    padding: 6px 8px;
  }
  
  :deep(.el-dialog) {
    width: 90% !important;
    margin: 0 auto;
  }
}

@media (min-width: 769px) and (max-width: 1200px) {
  .card {
    width: 280px;
  }
  
  .card-space {
    --el-space-x-gap: 16px;
    --el-space-y-gap: 16px;
  }
}
</style>