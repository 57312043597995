<template>
  <el-dialog
    v-model="dialogVisible"
    :title="title"
    :width="width"
    :close-on-click-modal="false"
    :show-close="showClose"
    :close-on-press-escape="closeOnPressEscape"
    :custom-class="customClass"
    @closed="handleClosed"
  >
    <div class="dialog-content">
      <p>{{ message }}</p>
      <div class="dialog-buttons">
        <el-button
          v-for="(button, index) in buttons"
          :key="index"
          :type="button.type || (index === selectedIndex ? 'primary' : '')"
          :round="button.round"
          :style="[
            button.style || {},
            index === selectedIndex ? { 'border-width': '2px', 'transform': 'scale(1.05)' } : {}
          ]"
          @click="handleButtonClick(index)"
          @mouseenter="selectedIndex = index"
        >
          {{ button.text }}
        </el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'KeyboardConfirmDialog',
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: '确认'
    },
    message: {
      type: String,
      default: ''
    },
    buttons: {
      type: Array,
      default: () => [
        { text: '取消', type: '' },
        { text: '确认', type: 'primary' }
      ]
    },
    width: {
      type: String,
      default: '30%'
    },
    showClose: {
      type: Boolean,
      default: false
    },
    closeOnPressEscape: {
      type: Boolean,
      default: false
    },
    customClass: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      selectedIndex: 0,
      dialogVisible: this.modelValue
    }
  },
  watch: {
    modelValue(val) {
      this.dialogVisible = val
      if (val) {
        this.selectedIndex = 0
        this.$nextTick(() => {
          window.addEventListener('keydown', this.handleKeydown)
        })
      }
    },
    dialogVisible(val) {
      this.$emit('update:modelValue', val)
      if (!val) {
        window.removeEventListener('keydown', this.handleKeydown)
      }
    }
  },
  methods: {
    handleKeydown(event) {
      if (!this.dialogVisible) return
      
      switch (event.key) {
        case 'ArrowLeft':
          event.preventDefault()
          this.selectedIndex = Math.max(0, this.selectedIndex - 1)
          break
        case 'ArrowRight':
          event.preventDefault()
          this.selectedIndex = Math.min(this.buttons.length - 1, this.selectedIndex + 1)
          break
        case 'Enter':
          event.preventDefault()
          event.stopPropagation()
          this.handleButtonClick(this.selectedIndex)
          break
      }
    },
    handleButtonClick(index) {
      if (this.buttons[index].onClick) {
        this.buttons[index].onClick()
      }
      this.$emit('button-click', index)
      if (this.buttons[index].closeDialog !== false) {
        this.dialogVisible = false
      }
    },
    handleClosed() {
      this.$emit('closed')
      window.removeEventListener('keydown', this.handleKeydown)
    }
  },
  beforeUnmount() {
    window.removeEventListener('keydown', this.handleKeydown)
  }
}
</script>

<style scoped>
.dialog-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.dialog-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 10px;
}
</style> 