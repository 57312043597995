<!--
教师管理界面
最后编辑人：郑志强 -> Claude AI
最后编辑时间：2022-3-8 -> 2024-3-20
最后编辑细节：优化界面布局，支持响应式设计和深色模式
-->
<template>
  <div class="teacher-container" :class="{ 'dark-theme': isDarkMode }" ref="container">
    <!-- 左侧单位树区域 -->
    <div class="left-tree" :class="{ 'collapsed': isTreeCollapsed }" v-if="userStore.user.type != 3">
      <div class="tree-header">
        <h3 class="tree-header-title" v-show="!isTreeCollapsed">单位列表</h3>
        <div class="collapse-btn" @click="isTreeCollapsed = !isTreeCollapsed">
          <i-expand-left v-if="!isTreeCollapsed" theme="outline" size="24" fill="#fff"/>
          <i-expand-right v-else theme="outline" size="24" fill="#fff"/>
        </div>
      </div>
      
      <div class="search-container" v-show="!isTreeCollapsed">
        <el-input
          size="default"
          placeholder="搜索单位"
          prefix-icon="Search"
          v-model="filterName"
          clearable
        ></el-input>
      </div>
      
      <div class="tree-content">
        <el-tree
          :data="treeData"
          :props="treeProps"
          node-key="unit_id"
          default-expand-all
          :filter-node-method="filterNode"
          :expand-on-click-node="false"
          :highlight-current="true"
          @node-click="clickNode"
          ref="unitTree">
          <template #default="{ node, data }">
            <div class="custom-tree-node">
              <span class="tree-icon" :class="data.unit_type === '4' ? 'school-icon' : 'building-icon'"></span>
              <span class="tree-node-label" v-show="!isTreeCollapsed">{{ node.label }}</span>
            </div>
          </template>
        </el-tree>
      </div>
    </div>

    <!-- 主内容区域 -->
    <div class="right-container">
      <div class="right-content">
        <!-- 搜索过滤区域 -->
        <collapsible-pane-search
          @search="toSearch"
          @reset="resetSearch"
          size="mini"
        >
          <el-input
            size="small"
            placeholder="用户姓名"
            prefix-icon="User"
            v-model="search.username"
            clearable
          ></el-input>
          
          <el-input
            size="small"
            placeholder="用户账号"
            prefix-icon="Ticket"
            v-model="search.useraccount"
            clearable
          ></el-input>
        </collapsible-pane-search>
        
        <!-- 操作按钮组 -->
        <div class="operation-card">
          <el-button 
            type="primary" 
            size="default" 
            @click="showAdd" 
            class="action-button add-button"
          >
            <i-plus theme="outline" size="18" />
            <span>添加用户</span>
          </el-button>
          

          <el-popover
                trigger="click"
                ref="popoverUpload"
                placement="bottom"
                :visible="uploadVisi"
                width="360px">
              <template #reference>
                <el-button 
            type="success" 
            size="default" 
            @click="showUpload"
            class="action-button import-button"
            :disabled="!clickUnit.parent_id"
          >
            <i-file-addition theme="outline" size="18" />
            <span>导入</span>
          </el-button>
              </template>
              <div style="width: 100%; text-align: right;"><el-button link icon="Close" @click="closeUpload"></el-button></div>
              <el-upload
                  action="/api/info/user/importteacher"
                  :data="uploadData"
                  name="excelfile"
                  ref="upload"
                  drag
                  :multiple="false"
                  :before-upload="checkUpload"
                  :on-success="afterSuccess">
                <i class="upload"></i>
                <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                <template #tip>
                  <div class="el-upload__tip">只能上传xls或xlsx文件，且不超过10MB</div>
                </template>
              </el-upload>
            </el-popover>
            

       
          
          <el-button 
            type="info" 
            size="default" 
            @click="toDownload"
            class="action-button download-button"
            :disabled="!clickUnit.parent_id"
          >
            <i-download-two theme="outline" size="18" />
            <span>下载导入模板</span>
          </el-button>
        </div>
        
        <!-- 数据表格 -->
        <div class="table-scroll-container">
          <el-table 
            :data="tableData" 
            stripe
            border
            height="100%"
          >
            <el-table-column label="单位" min-width="150" prop="unit_name"></el-table-column>
            <el-table-column prop="user_name" label="用户名称" min-width="100"></el-table-column>
            <el-table-column prop="user_account" label="用户账号" min-width="120"></el-table-column>
            <el-table-column prop="cellphone" label="手机号" min-width="100"></el-table-column>
            <el-table-column label="角色" min-width="200">
              <template #default="{row}">
                <el-space size="small"  wrap>
                  <el-tag 
                    v-for="(type,index) in row.user_types"
                    :key="index"
                    class="prop-tag"
                  >
                    {{uTypesMap[type]?.code_name}}
                  </el-tag>
                </el-space>
              </template>
            </el-table-column>
            <el-table-column prop="valid_until" label="有效期" min-width="120"></el-table-column>
            <el-table-column 
              label="操作" 
              fixed="right" 
              min-width="250"
            >
              <template #default="scope">
                <div >
                  <el-button 
                    type="primary" 
                    text 
                    @click="showEdit(scope.row)" 
                    size="small" 
                    class="table-action-button"
                  >
                    <i-edit theme="outline" size="16" />
                    <span class="action-text">编辑</span>
                  </el-button>
                  
                  <el-button 
                    type="warning" 
                    text 
                    @click="popReset(scope.row)" 
                    size="small" 
                    class="table-action-button"
                  >
                    <i-refresh theme="outline" size="16" />
                    <span class="action-text">重置密码</span>
                  </el-button>
                  
                  <el-button 
                    type="danger" 
                    text 
                    @click="toDel(scope.row)" 
                    size="small" 
                    class="table-action-button"
                  >
                    <i-delete theme="outline" size="16" />
                    <span class="action-text">删除</span>
                  </el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        
       
        <!-- 分页器 -->
        <div class="pagination-container">
          <el-pagination
            @current-change="toPage"
            :current-page="search.page"
            :page-size="search.pagesize"
            layout="total, sizes, prev, pager, next, jumper"
            :page-sizes="[15, 30, 50, 100]"
            @size-change="handleSizeChange"
            :total="total"
            background
          ></el-pagination>
        </div>
      </div>
    </div>

    <!-- 用户表单对话框 -->
    <el-dialog
      :title="formTitle"
      v-model="dialogVisi"
      width="750px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      class="teacher-dialog"
    >
      <el-form :model="userData" :rules="rules" ref="userForm" label-width="150px">
        <el-form-item label="单位">
          <span>{{ userStore.user.type == "3" ? userStore.unit.name : userData.unit_name}} </span>
        </el-form-item>
        <el-form-item label="用户账号" prop="useraccount" v-show="userData.userid!==null">
          <span>{{ userData.useraccount }}</span>
        </el-form-item>
        <el-form-item label="登录密码" prop="userpwd" v-show="userData.userid===null">
          <el-input type="password" v-model="userData.userpwd" :disabled="userData.userid!==null || unedit"></el-input>
        </el-form-item>
        <el-form-item label="用户名称" prop="username">
          <el-input v-model="userData.username" :disabled="unedit"></el-input>
        </el-form-item>
        <el-form-item label="角色" prop="user_types">
          <el-select multiple v-model="userData.user_types" @remove-tag="userTypeRemove">
            <el-option 
              v-for="item in userTypes"
              :key="item.code"
              :value="item.code"
              :disabled="(userData.unit_parent_id === null && item.code !== '2') || (userData.unit_parent_id !== null && ['1','2','6','9','10'].includes(item.code))"
              :label="item.code_name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="学科教研员科目" v-show="userData.user_types.includes('7')">
          <el-select v-model="userData.researcherSubject" multiple>
            <el-option
              v-for="item in subjectTags"
              :key="item.tag_id"
              :label="item.tag_name"
              :value="item.tag_id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="班主任所带班级" v-show="userData.user_types.includes('11')">
          <el-checkbox-group v-model="userData.classLeaderClass">
            <el-checkbox
              v-for="(item,index) in schoolGradesClass"
              :key="index"
              :label="item.grade + ' ' + item.class_name"
              :value="item.grade_id+ '-' + item.grade + '-' + item.class_id + '-' + item.class_name + '-' + item.school"
            />
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="任课教师科目班级" v-show="userData.user_types.includes('8')">
          <el-select v-model="userData.teacherSubject">
            <el-option
              v-for="item in subjectTags"
              :key="item.tag_id"
              :label="item.tag_name"
              :value="item.tag_id"
            />
          </el-select>
          <el-checkbox-group 
            v-model="userData.teacherGradeClass[userData.teacherSubject]" 
            :disabled="userData.teacherSubject === null"
          >
            <el-checkbox
              v-for="(item,index) in schoolGradesClass"
              :key="index"
              :label="item.grade + ' ' + item.class_name"
              :value="item.grade_id+ '-' + item.grade + '-' + item.class_id + '-' + item.class_name + '-' + item.school"
            />
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="年级组长所属年级" v-show="userData.user_types.includes('5')">
          <el-checkbox-group v-model="userData.gradeLeadergrades">
            <el-checkbox
              v-for="(grade,grade_id) in schoolGrades"
              :key="grade_id"
              :label="grade"
              :value="{grade_id:parseInt(grade_id),grade}"
            />
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="手机号" prop="cellphone">
          <el-input v-model="userData.cellphone" :disabled="unedit"></el-input>
        </el-form-item>
        <el-form-item label="Email" prop="email">
          <el-input v-model="userData.email" :disabled="unedit"></el-input>
        </el-form-item>
        <el-form-item label="有效期" prop="until">
          <el-date-picker
            v-model="userData.until"
            type="date"
            format="YYYY-MM-DD"
            date-format="YYYY-MM-DD ddd"
            value-format="YYYY-MM-DD"
            time-format="YYYY-MM-DD"
            style="width: 140px"
            :disabled="unedit"
            :clearable="false"
          />
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="closeDialog">取 消</el-button>
          <el-button type="primary" @click="toSave">保 存</el-button>
        </div>
      </template>
    </el-dialog>

    <!-- 重置密码弹窗 -->
    <el-dialog
      title="重置密码"
      v-model="resetPwdVisible"
      width="400px"
      class="teacher-dialog"
    >
      <div class="reset-pwd-content">
        <el-input 
          v-model="resetPwd" 
          placeholder="请输入新密码"
          type="password"
        ></el-input>
        <div class="pwd-tip">如果不输入新密码则默认重置为az741369</div>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="resetPwdVisible = false">取 消</el-button>
          <el-button type="primary" @click="toResetPwd">确 定</el-button>
        </div>
      </template>
    </el-dialog>

    <!-- 导入错误提示对话框 -->
    <el-dialog
      title="导入数据出错"
      v-model="errorVisi"
      width="700px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      class="teacher-dialog"
    >
      <el-table :data="errorData" border stripe>
        <el-table-column prop="row" label="行号" width="80"></el-table-column>
        <el-table-column prop="msg" label="错误信息" min-width="500"></el-table-column>
      </el-table>
    </el-dialog>

    <!-- 下载链接占位 -->
    <a id="downlink" href="/api/info/user/downimporttemplate" v-show="false"></a>
  </div>
</template>

<script>
import { useUserStoreWithOut } from "@/store/modules/user";
import { useCodeStoreWithOut } from "@/store/modules/code";
import { useTagStoreWithOut } from "@/store/modules/tag";
import themeManager from "@/utils/ThemeManager";
import CollapsiblePaneSearch from "@/components/common/CollapsiblePaneSearch.vue";

export default {
  name: "TeacherUser",
  components: {
    CollapsiblePaneSearch
  },
  setup() {
    const userStore = useUserStoreWithOut();
    const codeStore = useCodeStoreWithOut();
    const tagStore = useTagStoreWithOut();
    return { userStore, codeStore, tagStore };
  },
  data() {
    return {
      isDarkMode: themeManager.isDarkMode(),
      isTreeCollapsed: false,
      themeUnsubscribe: null,
      resetPwdVisible: false,
      filterUserTypes:[], // 根据当前用户筛选过的角色list
      clickUnit: {}, // 左侧点击的单位
      userTypes:[],
      filterName: "",
      treeData: [],
      treeProps: {
        children: 'subunit',
        label: 'unit_name'
      },
      uploadVisi: false,
      uploadData:{
        unitid: null
      },
      errorVisi: false,
      errorData: [],
      tableTitle: "",
      total: 0,
      search: {
        page: 1,
        pagesize: 15,
        unitid: null,
        username: "",
        zhiwei: "",
        kemu: ""
      },
      tableData: [],
      resetPwd: "",
      zhiweiTags: [],
      userTags: [],
      subjectTags: [],
      unedit: true,
      dialogVisi: false,
      formTitle: "用户",
      userData: {
        unit_name: "",
        userid: null,
        useraccount: "",
        username: "",
        userpwd: "",
        cellphone: "",
        email: "",
        user_types:"",
        until: null,
        unitid: null,
        unit_parent_id: null,
        usertags: null,
        subjects: null,
        gradeLeadergrades:[], // 年级组长所属年级
        teacherGradeClass:{},// 任课教师所带科目班级
        teacherSubject:null, // 任课教师所教科目
        researcherSubject:[], // 学科教研员科目
        classLeaderClass:[],// 班主任所带班级
      },
      rules: {
        username: [{required: true, message: "请填写用户名称", trigger: "blur"}, {max: 30, message: "不能超过30个字", trigger: "blur"}],
        userpwd: [{required: true, message: "请填写登录密码", trigger: "blur"}, {max: 20, message: "不能超过20个字符", trigger: "blur"}],
        user_types: [{required: true, message: "请选择角色", trigger: "blur"}],
       // cellphone: [{required: true, message: "请填写手机号", trigger: "blur"},{pattern: /^\d*$/, message: "手机号必须是数字", trigger: "blur"}],
        email: [{type: "email", message: "请填写有效格式邮箱", trigger: "blur"}]
      },
      schoolGradesClass:[],
    };
  },
  computed:{
    schoolGrades(){
      const res = {}
      this.schoolGradesClass.forEach(item => {
        res[item.grade_id] = item.grade
      })
      return res
    },
    uTypesMap(){
      let map = {}
      for(const item of this.userTypes){
        map[item.code] = item
      }
      return map
    }
  },
  watch: {
    filterName (val) {
      this.$refs.unitTree.filter(val)
    },
    'userStore.user'(val){
      this.loadCodes()
      if (this.userStore.user.type == "3"){ // 学校管理员
        this.getUnitGradesAndClass(this.userStore.unit.unit_id)
      }
    }
  },
  mounted() {
    // 使用主题管理器监听主题变化
    this.themeUnsubscribe = themeManager.onThemeChange(this.handleThemeChange);
    
    // 初始化主题状态
    this.isDarkMode = themeManager.isDarkMode();
    
    this.loadTreeData()
    this.loadCodes()
    this.tagStore.getTag("subject").then((subjects) => {
      this.subjectTags = subjects
    })
  },
  beforeUnmount() {
    // 清除主题变化订阅
    if (this.themeUnsubscribe) {
      this.themeUnsubscribe();
      this.themeUnsubscribe = null;
    }
  },
  methods: {
    // 主题变化处理
    handleThemeChange(theme) {
      window.requestAnimationFrame(() => {
        document.documentElement.classList.add('disable-transitions');
        this.isDarkMode = theme === 'dark';
        setTimeout(() => {
          document.documentElement.classList.remove('disable-transitions');
        }, 100);
      });
    },
    // 重置搜索条件
    resetSearch() {
      this.search.username = "";
      this.search.useraccount = "";
      this.search.page = 1;
      this.loadUser();
    },
    
    // 处理页面大小变化
    handleSizeChange(val) {
      this.search.pagesize = val;
      this.toSearch();
    },
    // 重置密码相关方法
    popReset(row) {
      this.currentUser = row;
      this.resetPwd = "";
      this.resetPwdVisible = true;
    },
    
    toResetPwd() {
      if (!this.currentUser) return;
      
      this.axios.post("/api/info/user/resetpwd", {
        user_id: this.currentUser.user_id,
        newpwd: this.resetPwd || "az741369"
      }).then((response) => {
        if (response.data.success) {
          this.$message({
            message: "密码重置成功",
            type: "success"
          });
          this.resetPwdVisible = false;
        }
      });
    },
    // 用户角色删除
    userTypeRemove(val){
      if (val == 4){
        this.userData.user_types.push('4')
        this.$message({message: "普通角色无法删除", type: "warning"})
      }
    },
    //载入单位树形列表
    loadTreeData () {
      this.axios.get("/api/info/user/loadtreeeduunit")
          .then((response) => {
            if (response.data.success) {
              this.treeData = response.data.result
              if (this.treeData.length > 0){
                this.clickUnit = this.treeData[0]
                this.search.unitid = this.treeData[0].unit_id
                this.loadUser()
              }
            }

          })

    },
    //查找树节点
    filterNode (value, data) {
      if (!value) return true;
      return data.unit_name.indexOf(value) !== -1;
    },
    //点击树节点
    clickNode (data) {
      this.tableTitle = data.unit_name
      this.search.unitid = data.unit_id
      this.uploadData.unitid = data.unit_id
      this.clickUnit = data
      this.loadUser()
      if (data.parent_id){
        // 学校单位，查这个学校的年级
        this.getUnitGradesAndClass(data.unit_id)
      }
    },
    // 获取某个学校单位的年级和班级
    async getUnitGradesAndClass(unit_id){
      const resp = await this.axios.post("/api/info/user/getUnitGradesAndClass",{unit_id:unit_id})
      if (resp.data.success){
        this.schoolGradesClass = resp.data.result

      }
    },
    //载入代码列表
    async loadCodes () {
      const uType = this.userStore.user.type
      const codes = await this.codeStore.getCode("user_type");
      this.userTypes = codes
      const filterCodes = []
      /**
       * [
       *     {
       *         "code_id": 12,
       *         "type_code": "user_type",
       *         "type_name": "用户类型",
       *         "code": "1",
       *         "code_name": "核心管理员"
       *     },
       *     {
       *         "code_id": 13,
       *         "type_code": "user_type",
       *         "type_name": "用户类型",
       *         "code": "2",
       *         "code_name": "经销商"
       *     },
       *     {
       *         "code_id": 14,
       *         "type_code": "user_type",
       *         "type_name": "用户类型",
       *         "code": "3",
       *         "code_name": "学校管理员"
       *     },
       *     {
       *         "code_id": 17,
       *         "type_code": "user_type",
       *         "type_name": "用户类型",
       *         "code": "4",
       *         "code_name": "普通用户"
       *     },
       *     {
       *         "code_id": 43,
       *         "type_code": "user_type",
       *         "type_name": "用户类型",
       *         "code": "5",
       *         "code_name": "年级组长"
       *     },
       *     {
       *         "code_id": 46,
       *         "type_code": "user_type",
       *         "type_name": "用户类型",
       *         "code": "6",
       *         "code_name": "考试管理员"
       *     },
       *     {
       *         "code_id": 47,
       *         "type_code": "user_type",
       *         "type_name": "用户类型",
       *         "code": "7",
       *         "code_name": "学科教研员"
       *     },
       *     {
       *         "code_id": 48,
       *         "type_code": "user_type",
       *         "type_name": "用户类型",
       *         "code": "8",
       *         "code_name": "任课教师"
       *     },
       *     {
       *         "code_id": 49,
       *         "type_code": "user_type",
       *         "type_name": "用户类型",
       *         "code": "9",
       *         "code_name": "学生"
       *     }
       * ]
       */
      codes.forEach(code => {
        if (uType == "1"){
          // 核心管理员
          if (["1","2","3","4","5","8"].includes(code.code)){
            filterCodes.push(code)
          }
        }else if (uType == "2"){
          // 经销商
          if (["2","3","4","5","8"].includes(code.code)){
            filterCodes.push(code)
          }
        }else if (uType == "3"){
          //  学校管理员
          if (["3","4","5","8"].includes(code.code)){
            filterCodes.push(code)
          }
        }
      })
      this.filterUserTypes = filterCodes
    },
    //载入用户列表
    loadUser () {
      this.axios.post("/api/info/user/loadeduuser", this.search)
          .then((response) => {
            if (response.data.success) {
              this.total = response.data.result.totalrecords
              this.tableData = response.data.result.datalist
            }

          })

    },
    //页码跳转
    toPage (page) {
      this.search.page = page
      this.loadUser()
    },
    //条件查询
    toSearch () {
      this.search.page = 1
      this.loadUser()
    },
    //关闭对话框
    closeDialog () {
      this.$refs["userForm"].resetFields()
      this.dialogVisi = false
      this.userData.userid = null
      this.userData.useraccount = ""
      this.userData.username = ""
      this.userData.userpwd = ""
      this.userData.cellphone = ""
      this.userData.email = ""
      this.userData.until = null
      this.userData.unitid = this.search.unitid
      this.userData.unit_name = ""
      this.userData.user_types = ["4"]
    },
    //新增
    showAdd () {
      if (this.search.unitid !== null || this.userStore.user.type == 3) {
        this.dialogVisi = true
        this.unedit = false
        this.userData.userid = null
        this.userData.useraccount = ""
        this.userData.username = ""
        this.userData.unit_name = this.tableTitle
        this.userData.userpwd = ""
        this.userData.cellphone = ""
        this.userData.email = ""
        this.userData.until = null
        this.userData.unitid = this.search.unitid
        if (this.clickUnit.parent_id === null){
          // 经销商单位,就默认只有一种角色
          this.userData.user_types = ["2"]
        }else {
          // 学校单位
          this.userData.user_types = ["4"]
        }

        this.formTitle = "新增用户"
        if (this.$refs["userForm"])
          this.$refs["userForm"].clearValidate()
      }
      else
        this.$message({message: "请先选择单位", type: "warning"})
    },
    //编辑
    showEdit (data) {
      this.dialogVisi = true
      this.unedit = false
      this.userData.userid = data.user_id
      this.userData.useraccount = data.user_account
      this.userData.username = data.user_name
      this.userData.userpwd = "x"
      this.userData.cellphone = data.cellphone
      this.userData.email = data.email
      this.userData.until = data.valid_until
      this.userData.unitid = data.unit_id
      this.userData.unit_name = data.unit_name
      this.userData.unit_parent_id = data.parent_id
      this.userData.user_types = data.user_types
      this.userData.gradeLeadergrades = data.gradeleadergrades ? data.gradeleadergrades : []
      if (data.teachergradeclass){
        const subjectIds = Object.keys(data.teachergradeclass)
        if (subjectIds.length > 0){
          this.userData.teacherSubject = parseInt(subjectIds[0])
          subjectIds.forEach(subject_id => {
            const arr = data.teachergradeclass[subject_id]
            this.userData.teacherGradeClass[subject_id] = []
            arr.forEach(a => {
              this.userData.teacherGradeClass[subject_id].push(a.grade_id+"-"+a.grade+'-'+a.class_id+'-'+a.class_name+'-'+a.school)
            })
          })
        }else {
          this.userData.teacherSubject = null
        }

        // this.userData.teacherGradeClass = data.teachergradeclass
      }else {
        this.userData.teacherSubject = null
        this.userData.teacherGradeClass = {}
      }
      this.userData.researcherSubject = data.researchersubject ? data.researchersubject : []

      this.userData.classLeaderClass = []
      if (data.classleaderclass){
        data.classleaderclass.forEach(a => {
          this.userData.classLeaderClass.push(a.grade_id+"-"+a.grade+'-'+a.class_id+'-'+a.class_name+'-'+a.school)
        })
      }

      this.formTitle = "编辑用户"
      if (this.$refs["userForm"])
        this.$refs["userForm"].clearValidate()
    },
    //删除
    toDel (data) {
      this.$confirm("确认删除此用户?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.axios.post("/api/info/user/deluser", {user_id: data.user_id,unit_id:data.unit_id})
            .then((response) => {
              if (response.data.success) {
                this.$message({message: "用户删除成功", type: "success"})
                if (data.user_id === this.userData.userid) {
                  this.$refs["userForm"].resetFields()
                  this.unedit = true
                  this.userData.userid = null
                  this.userData.useraccount = ""
                  this.userData.username = ""
                  this.userData.userpwd = ""
                  this.userData.cellphone = ""
                  this.userData.email = ""
                  this.userData.until = null
                  this.userData.unitid = null
                  this.formTitle = "用户"
                }
                this.loadUser()
              }

            })

      })
    },
    //保存
    toSave () {
      this.$refs["userForm"].validate((valid) => {
        if (valid) {
          const newTeacherGradeClass = {}
          const newClassLeaderClass = []
          const data = {...this.userData,teacherGradeClass:newTeacherGradeClass,classLeaderClass:newClassLeaderClass}
          Object.keys(this.userData.teacherGradeClass).forEach(key => {
            newTeacherGradeClass[key] = []
            const list = this.userData.teacherGradeClass[key]

            for (let i = 0; i < list.length; i++) {
              const arr = list[i].split("-")
              const res = {}
              res["grade_id"] = parseInt(arr[0])
              res["grade"] = arr[1]
              res["class_id"] = parseInt(arr[2])
              res["class_name"] = arr[3]
              res["school"] = parseInt(arr[4])
              newTeacherGradeClass[key].push(res)
            }

          })
          this.userData.classLeaderClass.forEach(item => {
            const arr = item.split("-")
            const res = {}
            res["grade_id"] = parseInt(arr[0])
            res["grade"] = arr[1]
            res["class_id"] = parseInt(arr[2])
            res["class_name"] = arr[3]
            res["school"] = parseInt(arr[4])
            newClassLeaderClass.push(res)
          })
          this.axios.post("/api/info/user/saveuser", data)
              .then((response) => {
                if (response.data.success) {
                  this.$message({message: "用户保存成功", type: "success"})
                  this.loadUser()
                  this.closeDialog()
                }
              })

        }
        else
          return false
      })
    },
    //显示上传框
    showUpload () {
      if (this.uploadData.unitid != null) {
        this.uploadVisi = true
        this.$refs["upload"].clearFiles()
      }
      else
        this.$message({message: "请先选择单位", type: "warning"})
    },
    //关闭上传框
    closeUpload () {
      this.uploadVisi = false
    },
    //上传前检查
    checkUpload (file) {
      if (this.uploadData.unitid == null) {
        this.$message({message: "请先选择单位", type: "warning"})
        return false
      }
      let name = file.name.toLowerCase()
      if (!name.endsWith(".xls") && !name.endsWith(".xlsx")) {
        this.$message({message: "只能上传xls或xlsx文件", type: "warning"})
        return false
      }
      return true
    },
    //上传后处理
    afterSuccess (data) {
      this.$refs["upload"].clearFiles()
      if (data.success) {
        this.$message({message: "导入成功", type: "success"})
        this.loadUser()
        this.uploadVisi = false
      }
      else {
        if (typeof data.result === "string")
          this.$alert(data.result, "导入出错")
        else {
          this.errorData = data.result
          this.errorVisi = true
        }
      }
    },
    //下载模板
    toDownload () {
      document.getElementById("downlink").click()
    }
  }
}
</script>

<style scoped>
/* 布局结构 */
.teacher-container {
  --background-primary: var(--el-bg-color);
  --background-secondary: var(--el-fill-color-light);
  --text-primary: var(--el-text-color-primary);
  --text-secondary: var(--el-text-color-secondary);
  --border-color: var(--el-border-color);
  --shadow-color: rgba(0, 0, 0, 0.05);
  
  background-color: var(--background-primary);
  color: var(--text-primary);
  display: flex;
  height: 100%;
  position: relative;
  transition: background-color 0.3s, color 0.3s;
  overflow: hidden;
  margin-top: 0;
}

/* 左侧树形结构 */
.left-tree {
  width: 280px;
  height: 100%;
  transition: width 0.3s ease, box-shadow 0.3s ease;
  border-right: 1px solid var(--border-color);
  background-color: var(--background-primary);
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  overflow: hidden;
  box-shadow: 0 0 10px var(--shadow-color);
  position: relative;
  z-index: 5;
}

.left-tree.collapsed {
  width: 60px;
}

.tree-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid var(--border-color);
  background: linear-gradient(135deg, var(--primary-color), var(--primary-dark));
  color: white;
}

.tree-header-title {
  font-weight: 500;
  font-size: 18px;
  margin: 0;
  white-space: nowrap;
  color: white;
}

.collapse-btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  transition: all 0.3s;
  background-color: rgba(255, 255, 255, 0.1);
}

.collapse-btn:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.search-container {
  padding: 16px;
}

.tree-content {
  flex: 1;
  overflow: auto;
  padding: 12px;
  height: 0;
}

/* 右侧内容区域 */
.right-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: margin-left 0.3s;
}

.right-content {
  flex: 1;
  padding: 16px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  position: relative;
}

/* 操作按钮区域 */
.operation-card {
  background-color: var(--background-primary);
  border-radius: 12px;
  padding: 10px;
  box-shadow: 0 4px 12px var(--shadow-color);
  flex-shrink: 0;
  transition: box-shadow 0.3s ease;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-bottom: 16px;
}

.operation-card:hover {
  box-shadow: 0 6px 16px var(--shadow-color);
}

/* 表格区域 */
.table-scroll-container {
  width: 100%;
  overflow-x: hidden;
  margin-bottom: 60px;
}

.table-scroll-container :deep(.el-table) {
  width: 100%;
  min-width: 650px;
}

/* 表格操作按钮 */
.table-actions {
  display: flex;
  gap: 4px;
  justify-content: center;
}

.table-action-button {
  padding: 4px 8px;
  border-radius: 4px;
  transition: all 0.2s ease;
}

.table-action-button:hover {
  background-color: var(--el-fill-color-light);
}

.table-action-button .action-text {
  margin-left: 4px;
  font-size: 12px;
}

/* 分页器 */
.pagination-container {
  position: absolute;
  bottom: 16px;
  left: 0;
  right: 0;
  background-color: var(--background-primary);
  padding: 8px 0;
  z-index: 5;
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.05);
}

/* 对话框样式 */
.teacher-dialog :deep(.el-dialog) {
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
}

.teacher-dialog :deep(.el-dialog__header) {
  padding: 20px 24px;
  margin: 0;
  background-color: var(--background-primary);
  border-bottom: 1px solid var(--border-color);
}

.teacher-dialog :deep(.el-dialog__body) {
  padding: 24px;
}

.teacher-dialog :deep(.el-dialog__footer) {
  padding: 16px 24px;
  border-top: 1px solid var(--border-color);
  background-color: var(--background-primary);
}

/* 移动端适配 */
.mobile-block-visi {
  display: none;
}

.mobile-block-not-visi {
  display: block;
}

@media (max-width: 768px) {
  .mobile-block-not-visi {
    display: none;
  }
  
  .mobile-block-visi {
    display: block;
  }
  
  .teacher-container {
    flex-direction: column;
    height: 100%;
    overflow: hidden;
  }
  
  .left-tree {
    display: none;
  }
  
  .operation-card {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
    padding: 10px;
  }
  
  .action-button {
    height: 36px;
    padding: 0 10px;
    min-width: 80px;
    width: 100%;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
  }
  
  .action-button span {
    margin-left: 4px;
    display: inline-block;
  }
  
  .right-content {
    padding: 8px;
    overflow: auto;
    position: relative;
  }
  
  .table-scroll-container {
    margin: 0 -8px 60px -8px;
    width: calc(100% + 16px);
    padding: 0 8px;
    overflow-x: auto;
  }
}

/* 保持原有的特定样式 */
.prop-tag {
  margin-right: 15px;
}

.pwd-tip {
  padding: 15px 5px;
  font-size: 13px;
  color: #a9a9a9;
}
.school-icon::before {
  content: '🏫';
}

.building-icon::before {
  content: '🏢';
}
</style>
