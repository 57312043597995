<template>
  <div class="phone-login-container">
    <div class="phone-input-group">
      <div class="phone-input-wrapper">
        <custom-input
          v-model:modelValue="loginForm.phone"
          icon="fas fa-mobile-alt"
          placeholder="请输入手机号"
          :has-error="errors.phone !== ''"
          :error-message="errors.phone"
          class="phone-input"
        />
      </div>
      <button 
        class="verification-code-button" 
        @click="getVerificationCode"
        :disabled="countDown > 0"
      >
        {{ countDown > 0 ? `${countDown}s` : '获取验证码' }}
      </button>
    </div>
    
    <div v-if="showCodeInput" class="verification-code-wrapper">
      <verification-code-input
        v-model:modelValue="loginForm.code"
        :phone="loginForm.phone.trim()"
        @error="handleError"
        @success="handleSuccess"
        @code-completed="autoLogin"
        class="verification-code-input"
      />
    </div>
  </div>
</template>

<script>
import CustomInput from './CustomInput.vue'
import CustomButton from './CustomButton.vue'
import VerificationCodeInput from './VerificationCodeInput.vue'

export default {
  name: 'PhoneLoginForm',
  components: {
    CustomInput,
    CustomButton,
    VerificationCodeInput
  },
  data() {
    return {
      loginForm: {
        phone: '',
        code: ''
      },
      errors: {
        phone: '',
        code: ''
      },
      countDown: 0,
      timer: null,
      showCodeInput: false
    }
  },
  beforeUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  methods: {
    handleError(message) {
      this.$emit('error', message)
    },
    handleSuccess(message) {
      this.$emit('success', message)
    },
    validate() {
      let isValid = true
      
      // Reset errors
      this.errors = {
        phone: '',
        code: ''
      }
      
      const phone = this.loginForm.phone.trim()
      if (!phone) {
        this.errors.phone = '请输入手机号'
        isValid = false
      } else if (!this.checkPhone(phone)) {
        this.errors.phone = '手机号格式不正确'
        isValid = false
      }
      
      if (!this.loginForm.code.trim()) {
        this.errors.code = '请输入验证码'
        isValid = false
      } else if (this.loginForm.code.trim().length !== 4) {
        this.errors.code = '验证码必须是4位'
        isValid = false
      }
      
      return isValid
    },
    checkPhone(phone) {
      let myreg = /^1[3456789]\d{9}$/
      return myreg.test(phone)
    },
    autoLogin() {
      if (this.loginForm.code.length === 4) {
        this.doLogin();
      }
    },
    doLogin() {
      if (this.validate()) {
        // Format data to match API expectations
        const loginData = {
          phone: this.loginForm.phone.trim(),
          code: this.loginForm.code.trim()
        }
        
        this.$emit('verify', loginData)
      }
    },
    async getVerificationCode() {
      // 检查手机号是否有效
      if (!this.loginForm.phone.trim()) {
        this.handleError('手机号不能为空')
        return
      }
      
      if (!this.checkPhone(this.loginForm.phone.trim())) {
        this.handleError('手机号格式不正确')
        return
      }
      
      try {
        const { data } = await this.axios.post("/api/login/sendPhoneCode", { phone: this.loginForm.phone.trim() })
        if (data.success) {
          this.startTimer()
          this.showCodeInput = true
          this.handleSuccess('验证码发送成功')
        } else {
          this.handleError(data.result)
        }
      } catch (error) {
        this.handleError('验证码发送失败')
      }
    },
    startTimer() {
      this.countDown = 64
      this.timer = setInterval(() => {
        this.countDown--
        if (this.countDown <= 0) {
          clearInterval(this.timer)
        }
      }, 1000)
    }
  }
}
</script>

<style scoped>
.phone-login-container {
  width: 100%;
  max-width: 380px;
}

.phone-input-group {
  display: flex;
  gap: 8px;
  margin-bottom: 32px;
  width: 100%;
  align-items: stretch;
}

.phone-input-wrapper {
  flex-grow: 1;
}

.phone-input {
  width: 100%;
}

.verification-code-button {
  background-color: #2196f3;
  color: white;
  border: none;
  border-radius: 8px;
  min-width: 100px;
  padding: 0 15px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  transition: background-color 0.3s;
}

/* 移除错误消息对高度的影响 */
:deep(.custom-input-container) {
  margin-bottom: 0;
}

/* 确保只有错误消息才有下边距 */
:deep(.error-message) {
  margin-bottom: 16px;
  margin-top: 4px;
}

.verification-code-button:hover:not(:disabled) {
  background-color: #1976d2;
}

.verification-code-button:disabled {
  background-color: #90caf9;
  cursor: not-allowed;
}

.verification-code-wrapper {
  margin-bottom: 16px;
  margin-top: 10px;
  width: 100%;
  padding: 0;
}

.verification-code-input {
  width: 100%;
}

.login-button {
  width: 100%;
  margin-top: 24px;
}
</style> 