<!--
登录界面
最后编辑人：
最后编辑时间：
最后编辑细节：优化登录页面样式，分解成多个组件便于维护
-->
<template>
  <div class="login-page">
    <div class="login-container">
      <div class="login-card">
        <div class="login-header">
          <h2 class="login-title">沐昀网上阅卷平台</h2>
          <p class="login-subtitle">欢迎使用，请登录您的账号</p>
        </div>
        
        <div class="tab-container">
          <div 
            class="tab-item" 
            :class="{ 'active': activeTab === 'account' }"
            @click="activeTab = 'account'"
          >
            账号密码登录
          </div>
          <div 
            class="tab-item" 
            :class="{ 'active': activeTab === 'phone' }"
            @click="activeTab = 'phone'"
          >
            手机验证码登录
          </div>
        </div>
        
        <div class="login-form-container">
          <transition name="fade" mode="out-in">
            <keep-alive>
              <component :is="activeComponent" 
                @login="handleAccountLogin"
                @verify="handlePhoneLogin"
                @error="handleError"
                @success="handleSuccess"
              />
            </keep-alive>
          </transition>
        </div>
      </div>
      
      <div class="login-info">
        <div class="info-content">
          <h2 class="info-title">沐昀网上阅卷</h2>
          <p class="info-text">
            答题卡智能裁剪、智能识别、整个阅卷流程系统化、数据分析智能化。
          </p>
          <div class="info-actions">
            <custom-button @click="toDownload">下载扫描客户端</custom-button>
            <custom-button type="text">关于我们</custom-button>
          </div>
        </div>
        <div class="info-image">
          <img src="../../assets/img-01.png" alt="阅卷系统插图" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AccountLoginForm from './components/AccountLoginForm.vue'
import PhoneLoginForm from './components/PhoneLoginForm.vue'
import CustomButton from './components/CustomButton.vue'
import { ElMessage, ElMessageBox } from 'element-plus'

export default {
  name: 'Login',
  components: {
    AccountLoginForm,
    PhoneLoginForm,
    CustomButton
  },
  data() {
    return {
      activeTab: 'account',
      isDownloading: false,
      isLoggingIn: false
    }
  },
  computed: {
    activeComponent() {
      return this.activeTab === 'account' ? 'AccountLoginForm' : 'PhoneLoginForm'
    }
  },
  mounted() {
    if (this.checkIfMobile()) {
      this.$router.replace({ name: 'm_login' })
    }
  },
  methods: {
    handleAccountLogin(loginData) {
      if (this.isLoggingIn) return; // 防止重复请求
      this.isLoggingIn = true;

      this.axios.post('/api/login/dologin', loginData)
        .then(response => {
          if (response.data.success) {
            this.toMainContent()
          }
        })
        .catch(error => {
          console.error('登录失败', error)
          this.handleError('登录失败，请检查账号密码')
        })
        .finally(() => {
          this.isLoggingIn = false;
        });
    },
    
    async handlePhoneLogin(loginData) {
      if (this.isLoggingIn) return; // 防止重复请求
      this.isLoggingIn = true;

      try {
        const { data } = await this.axios.post('/api/login/verifyPhoneCode', {
          phone: loginData.phone,
          sms_code: loginData.code
        })
        if(data.success === false){
          // throw new Error(data.result)
          return
        }

        const password = data.result.password
        
        if (password) {
          ElMessageBox.alert(`新用户您好！您的密码是: ${password}, 进入系统后可以进行修改`, '提示', {
            confirmButtonText: '我知道了',
            callback: this.toMainContent
          })
        } else {
          this.toMainContent()
        }
      } catch (error) {
        console.error('验证码验证失败', error)
        this.handleError('验证码验证失败')
      } finally {
        this.isLoggingIn = false;
      }
    },
    
    toMainContent() {
      sessionStorage.setItem('is-login', '1')
      sessionStorage.removeItem('redirect')
      this.$router.replace({ name: 'cardMenu' })
    },
    
    toDownload() {
      if (this.isDownloading) return; // 防止重复点击
      this.isDownloading = true;
      
      this.axios({
        method: 'post',
        url: '/api/common/download/1',
        data: {},
        responseType: 'blob'
      }).then(res => {
        const link = document.createElement('a')
        if (res.headers['content-type'] === 'application/json;charset=UTF-8') {
          res.data.text().then(text => {
            const json = JSON.parse(text)
            this.handleError(json.result)
          }).catch(error => {
            console.error('解析JSON出错:', error)
            this.handleError('解析JSON出错:' + error)
          })
        } else {
          let blob = new Blob([res.data], { type: 'application/octet-stream' })
          link.style.display = 'none'
          link.href = URL.createObjectURL(blob)
          const fileName = res.headers['content-disposition'].split(';')[1].split('=')[1]
          link.setAttribute('download', decodeURIComponent(fileName))
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        }
      }).catch(err => {
        console.error(err)
        this.handleError('下载失败！')
      }).finally(() => {
        this.isDownloading = false;
      })
    },
    
    checkIfMobile() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera
      return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent.toLowerCase())
    },
    
    handleError(message) {
      ElMessage.error(message)
    },
    
    handleSuccess(message) {
      ElMessage.success(message)
    }
  }
}
</script>

<style scoped>
.login-page {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8f9fa;
  padding: 20px;
  overflow: hidden;
  box-sizing: border-box;
}

.login-container {
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: row;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  overflow: hidden;
  background-color: white;
  min-height: 600px;
}

.login-card {
  flex: 1;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 600px; /* 固定高度 */
  box-sizing: border-box;
}

.login-header {
  text-align: center;
  margin-bottom: 24px;
}

.login-title {
  font-size: 28px;
  font-weight: 600;
  color: #333;
  margin-bottom: 8px;
}

.login-subtitle {
  font-size: 16px;
  color: #666;
}

.tab-container {
  display: flex;
  width: 100%;
  max-width: 380px;
  margin-bottom: 24px;
  background-color: #f5f5f5;
  border-radius: 8px;
  overflow: hidden;
  min-height: 50px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.tab-item {
  flex: 1;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 16px;
  font-weight: 500;
  color: #333;
  user-select: none;
  line-height: 50px;
  height: 50px;
}

.tab-item.active {
  background-color: #2196f3;
  color: white;
  font-weight: 600;
}

.login-form-container {
  width: 100%;
  display: flex;
  justify-content: center;
  transition: all 0.3s ease;
  position: relative;
  min-height: 350px; /* 确保表单容器有足够的高度 */
}

.login-info {
  flex: 1.2;
  background-color: #2196f3;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px;
  position: relative;
  overflow: hidden;
  height: 600px; /* 固定高度 */
  box-sizing: border-box;
}

.info-content {
  position: relative;
  z-index: 2;
}

.info-title {
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 16px;
}

.info-text {
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 32px;
  opacity: 0.9;
}

.info-actions {
  display: flex;
  gap: 16px;
}

.info-image {
  position: absolute;
  bottom: -10%;
  right: -10%;
  width: 60%;
  opacity: 0.15;
  z-index: 1;
}

.info-image img {
  width: 100%;
}

/* 添加淡入淡出动画 */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: translateY(10px);
}

@media (max-width: 768px) {
  .login-container {
    flex-direction: column;
    min-height: auto;
    max-height: 100vh;
  }
  
  .login-card, .login-info {
    height: auto;
    min-height: 0;
    padding: 24px;
  }
  
  .login-info {
    padding: 24px;
  }
  
  .info-image {
    display: none;
  }
}

/* 添加全局样式防止滚动条 */
:deep(html), :deep(body) {
  margin: 0;
  padding: 0;
  overflow: hidden;
  height: 100%;
  width: 100%;
}
</style>
