<template>
  <div class="collapsible-search-pane" :class="{ 'expanded': isExpanded, [size]: true }">
    <!-- 内容区域 -->
    <div class="search-form-content" ref="content">
      <div class="form-items" :class="{ 'expanded': isExpanded }" ref="formItems">
        <slot></slot>
      </div>
      
      <div class="action-buttons">
        <el-button 
          type="primary" 
          @click="search"
          class="search-button"
        >
          <i-search />
          <span class="button-text">{{ searchButtonText }}</span>
        </el-button>
        
        <el-button 
          v-if="showReset" 
          @click="reset"
          class="reset-button"
        >
          <i-refresh />
          <span class="button-text">{{ resetButtonText }}</span>
        </el-button>
      </div>
    </div>
    
    <!-- 展开/折叠按钮 -->
    <div 
      v-if="hasOverflow || (isMobile && formItemCount > 1)"
      class="toggle-button-container"
      @click="toggleExpand"
    >
      <i-expand-down-one v-if="!isExpanded" theme="outline" size="16" fill="var(--el-color-primary)" />
      <i-fold-up-one v-else theme="outline" size="16" fill="var(--el-color-primary)" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'CollapsiblePaneSearch',
  props: {
    searchButtonText: {
      type: String,
      default: '查询'
    },
    resetButtonText: {
      type: String,
      default: '重置'
    },
    showReset: {
      type: Boolean,
      default: true
    },
    size: {
      type: String,
      default: 'medium',
      validator: (value) => ['mini', 'small', 'medium', 'large'].includes(value)
    }
  },
  data() {
    return {
      isExpanded: false,
      hasOverflow: false,
      isMobile: false,
      formItemCount: 0
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.checkMediaQuery();
      this.checkOverflow();
      this.countFormItems();
      
      // 监听窗口大小变化
      window.addEventListener('resize', this.handleResize);
    });
  },
  beforeUnmount() {
    // 移除事件监听
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.checkMediaQuery();
      this.checkOverflow();
    },
    checkMediaQuery() {
      this.isMobile = window.innerWidth <= 768;
    },
    countFormItems() {
      // 计算实际的表单项数量
      const formItems = this.$refs.formItems;
      if (formItems) {
        this.formItemCount = formItems.children.length;
      }
    },
    checkOverflow() {
      const formItems = this.$refs.formItems;
      if (!formItems) return;
      
      // 获取最大高度（根据尺寸变化）
      let maxHeight;
      if (this.size === 'mini') {
        maxHeight = 72;
      } else if (this.size === 'small') {
        maxHeight = 84;
      } else if (this.size === 'large') {
        maxHeight = 108;
      } else {
        maxHeight = 96; // medium
      }
      
      // 临时移除高度限制以获取实际高度
      const originalMaxHeight = formItems.style.maxHeight;
      formItems.style.maxHeight = 'none';
      const scrollHeight = formItems.scrollHeight;
      formItems.style.maxHeight = originalMaxHeight;
      
      // 判断内容是否超出两行
      this.hasOverflow = scrollHeight > maxHeight;
      
      // 同时更新表单项计数
      this.countFormItems();
    },
    toggleExpand() {
      this.isExpanded = !this.isExpanded;
    },
    search() {
      this.$emit('search');
    },
    reset() {
      this.$emit('reset');
    }
  }
}
</script>

<style scoped>
.collapsible-search-pane {
  position: relative;
  border-radius: 12px;
  margin: 0;
  background-color: var(--el-bg-color);
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
  /* transition: all 0.3s ease; */
  overflow: visible;
  padding: 0;
  border: 1px solid var(--el-border-color-light);
}

.collapsible-search-pane:hover {
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.12);
}

/* 大小变体 */
.collapsible-search-pane.mini {
  border-radius: 8px;
}

.collapsible-search-pane.small {
  border-radius: 10px;
}

.collapsible-search-pane.large {
  border-radius: 14px;
}

.search-form-content {
  display: flex;
  justify-content: space-between;
  padding: 10px 10px 8px;
  align-items: flex-start;
}

/* 大小变体的内容区域样式 */
.mini .search-form-content {
  padding: 12px 12px 10px;
}

.small .search-form-content {
  padding: 16px 16px 12px;
}

.large .search-form-content {
  padding: 24px 24px 20px;
}

.form-items {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  gap: 16px;
  min-width: 0;
  /* transition: max-height 0.25s cubic-bezier(0.4, 0, 0.2, 1); */
  overflow: hidden;
  max-height: 96px; /* 默认限制最多显示两行 */
  position: relative; /* 添加定位 */
}



.form-items.expanded {
  max-height: 1000px; /* 足够大的高度以显示所有内容 */
}

.form-items.expanded::after {
  display: none;
}

/* 大小变体的表单项目样式 */
.mini .form-items {
  max-height: 72px;
  gap: 8px;
}

.small .form-items {
  max-height: 84px;
  gap: 12px;
}

.large .form-items {
  max-height: 108px;
  gap: 20px;
}

.mini .form-items.expanded,
.small .form-items.expanded,
.large .form-items.expanded {
  max-height: 1000px;
}

@media (min-width: 769px) {
  .form-items > :deep(*) {
    width: 220px;
  }
  
  .mini .form-items > :deep(*) {
    width: 180px;
  }
  
  .small .form-items > :deep(*) {
    width: 200px;
  }
  
  .large .form-items > :deep(*) {
    width: 240px;
  }
}

.action-buttons {
  display: flex;
  gap: 12px;
  margin-left: 16px;
  flex-shrink: 0;
  align-items: flex-start; /* 确保按钮顶部对齐 */
}

/* 大小变体的按钮区域样式 */
.mini .action-buttons {
  gap: 8px;
}

.small .action-buttons {
  gap: 10px;
  margin-left: 12px;
}

.large .action-buttons {
  gap: 14px;
  margin-left: 20px;
}

@media (min-width: 769px) and (max-width: 1024px) {
  /* 中等屏幕上按钮上下排列 */
  .action-buttons {
    flex-direction: column;
  }
  
  /* 确保上下排列时按钮等宽 */
  .action-buttons .el-button {
    width: 100%;
  }
}

/* 按钮样式 - 与输入框高度一致 */
.search-button,
.reset-button {
  height: 32px;
  padding: 0 16px;
  display: inline-flex; /* 改为inline-flex */
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  font-weight: 500;
  /* transition: all 0.2s ease; */
  margin: 0; /* 重置margin */
  line-height: 1; /* 统一行高 */
}

/* 大小变体的搜索和重置按钮样式 */
.mini .search-button,
.mini .reset-button {
  height: 24px;
  padding: 0 12px;
  border-radius: 4px;
  font-size: 12px;
}

.small .search-button,
.small .reset-button {
  height: 28px;
  padding: 0 14px;
  border-radius: 5px;
  font-size: 13px;
}

.large .search-button,
.large .reset-button {
  height: 36px;
  padding: 0 18px;
  border-radius: 7px;
  font-size: 15px;
}

/* 图标和文字的间距 */
.search-button i,
.reset-button i {
  margin-right: 4px;
  font-size: 16px;
  line-height: 1;
}

.mini .search-button i,
.mini .reset-button i {
  font-size: 14px;
  margin-right: 3px;
}

.small .search-button i,
.small .reset-button i {
  font-size: 15px;
  margin-right: 3px;
}

.large .search-button i,
.large .reset-button i {
  font-size: 18px;
  margin-right: 5px;
}

.button-text {
  line-height: 1;
  display: inline-block;
  vertical-align: middle;
}

.search-button:hover,
.reset-button:hover {
  transform: translateY(-1px);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

/* 重新定位的展开/折叠按钮容器 */
.toggle-button-container {
  position: absolute;
  right: 16px;
  bottom: -14px;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: var(--el-bg-color);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 8px var(--el-border-color);
  z-index: 20;
  /* transition: all 0.2s ease; */
  border: 1px solid var(--el-border-color-lighter);
  will-change: transform;
}

/* 大小变体的按钮容器样式 */
.mini .toggle-button-container {
  width: 22px;
  height: 22px;
  right: 12px;
  bottom: -11px;
}

.small .toggle-button-container {
  width: 24px;
  height: 24px;
  right: 14px;
  bottom: -12px;
}

.large .toggle-button-container {
  width: 32px;
  height: 32px;
  right: 18px;
  bottom: -16px;
}

.toggle-button-container:hover {
  transform: scale(1.1);
  background-color: var(--el-color-primary-light-9);
}

/* 响应式样式 */
@media (max-width: 768px) {
  .collapsible-search-pane {
    border-radius: 10px;
  }
  
  .search-form-content {
    padding: 16px;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
  }
  
  .form-items {
    width: 100%;
    gap: 12px;
    margin-bottom: 0;
    max-height: 40px;
    overflow: hidden;
  }
  
  .form-items.expanded {
    flex-direction: column;
    max-height: 1000px;
    margin-bottom: 12px;
  }
  
  .form-items > :deep(*) {
    width: 100% !important; /* 移动端全宽 */
  }
  
  /* 只显示第一个表单项（未展开时） */
  .form-items:not(.expanded) > :deep(*:not(:first-child)) {
    display: none;
  }
  
  .action-buttons {
    width: auto;
    margin-left: 10px;
    gap: 8px;
    flex-direction: row; /* 在移动端始终保持水平排列 */
    align-items: center; /* 确保按钮居中对齐 */
    flex-shrink: 0;
  }
  
  .search-button,
  .reset-button {
    width: 36px;
    height: 36px; /* 移动端稍微大一点便于点击 */
    padding: 0;
    border-radius: 50%;
  }
  
  .button-text {
    display: none;
  }
  
  .search-button i,
  .reset-button i {
    margin-right: 0;
    font-size: 16px;
  }
  
  /* 恢复展开/折叠按钮的显示 */
  .toggle-button-container {
    display: flex;
    right: 50%;
    transform: translateX(50%);
    will-change: transform;
    bottom: -12px;
  }
  
  .toggle-button-container:hover {
    transform: translateX(50%) scale(1.1);
    background-color: var(--el-color-primary-light-9);
  }
  
  /* 展开状态下调整内容区域 */
  .expanded .search-form-content {
    flex-direction: column;
    align-items: stretch;
  }
  
  .expanded .action-buttons {
    margin-left: 0;
    margin-top: 12px;
  }
}

@media (max-width: 480px) {
  .search-button,
  .reset-button {
    width: 32px;
    height: 32px;
  }
  
  .search-form-content {
    padding: 12px;
  }
}
</style> 