<!--
经销商及学校单位管理
最后编辑人：郑志强 -> Claude AI
最后编辑时间：2024-8-15 -> 2024-9-10
最后编辑细节：优化界面布局，支持响应式设计和深色模式
-->
<template>
  <div class="unit-container" :class="{ 'dark-theme': isDarkMode }" ref="container">
 

    <!-- 主内容区域 -->
    <div class="right-container">
      <div class="right-content">
        <!-- 搜索过滤区域 -->
        <collapsible-pane-search
          @search="toSearch"
          @reset="resetSearch"
          size="mini"
        >
          <el-input
            size="small"
            placeholder="单位名称"
            prefix-icon="Office-Building"
            v-model="search.unit_name"
            clearable
          ></el-input>
          
          <el-select 
            v-model="search.unit_type" 
            placeholder="单位类型" 
            clearable
            size="small"
          >
            <el-option
              v-for="item in unitTypes"
              :key="item.code"
              :label="item.code_name"
              :value="item.code"
            ></el-option>
          </el-select>
        </collapsible-pane-search>
        
        <!-- 操作按钮组 -->
        <div class="operation-card">
          <el-button 
            type="primary" 
            size="default" 
            @click="toAdd" 
            class="action-button add-button"
          >
            <i-plus theme="outline" size="18" />
            <span>新增单位</span>
          </el-button>
          
      
        </div>
        
        <!-- 数据表格 -->
        <div class="table-scroll-container">
          <el-table 
            :data="tableData" 
            stripe
            border
            height="100%"
          >
            <el-table-column prop="unit_id" label="单位ID" min-width="80"></el-table-column>
            <el-table-column prop="unit_code" label="单位编码" min-width="100"></el-table-column>
            <el-table-column prop="unit_name" label="单位名称" min-width="160"></el-table-column>
            <el-table-column prop="short_code" label="简写代码" min-width="80"></el-table-column>
            <el-table-column prop="unit_type_name" label="单位类型" min-width="100"></el-table-column>
            <el-table-column prop="state" label="状态" min-width="80">
              <template #default="scope">
                <el-tag v-if="scope.row.state === '1'" type="success">启用</el-tag>
                <el-tag v-else type="danger">禁用</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="create_time" label="创建时间" min-width="160"></el-table-column>
            <el-table-column prop="last_edit_time" label="最后编辑时间" min-width="160"></el-table-column>
            <el-table-column prop="punit_name" label="所属经销商" min-width="160"></el-table-column>
            <el-table-column 
              label="操作" 
              fixed="right" 
              min-width="200"
            >
              <template #default="scope">
                <div class="table-actions">
                  <el-switch
                    v-model="scope.row.state"
                    active-color="#13ce66"
                    active-value="1"
                    inactive-value="0"
                    style="margin-right: 10px;"
                    @change="toSwitchState(scope.row)"
                  ></el-switch>
                  
                  <el-button 
                    type="primary" 
                    text 
                    @click="toEdit(scope.row)" 
                    size="small" 
                    class="table-action-button"
                  >
                    <i-edit theme="outline" size="16" />
                    <span class="action-text">编辑</span>
                  </el-button>
                  
                  <el-button 
                    type="danger" 
                    text 
                    @click="toDel(scope.row)" 
                    size="small" 
                    class="table-action-button"
                  >
                    <i-delete theme="outline" size="16" />
                    <span class="action-text">删除</span>
                  </el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        
        <!-- 分页器 -->
        <div class="pagination-container">
          <el-pagination
            @current-change="toPage"
            :current-page="search.page"
            :page-size="search.pagesize"
            layout="total, sizes, prev, pager, next, jumper"
            :page-sizes="[15, 30, 50, 100]"
            @size-change="handleSizeChange"
            :total="total"
            background
          ></el-pagination>
        </div>
      </div>
    </div>

    <!-- 单位表单对话框 -->
    <el-dialog
      :title="formTitle"
      v-model="dialogVisible"
      width="600px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      class="unit-dialog"
    >
      <el-form :model="UNITDATA" :rules="rules" ref="unitForm" label-width="120px">
        <el-form-item label="单位编码" prop="unit_code">
          <el-input v-model="UNITDATA.unit_code"></el-input>
        </el-form-item>
        <el-form-item label="单位名称" prop="unit_name">
          <el-input v-model="UNITDATA.unit_name"></el-input>
        </el-form-item>
        <el-form-item label="简写代码" prop="short_code">
          <el-input v-model="UNITDATA.short_code"></el-input>
        </el-form-item>
        <el-form-item label="单位类型" prop="unit_type">
          <el-select v-model="UNITDATA.unit_type" placeholder="请选择单位类型">
            <el-option
              v-for="item in unitTypes"
              :key="item.code"
              :label="item.code_name"
              :value="item.code"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态" prop="state">
          <el-select v-model="UNITDATA.state" placeholder="请选择状态">
            <el-option
              v-for="item in stateOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属经销商" prop="punit_id" v-show="UNITDATA.unit_type==='4'">
          <el-select v-model="UNITDATA.punit_id" placeholder="请选择所属经销商">
            <el-option
              v-for="item in dlearsList"
              :key="item.unit_id"
              :label="item.unit_name"
              :value="item.unit_id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="toSave">保 存</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { useUserStoreWithOut } from "@/store/modules/user";
import { useCodeStoreWithOut } from "@/store/modules/code";
import themeManager from "@/utils/ThemeManager";
import CollapsiblePaneSearch from "@/components/common/CollapsiblePaneSearch.vue";

export default {
  name: "ManageUnit",
  components: {
    CollapsiblePaneSearch
  },
  setup() {
    const userStore = useUserStoreWithOut();
    const codeStore = useCodeStoreWithOut();
    return { userStore, codeStore };
  },
  data() {
    return {
      isDarkMode: themeManager.isDarkMode(),
      isTreeCollapsed: false,
      themeUnsubscribe: null,
      filterName: "",
      treeData: [],
      treeProps: {
        children: 'children',
        label: 'unit_name'
      },
      tableData: [],
      total: 0,
      search: {
        page: 1,
        pagesize: 15,
        unit_type: '',
        unit_name: '',
        unitid: null
      },
      formTitle: "",
      isEdit: false,
      UNITDATA: {
        unit_id: null,
        unit_code: '',
        unit_name: '',
        short_code: '',
        unit_type: '',
        state: '',
        create_time: '',
        last_edit_time: '',
        punit_id: null,
        punit_name: ''
      },
      dialogVisible: false,
      unitTypes: [],
      dlearsList: [],
      stateOptions: [
        {label: '启用', value: '1'},
        {label: '禁用', value: '0'}
      ],
      rules: {
        unit_name: [{required: true, message: '请填写单位名称', trigger: 'blur'}],
        unit_code: [{required: true, message: '请填写单位编码', trigger: 'blur'}],
        short_code: [{required: true, message: '请填写简写代码', trigger: 'blur'}],
        unit_type: [{required: true, message: '请选择单位类型', trigger: 'change'}],
        state: [{required: true, message: '请选择状态', trigger: 'change'}]
      }
    };
  },
  watch: {
    filterName(val) {
      this.$refs.unitTree?.filter(val);
    }
  },
  mounted() {
    // 使用主题管理器监听主题变化
    this.themeUnsubscribe = themeManager.onThemeChange(this.handleThemeChange);
    
    // 初始化主题状态
    this.isDarkMode = themeManager.isDarkMode();
    
    this.loadTreeData();
    this.loadData();
    this.listDlears();
    this.loadCodeByType();
  },
  beforeUnmount() {
    // 清除主题变化订阅
    if (this.themeUnsubscribe) {
      this.themeUnsubscribe();
      this.themeUnsubscribe = null;
    }
  },
  methods: {
    // 主题变化处理
    handleThemeChange(theme) {
      window.requestAnimationFrame(() => {
        document.documentElement.classList.add('disable-transitions');
        this.isDarkMode = theme === 'dark';
        setTimeout(() => {
          document.documentElement.classList.remove('disable-transitions');
        }, 100);
      });
    },
    // 加载单位树形数据
    loadTreeData() {
      this.axios.get("/api/info/user/loadtreeeduunit")
        .then((response) => {
          if (response.data.success) {
            this.treeData = response.data.result;
            if (this.treeData.length > 0) {
              this.search.unitid = null; // 初始不选中任何单位
            }
          }
        });
    },
    // 查找树节点
    filterNode(value, data) {
      if (!value) return true;
      return data.unit_name.indexOf(value) !== -1;
    },
    // 点击树节点
    clickNode(data) {
      this.search.unitid = data.unit_id;
      this.toSearch();
    },
    // 重置搜索条件
    resetSearch() {
      this.search.unit_name = "";
      this.search.unit_type = "";
      this.search.page = 1;
      this.loadData();
    },
    // 处理页面大小变化
    handleSizeChange(val) {
      this.search.pagesize = val;
      this.toSearch();
    },
    loadData() {
      this.axios.post("/api/newunit/listUnits", this.search)
        .then((response) => {
          if (response.data.success) {
            this.total = response.data.result.totalrecords;
            this.tableData = response.data.result.datalist;
          }
        });
    },
    listDlears() {
      this.axios.post("/api/newunit/listDlears", this.search)
        .then((response) => {
          if (response.data.success) {
            this.dlearsList = response.data.result;
          }
        });
    },
    loadCodeByType() {
      this.axios.post("/api/common/loadcodebytypes", {types: ['unit_type']})
        .then((response) => {
          if (response.data.success) {
            this.unitTypes = response.data.result.unit_type;
          }
        });
    },
    toPage(page) {
      this.search.page = page;
      this.loadData();
    },
    toSearch() {
      this.search.page = 1;
      this.loadData();
    },
    toAdd() {
      this.isEdit = false;
      this.formTitle = "新增单位信息";
      for (const key in this.UNITDATA) {
        this.UNITDATA[key] = '';
      }
      this.UNITDATA.state = '1'; // 默认启用
      this.dialogVisible = true;
      
      if (this.$refs.unitForm) {
        this.$refs.unitForm.clearValidate();
      }
    },
    toEdit(row) {
      this.formTitle = "编辑单位信息";
      this.isEdit = true;
      this.UNITDATA = Object.assign({}, row);
      this.dialogVisible = true;
      
      if (this.$refs.unitForm) {
        this.$refs.unitForm.clearValidate();
      }
    },
    toDel(row) {
      this.$confirm("确认删除此单位?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.UNITDATA = Object.assign({}, row);
        this.axios.post("/api/newunit/delUnit", this.UNITDATA)
          .then((response) => {
            if (response.data.success) {
              this.$message({
                message: "单位删除成功",
                type: "success"
              });
              this.loadData();
              this.listDlears();
              this.loadTreeData();
            } else {
              this.$message.error(response.data.result);
            }
          });
      });
    },
    toSwitchState(row) {
      this.UNITDATA = Object.assign({}, row);
      this.axios.post("/api/newunit/switchUnitState", this.UNITDATA)
        .then((response) => {
          if (response.data.success) {
            this.loadData();
            this.listDlears();
            this.loadTreeData();
            this.$message.success(response.data.result);
          } else {
            this.$message.error(response.data.result);
          }
        });
    },
    toSave() {
      this.$refs.unitForm.validate((valid) => {
        if (valid) {
          this.axios.post("/api/newunit/saveUnit", this.UNITDATA)
            .then((response) => {
              if (response.data.success) {
                this.$message({
                  message: "单位保存成功",
                  type: "success"
                });
                this.loadData();
                this.listDlears();
                this.loadTreeData();
                this.dialogVisible = false;
              } else {
                this.$message.error(response.data.result);
              }
            });
        }
      });
    }
  }
}
</script>

<style scoped>
/* 布局结构 */
.unit-container {
  --background-primary: var(--el-bg-color);
  --background-secondary: var(--el-fill-color-light);
  --text-primary: var(--el-text-color-primary);
  --text-secondary: var(--el-text-color-secondary);
  --border-color: var(--el-border-color);
  --shadow-color: rgba(0, 0, 0, 0.05);
  
  background-color: var(--background-primary);
  color: var(--text-primary);
  display: flex;
  height: 100%;
  position: relative;
  transition: background-color 0.3s, color 0.3s;
  overflow: hidden;
  margin-top: 0;
}

/* 左侧树形结构 */
.left-tree {
  width: 280px;
  height: 100%;
  transition: width 0.3s ease, box-shadow 0.3s ease;
  border-right: 1px solid var(--border-color);
  background-color: var(--background-primary);
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  overflow: hidden;
  box-shadow: 0 0 10px var(--shadow-color);
  position: relative;
  z-index: 5;
}

.left-tree.collapsed {
  width: 60px;
}

.tree-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid var(--border-color);
  background: linear-gradient(135deg, var(--primary-color), var(--primary-dark));
  color: white;
}

.tree-header-title {
  font-weight: 500;
  font-size: 18px;
  margin: 0;
  white-space: nowrap;
  color: white;
}

.collapse-btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  transition: all 0.3s;
  background-color: rgba(255, 255, 255, 0.1);
}

.collapse-btn:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.search-container {
  padding: 16px;
}

.tree-content {
  flex: 1;
  overflow: auto;
  padding: 12px;
  height: 0;
}

/* 右侧内容区域 */
.right-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: margin-left 0.3s;
}

.right-content {
  flex: 1;
  padding: 16px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  position: relative;
}

/* 操作按钮区域 */
.operation-card {
  background-color: var(--background-primary);
  border-radius: 12px;
  padding: 10px;
  box-shadow: 0 4px 12px var(--shadow-color);
  flex-shrink: 0;
  transition: box-shadow 0.3s ease;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-bottom: 16px;
}

.operation-card:hover {
  box-shadow: 0 6px 16px var(--shadow-color);
}

/* 表格区域 */
.table-scroll-container {
  width: 100%;
  overflow-x: hidden;
  margin-bottom: 60px;
  flex: 1;
  position: relative;
}

.table-scroll-container :deep(.el-table) {
  width: 100%;
  min-width: 650px;
}

/* 表格操作按钮 */
.table-actions {
  display: flex;
  gap: 4px;
  justify-content: center;
  align-items: center;
}

.table-action-button {
  padding: 4px 8px;
  border-radius: 4px;
  transition: all 0.2s ease;
}

.table-action-button:hover {
  background-color: var(--el-fill-color-light);
}

.table-action-button .action-text {
  margin-left: 4px;
  font-size: 12px;
}

/* 分页器 */
.pagination-container {
  position: absolute;
  bottom: 16px;
  left: 0;
  right: 0;
  background-color: var(--background-primary);
  padding: 8px 0;
  z-index: 5;
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.05);
}

/* 对话框样式 */
.unit-dialog :deep(.el-dialog) {
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
}

.unit-dialog :deep(.el-dialog__header) {
  padding: 20px 24px;
  margin: 0;
  background-color: var(--background-primary);
  border-bottom: 1px solid var(--border-color);
}

.unit-dialog :deep(.el-dialog__body) {
  padding: 24px;
}

.unit-dialog :deep(.el-dialog__footer) {
  padding: 16px 24px;
  border-top: 1px solid var(--border-color);
  background-color: var(--background-primary);
}

/* 自定义树节点 */
.custom-tree-node {
  display: flex;
  align-items: center;
  padding: 2px 0;
}

.tree-icon {
  margin-right: 8px;
  font-size: 16px;
}

.school-icon::before {
  content: '🏫';
}

.building-icon::before {
  content: '🏢';
}

/* 移动端适配 */
@media (max-width: 768px) {
  .unit-container {
    flex-direction: column;
    height: 100%;
    overflow: hidden;
  }
  
  .left-tree {
    display: none;
  }
  
  .operation-card {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
    padding: 10px;
  }
  
  .action-button {
    height: 36px;
    padding: 0 10px;
    min-width: 80px;
    width: 100%;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
  }
  
  .action-button span {
    margin-left: 4px;
    display: inline-block;
  }
  
  .right-content {
    padding: 8px;
    overflow: auto;
    position: relative;
  }
  
  .table-scroll-container {
    margin: 0 -8px 60px -8px;
    width: calc(100% + 16px);
    padding: 0 8px;
    overflow-x: auto;
  }
  
  .table-actions .action-text {
    display: none;
  }
  
  .table-actions .el-button {
    padding: 4px;
    min-width: 28px;
  }
}
</style>
