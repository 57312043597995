<!--
学生管理界面
最后编辑人：宁茂 -> Claude AI
最后编辑时间：2022-5-8 -> 2023-8-5
最后编辑细节：增加年级 -> 优化界面样式，支持响应式布局和深色模式
-->
<template>
  <div class="student-container" :class="{ 'dark-theme': isDarkMode }" ref="container">
    <!-- 左侧单位树区域 -->
    <div class="left-tree " :class="{ 'collapsed': isTreeCollapsed }" >
      <div class="tree-header">
        <h3 class="tree-header-title" v-show="!isTreeCollapsed">单位列表</h3>
        <div class="collapse-btn" @click="isTreeCollapsed = !isTreeCollapsed">
          <i-expand-left v-if="!isTreeCollapsed" theme="outline" size="24" fill="#fff"/>
          <i-expand-right v-else theme="outline" size="24" fill="#fff"/>
        </div>
      </div>
      
      <div class="search-container" v-show="!isTreeCollapsed">
        <el-input
          size="default"
          placeholder="搜索单位"
          prefix-icon="Search"
          v-model="filterName"
          clearable
        ></el-input>
      </div>
      
      <div class="tree-content">
        <el-tree
          :data="treeData"
          :props="treeProps"
          node-key="unit_id"
          default-expand-all
          :filter-node-method="filterNode"
          :expand-on-click-node="false"
          :highlight-current="true"
          @node-click="clickNode"
          ref="unitTree">
          <template #default="{ node, data }">
            <div class="custom-tree-node">
              <span class="tree-icon" :class="data.unit_type === '4' ? 'school-icon' : 'building-icon'"></span>
              <span class="tree-node-label" v-show="!isTreeCollapsed">{{ node.label }}</span>
            </div>
          </template>
        </el-tree>
      </div>
    </div>

    <!-- 移动端单位选择下拉框 -->
    <div class="mobile-unit-selector mobile-block-visi" >
      <el-select
        v-model="mobileSelectedUnit"
        placeholder="请选择单位"
        filterable
        size="small"
        @change="handleMobileUnitChange"
        style="width: 100%"
      >
        <el-option
          v-for="unit in flattenedUnits"
          :key="unit.unit_id"
          :label="unit.unit_name"
          :value="unit.unit_id"
        >
          <span class="mobile-unit-option">
            <span class="tree-icon" :class="unit.unit_type === '4' ? 'school-icon' : 'building-icon'"></span>
            <span>{{ unit.unit_name }}</span>
          </span>
        </el-option>
      </el-select>
    </div>

    <!-- 主内容区域 -->
    <div class="right-container">
      
      <!-- 主内容区域 -->
      <div class="right-content">
        <!-- 提示选择单位 -->
        <div class="select-unit-prompt" v-if="!search.unitid">
          <i-school size="64" fill="var(--text-secondary)" />
          <p>请选择一个单位开始管理学生</p>
        </div>
        
        <!-- 内容区 -->
        <template v-else>
          <!-- 搜索过滤区域 -->
          <collapsible-pane-search
            @search="toSearch"
            @reset="resetSearch"
            size="mini"
          >
              <el-input
                size="small"
                placeholder="学生姓名"
                prefix-icon="User"
                v-model="search.stuname"
                clearable
              ></el-input>
              
              <el-input
                size="small"
                placeholder="学生学号"
                prefix-icon="Ticket"
                v-model="search.stunum"
                clearable
              ></el-input>

              
              <el-select
                size="small"
                placeholder="选择年级"
                v-model="search.grade_id"
                clearable
              >
                <el-option
                  v-for="(item, index) in gradeTags"
                  :key="index"
                  :label="item.tag_name"
                  :value="item.tag_id"
                />
              </el-select>
              
              <el-select
                size="small"
                placeholder="选择班级"
                v-model="search.class_id"
                clearable
              >
                <el-option
                  v-for="(item, index) in class_list"
                  :key="index"
                  :label="item.grade + ' ' + item.class_name"
                  :value="item.class_id"
                />
              </el-select>
          </collapsible-pane-search>
        
          <!-- 操作按钮组 -->
          <div class="operation-card">
            <el-button 
              type="primary" 
              size="default" 
              @click="showAdd" 
              class="action-button add-button"
            >
              <i-plus theme="outline" size="18" />
              <span>添加学生</span>
            </el-button>
            
            
            <el-button 
              type="danger" 
              size="default"
              :disabled="selectStudents.length === 0" 
              @click="batchDelStudent()"
              class="action-button delete-button"
            >
              <i-delete theme="outline" size="18" />
              <span>批量删除</span>
            </el-button>
            

            <el-button 
              type="success" 
              size="default" 
              v-show="!(progressData.isloading)" 
              @click="showUpload"
              class="action-button import-button desktop-only-button"
            >
              <i-file-addition theme="outline" size="18" />
              <span>导入</span>
            </el-button>
            
            <el-button 
              type="info" 
              size="default" 
              @click="toDownload"
              class="action-button download-button desktop-only-button"
            >
              <i-download-two theme="outline" size="18" />
              <span>下载导入模板</span>
            </el-button>
          </div>
          
          <!-- 进度条 -->
          <div class="progress-card" v-show="progressData.isloading">
            <el-progress 
              :text-inside="true" 
              :stroke-width="20" 
              :percentage="progressData.progressPercent"
              :format="setItemText()"
              status="success"
            ></el-progress>
          </div>
          
          <!-- 数据表格 -->
          <div class="table-scroll-container">
            <el-table
              :data="tableData"
              stripe
              border
              row-key="student_number"
              @selection-change="(arr) => selectStudents = arr"
              highlight-current-row
              :row-style="{ height: '40px' }"
              style="width: 100%"
              height="100%"
            >
              <!-- 选择框 -->
              <el-table-column type="selection" width="55" fixed="left"></el-table-column>
              <el-table-column prop="unit_name" label="学校" min-width="100"></el-table-column>
              <el-table-column prop="student_name" label="姓名" min-width="100"></el-table-column>
              <el-table-column prop="student_number" label="学号" min-width="120"></el-table-column>
              <el-table-column prop="grade" label="年级" min-width="80"></el-table-column>
              <el-table-column prop="class_name" label="班级" min-width="120"></el-table-column>
              <el-table-column 
                label="操作" 
                fixed="right" 
                :width="150"
              >
                <template #default="scope">
                  <div class="table-actions">
                    <el-button type="primary" text @click="showEdit(scope.row)" size="small" class="table-action-button">
                      <i-edit theme="outline" size="16" />
                      <span class="action-text">编辑</span>
                    </el-button>
                    <el-button type="danger" text @click="toDel(scope.row)" size="small" class="table-action-button">
                      <i-delete theme="outline" size="16" />
                      <span class="action-text">删除</span>
                    </el-button>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          
          <!-- 移动端分页 -->
          <div class="pagination-container mobile-block-visi">
            <el-pagination
              @current-change="toPage"
              :current-page="search.page"
              v-model:page-size="search.pagesize"
              layout="prev, pager, next"
              :total="total"
              background
              size="small"
              pager-count="5"
            ></el-pagination>
          </div>
          <!-- 电脑端分页 -->
          <div class="pagination-container mobile-block-not-visi">
            <el-pagination
              @current-change="toPage"
              :current-page="search.page"
              v-model:page-size="search.pagesize"
              layout="total, sizes, prev, pager, next, jumper"
              :page-sizes="[15, 30, 50, 100]"
              @size-change="handleSizeChange"
              :total="total"
              background
            ></el-pagination>
          </div>
        </template>
      </div>
    </div>

    <!-- 上传文件弹窗 -->
    <el-dialog
      title="导入学生数据"
      v-model="popVisible"
      width="500px"
      :close-on-click-modal="false"
      :show-close="true"
      destroy-on-close
      class="student-dialog"
    >
      <el-upload
        action="/api/info/student/importstu"
        :data="uploadData"
        name="excelfile"
        ref="upload"
        drag
        :multiple="false"
        :before-upload="checkUpload"
        :on-success="afterSuccess"
      >
        <i-upload theme="filled" size="48" fill="var(--primary-color)" />
        <div class="el-upload__text">将Excel文件拖到此处，或<em>点击上传</em></div>
        <template #tip>
          <div class="el-upload__tip">
            只能上传xls或xlsx文件，且不超过10MB
          </div>
        </template>
      </el-upload>
    </el-dialog>

    <!-- 学生表单对话框 -->
    <el-dialog
      :title="formTitle"
      v-model="dialogVisi"
      width="500px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      class="student-dialog"
    >
      <el-form :model="stuData" :rules="rules" ref="stuForm" label-width="80px" class="student-form">
        <el-form-item label="姓名" prop="stuname">
          <el-input v-model="stuData.stuname" placeholder="请输入学生姓名"></el-input>
        </el-form-item>
        <el-form-item label="学号" prop="stunum">
          <el-input v-model="stuData.stunum" placeholder="请输入学号" ></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input 
            v-model="stuData.password" 
            placeholder="用于学生绑定账号，为空则与学号一致"
          ></el-input>
        </el-form-item>
        <el-form-item label="年级" prop="grade_id">
          <el-select 
            v-model="stuData.grade_id" 
            placeholder="请选择年级"
            clearable 
            @change="changeGrade(stuData.grade_id)"
            style="width: 100%"
          >
            <el-option
              v-for="(item, index) in gradeTags"
              :key="index"
              :label="item.tag_name"
              :value="item.tag_id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="班级" prop="class_id">
          <el-select 
            v-model="stuData.class_id" 
            placeholder="请选择班级"
            clearable
            style="width: 100%"
          >
            <el-option
              v-for="(item, index) in all_class_list"
              :key="index"
              :label="item.class_name"
              :value="item.class_id"
            />
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="closeDialog">取 消</el-button>
          <el-button type="primary" @click="toSave">保 存</el-button>
        </div>
      </template>
    </el-dialog>

    <!-- 导入错误提示对话框 -->
    <el-dialog
      title="导入数据出错"
      v-model="errorVisi"
      width="700px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      class="student-dialog"
    >
      <el-table :data="errorData" border stripe>
        <el-table-column prop="row" label="行号" width="80"></el-table-column>
        <el-table-column prop="msg" label="错误信息" min-width="500"></el-table-column>
      </el-table>
    </el-dialog>

    
    <!-- 下载链接占位 -->
    <a id="downlink" href="/api/info/student/downstuimporttemplate" v-show="false"></a>
  </div>
</template>

<script>
import { useTagStoreWithOut } from "@/store/modules/tag";
import { useAppStoreWithOut } from "@/store/modules/app";
import themeManager from "@/utils/ThemeManager";
import CollapsiblePaneSearch from "@/components/common/CollapsiblePaneSearch.vue";

// 添加防抖函数工具
function debounce(fn, delay) {
  let timer = null;
  return function() {
    const context = this;
    const args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function() {
      fn.apply(context, args);
    }, delay);
  };
}

export default {
  name: "StudentMge",
  components: {
    CollapsiblePaneSearch
  },
  setup() {
    const tagStore = useTagStoreWithOut();
    const appStore = useAppStoreWithOut();
    return { tagStore, appStore }
  },
  data() {
    return {
      isTreeCollapsed: false,
      isDarkMode: themeManager.isDarkMode(), // 使用主题管理器
      isTableLoading: false,
      showUnitTree: true, // 控制是否显示单位树
      filterName: "",
      treeData: [],
      treeProps: {
        children: 'subunit',
        label: 'unit_name'
      },
      uploadData: {
        unitid: null
      },
      errorVisi: false,
      errorData: [],
      tableTitle: "",
      total: 0,
      search: {
        page: 1,
        pagesize: 15,
        unitid: null,
        stuname: "",
        stunum: "",
        class_id: "",
        grade_id: ""
      },
      class_list: [],
      grade_list: [],
      all_class_list: [],
      alL_grade_list: [],
      tableData: [],
      unedit: true,
      dialogVisi: false,
      formTitle: "用户",
      stuData: {
        stuname: "",
        stunum: "",
        password: "",
        grade_id: "",
        class_name: "",
        class_id: "",
        unitid: null
      },
      progressData: {
        total: 0,
        num: 0,
        progressPercent: 0,
        isloading: false,
      },
      interval: null,
      themeUnsubscribe: null, // 主题观察器
      rules: {
        stuname: [{required: true, message: "请填写姓名", trigger: "blur"}, {
          max: 30,
          message: "不能超过30个字",
          trigger: "blur"
        }],
        stunum: [{required: true, message: "请填写学号", trigger: "blur"}, {
          pattern: /^\d*$/,
          message: "学号必须是数字",
          trigger: "blur"
        }],
        grade_id: [{required: true, message: "请选择年级", trigger: "change"},],
        class_id: [{required: true, message: "请选择班级", trigger: "change"},]
      },
      popVisible: false,
      gradeTags:[],
      classMgeVisi: false,
      classList:[],
      classMgeSelectGrade:null,
      classMgeInputVisible: false,
      classMgeInputValue: null,
      selectStudents: [],
      mobileSelectedUnit: null,
      flattenedUnits: []
    }
  },
  computed:{
    classMgeClassList(){
      return this.class_list.filter(item => item.grade_id === this.classMgeSelectGrade)
    }
  },
  watch: {
    filterName(val) {
      this.$refs.unitTree.filter(val)
    }
  },
  mounted() {
    this.loadTreeData();
    this.tagStore.getTag("student_tag").then((tags) => {
      this.gradeTags = tags.filter((item) => item.tag_catalog === '年级');
    });
    
    // 使用主题管理器监听主题变化
    this.themeUnsubscribe = themeManager.onThemeChange(this.handleThemeChange);
    
    // 初始化主题状态
    this.isDarkMode = themeManager.isDarkMode();
  },
  beforeUnmount() {
    // 清除主题变化订阅
    if (this.themeUnsubscribe) {
      this.themeUnsubscribe();
      this.themeUnsubscribe = null;
    }
    
    // 清除定时器
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  },
  methods: {
    // 主题变化处理
    handleThemeChange(theme) {
      // 使用请求动画帧确保在下一帧更新UI
      window.requestAnimationFrame(() => {
        // 预先禁用过渡动画，减少主题切换卡顿
        document.documentElement.classList.add('disable-transitions');
        
        // 更新组件状态
        this.isDarkMode = theme === 'dark';
        
        // 使用延时，确保DOM更新后再启用过渡动画
        setTimeout(() => {
          document.documentElement.classList.remove('disable-transitions');
        }, 100);
      });
    },
    
    // 载入学生列表 - 添加表格渲染优化
    loadStu() {
      this.isTableLoading = true;
      this.axios.post("/api/info/student/loadstu", this.search)
          .then((response) => {
            if (response.data.success) {
              this.total = response.data.result.totalrecords;
              
              // 大量数据分批渲染
              const data = response.data.result.datalist;
              this.tableData = data;
            }
          })
        .finally(() => {
          this.isTableLoading = false;
        });
    },
    
    // 处理页面大小变化
    handleSizeChange(val) {
      this.search.pagesize = val;
      this.toSearch();
    },
    
    // 显示上传对话框
    showUpload() {
      if (this.uploadData.unitid != null) {
        this.popVisible = true;
        if (this.$refs["upload"]) {
          this.$refs["upload"].clearFiles();
        }
      } else {
        this.$message({message: "请先选择单位", type: "warning"});
      }
    },
    
    // 批量删除学生
    batchDelStudent(){
      this.$confirm("确认删除选中的学生？此操作无法恢复！", "提示",  {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.isTableLoading = true;
        this.axios.post("/api/info/student/batchDelStudent", {
          stuNumbers:this.selectStudents.map(stu=> stu.student_number),
          unitid: this.search.unitid
        })
            .then((response) => {
              if (response.data.success) {
                this.$message({message: "删除成功", type: "success"})
                this.toSearch()
              }
            })
        .finally(() => {
          this.isTableLoading = false;
        });
      }).catch(()=>{});
    },

    // 保存班级
    handleInputConfirm(){
      if(this.classMgeInputValue){
        this.axios.post("/api/info/student/saveClass", {
          class_name: this.classMgeInputValue, 
          grade_id: this.classMgeSelectGrade,
          unitid: this.search.unitid
        })
            .then(async (response) => {
              if (response.data.success) {
                this.$message({message: "添加成功", type: "success"})
                this.class_list = await this.loadCla({unitid: this.search.unitid})
              }
        });
      }
      this.classMgeInputVisible = false;
      this.classMgeInputValue = null;
    },
    
    // 删除班级
    delClass(clazz){
      this.$confirm("确认删除此班级?这将删除该班级下的所有学生！", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.axios.post("/api/info/student/delClass", {class_id: clazz.class_id})
            .then(async (response) => {
              if (response.data.success) {
                this.$message({message: "删除成功", type: "success"})
                this.class_list = await this.loadCla({unitid: this.search.unitid})
              }
          });
      }).catch(()=>{});
    },
    
    //载入单位树形列表
    loadTreeData() {
      this.axios.get("/api/info/student/loadtreeeduunit")
          .then((response) => {
            if (response.data.success) {
              this.treeData = response.data.result;
              
              // 扁平化单位树，用于移动端选择器
              this.flattenedUnits = this.flattenUnitTree(this.treeData);
              
              // 检查是否只有一个单位
              if (this.flattenedUnits.length === 1) {
                // 只有一个单位，自动选择
                this.showUnitTree = false;
                this.autoSelectSingleUnit(this.flattenedUnits[0]);
              } else {
                this.showUnitTree = true;
              }
            }
        });
    },
    
    // 扁平化单位树
    flattenUnitTree(units) {
      let result = [];
      
      for (const unit of units) {
        result.push(unit);
        if (unit.subunit && unit.subunit.length > 0) {
          result = result.concat(this.flattenUnitTree(unit.subunit));
        }
      }
      
      return result;
    },
    
    // 自动选择单一单位
    autoSelectSingleUnit(unit) {
      this.tableTitle = unit.unit_name;
      this.search.unitid = unit.unit_id;
      this.uploadData.unitid = unit.unit_id;
      this.mobileSelectedUnit = unit.unit_id; // 同步移动端选择
      this.loadStu();
      this.loadCla(this.search).then(classes => {
        this.class_list = classes;
      });
    },
    
    //查找树节点
    filterNode(value, data) {
      if (!value) return true;
      return data.unit_name.indexOf(value) !== -1;
    },
    
    //点击树节点
    async clickNode(data) {
      this.tableTitle = data.unit_name;
      this.search.unitid = data.unit_id;
      this.uploadData.unitid = data.unit_id;
      this.isTableLoading = true;
      this.loadStu();
      this.class_list = await this.loadCla(this.search);
    },
    
    //载入班级列表
    async loadCla(params) {
      const response = await this.axios.post("/api/info/student/loadcla", params);
      if (response.data.success) {
        return response.data.result;
      } else {
        throw new Error('加载班级列表失败！');
      }
    },
    
    //页码跳转
    toPage(page) {
      this.search.page = page;
      this.loadStu();
    },
    
    //条件查询
    async toSearch() {
      this.search.page = 1;
      this.isTableLoading = true;
      this.loadStu();
      this.class_list = await this.loadCla(this.search);
    },
    
    //关闭对话框
    closeDialog() {
      this.$refs["stuForm"].resetFields();
      this.dialogVisi = false;
      this.stuData.stuname = "";
      this.stuData.stunum = "";
      this.stuData.grade_id = "";
      this.stuData.class_id = "";
      this.stuData.unitid = this.search.unitid;
    },
    
    //新增
    async showAdd() {
      if (this.search.unitid !== null) {
        this.dialogVisi = true;
        this.unedit = false;
        this.stuData.stuname = "";
        this.stuData.stunum = "";
        this.stuData.grade_id = "";
        this.stuData.class_id = "";
        this.stuData.unitid = this.search.unitid;
        this.formTitle = "新增学生";
        if (this.$refs["stuForm"]) {
          this.$refs["stuForm"].clearValidate();
        }
        this.all_class_list = await this.loadCla({unitid: this.search.unitid, grade_id: this.stuData.grade_id});
      } else {
        this.$message({message: "请先选择单位", type: "warning"});
      }
    },
    
    //编辑
    async showEdit(data) {
      this.dialogVisi = true;
      this.unedit = false;
      this.stuData.stuname = data.student_name;
      this.stuData.stunum = data.student_number;
      this.stuData.grade_id = data.grade_id;
      this.stuData.class_id = data.class_id;
      this.stuData.unitid = this.search.unitid;
      this.formTitle = "编辑学生";
      if (this.$refs["stuForm"]) {
        this.$refs["stuForm"].clearValidate();
      }
      this.all_class_list = await this.loadCla({unitid: this.search.unitid, grade_id: this.stuData.grade_id});
    },
    
    //年级改变
    async changeGrade(value) {
      this.all_class_list = await this.loadCla({unitid: this.search.unitid, grade_id: value});
      this.stuData.class_id = "";
    },
    
    //删除
    toDel(data) {
      this.$confirm("确认删除此学生?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.isTableLoading = true;
        this.axios.post("/api/info/student/delstu", {stunum: data.student_number})
            .then((response) => {
              if (response.data.success) {
              this.$message({message: "删除成功", type: "success"});
                if (data.student_number === this.stuData.stunum) {
                this.$refs["stuForm"].resetFields();
                this.unedit = true;
                this.stuData.stuname = "";
                this.stuData.stunum = "";
                this.stuData.grade_id = "";
                this.stuData.class_id = "";
                this.stuData.unitid = null;
                this.formTitle = "学生";
              }
              this.loadStu();
            }
          })
          .finally(() => {
            this.isTableLoading = false;
          });
      }).catch(() => {});
    },
    
    //保存
    toSave() {
      this.$refs["stuForm"].validate((valid) => {
        if (valid) {
          this.axios.post("/api/info/student/savestu", this.stuData)
              .then((response) => {
                if (response.data.success) {
                this.$message({message: "保存成功", type: "success"});
                this.loadStu();
                this.closeDialog();
              }
            });
        } else {
          return false;
        }
      });
    },
    
    //关闭上传框
    closeUpload() {
      this.popVisible = false;
    },
    
    //上传前检查
    checkUpload(file) {
      this.getProgress();
      clearInterval(this.interval);
      this.interval = setInterval(this.getProgress, 2000);
      
      if (this.uploadData.unitid == null) {
        this.$message({message: "请先选择单位", type: "warning"});
        return false;
      }
      
      let name = file.name.toLowerCase();
      if (!name.endsWith(".xls") && !name.endsWith(".xlsx")) {
        this.$message({message: "只能上传xls或xlsx文件", type: "warning"});
        return false;
      }
      
      return true;
    },
    
    //上传后处理
    afterSuccess(data) {
      if (this.$refs["upload"]) {
        this.$refs["upload"].clearFiles();
      }
      
      if (data.success) {
        this.$message({message: "导入成功", type: "success"});
        this.loadStu();
        this.popVisible = false;
      }
    },
    
    //下载模板
    toDownload() {
      document.getElementById("downlink").click();
    },

    //获取进度
    getProgress() {
      const that = this;
      that.axios.get("/api/info/student/getprogress")
          .then((response) => {
            if (response.data.success) {
            that.progressData.total = response.data.result.TOTAL;
            that.progressData.num = response.data.result.NUM;
              that.progressData.progressPercent = ((response.data.result.NUM / response.data.result.TOTAL) * 100) | 0;
            
              if (that.progressData.total !== 0) {
              that.progressData.isloading = true;
              }
            
              if (response.data.result.ISEXCEPTIONAL) {
              this.$alert("导入出错,请重试！");
              clearInterval(this.interval);
              this.interval = null;
              that.progressData.isloading = false;
            }
            
              if (response.data.result.ISEND) {
              that.progressData.isloading = false;
                clearInterval(this.interval);
                this.interval = null;
              }
            
              if (that.progressData.num === that.progressData.total) {
              that.progressData.isloading = false;
                clearInterval(this.interval);
                this.interval = null;
              this.toSearch();
              }
            } else {
              that.$alert(response.data.result, "进度条数据获取错误");
              clearInterval(this.interval);
            }
        });
    },

    setItemText() {
      return () => {
        return '上传中：' + this.progressData.progressPercent + '%';
      }
    },

    handleMobileUnitChange() {
      const selectedUnit = this.flattenedUnits.find(unit => unit.unit_id === this.mobileSelectedUnit);
      if (selectedUnit) {
        this.tableTitle = selectedUnit.unit_name;
        this.search.unitid = selectedUnit.unit_id;
        this.uploadData.unitid = selectedUnit.unit_id;
        this.loadStu();
        this.loadCla(this.search).then(classes => {
          this.class_list = classes;
        });
      }
    },

    // 重置搜索条件
    resetSearch() {
      this.search.stuname = "";
      this.search.stunum = "";
      this.search.grade_id = "";
      this.search.class_id = "";
      this.search.page = 1;
      this.loadStu();
    },
  }
}
</script>

<style scoped>
/* 更新配色方案 - 使用CSS变量简化主题切换 */
:root {
  --primary-color: #3a7afe;
  --primary-light: #ecf2ff; 
  --primary-dark: #2c5cc5;
  --secondary-color: #64748b;
  --danger-color: #dc2626;
  --danger-light: #fee2e2;
  --success-color: #22c55e;
  --success-light: #dcfce7;
  --warning-color: #f59e0b;
  --warning-light: #fef3c7;
}

.student-container {
  --background-primary: var(--el-bg-color);
  --background-secondary: var(--el-fill-color-light);
  --text-primary: var(--el-text-color-primary);
  --text-secondary: var(--el-text-color-secondary);
  --border-color: var(--el-border-color);
  --shadow-color: rgba(0, 0, 0, 0.05);
  
  background-color: var(--background-primary);
  color: var(--text-primary);
  display: flex;
  height: 100%; /* 使用100%高度，适配router-view容器 */
  position: relative;
  transition: background-color 0.3s, color 0.3s; /* 仅为主题变化添加过渡效果 */
  overflow: hidden; /* 防止滚动条出现 */
  margin-top: 0; /* 移除上边距 */
}

/* 暗色模式配色 - 最小化属性变化 */
.dark-theme {
  --primary-light: rgba(58, 122, 254, 0.15);
  --danger-light: rgba(220, 38, 38, 0.15);
  --success-light: rgba(34, 197, 94, 0.15);
  --warning-light: rgba(245, 158, 11, 0.15);
  --shadow-color: rgba(0, 0, 0, 0.2);
}

/* 布局结构 */
.left-tree {
  width: 280px;
  height: 100%;
  transition: width 0.3s ease, box-shadow 0.3s ease;
  border-right: 1px solid var(--border-color);
  background-color: var(--background-primary);
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  overflow: hidden; /* 防止树容器产生滚动条 */
  box-shadow: 0 0 10px var(--shadow-color);
  position: relative;
  z-index: 5;
}

.left-tree.collapsed {
  width: 60px;
}

.tree-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid var(--border-color);
  background: linear-gradient(135deg, var(--primary-color), var(--primary-dark));
  color: white;
}

.tree-header-title {
  font-weight: 500;
  font-size: 18px;
  margin: 0;
  white-space: nowrap;
  color: white;
}

.collapse-btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  transition: all 0.3s;
  background-color: rgba(255, 255, 255, 0.1);
}

.collapse-btn:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.search-container {
  padding: 16px;
}

.tree-content {
  flex: 1;
  overflow: auto;
  padding: 12px;
  height: 0; /* 确保flex布局下正确计算高度 */
}

.custom-tree-node {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px 4px;
  cursor: pointer;
  border-radius: 6px;
  transition: background-color 0.2s;
}

.tree-icon {
  margin-right: 10px;
  font-style: normal;
  flex-shrink: 0;
  font-size: 18px;
}

.school-icon::before {
  content: '🏫';
}

.building-icon::before {
  content: '🏢';
}


.right-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: margin-left 0.3s;
}

.right-header {
  padding: 0 24px 16px 24px;
  background-color: var(--background-primary);
  border-bottom: 1px solid var(--border-color);
  flex-shrink: 0; /* 防止压缩 */
}

.current-unit-title {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
}

.right-content {
  flex: 1;
  padding: 16px;
  overflow: auto; /* 使用auto允许内容区域滚动 */
  display: flex;
  flex-direction: column;
  position: relative; /* 为分页器定位 */
}

.select-unit-prompt {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--text-secondary);
}

.select-unit-prompt p {
  margin-top: 16px;
  font-size: 16px;
}

/* 卡片样式 */
.operation-card, 
.progress-card {
  background-color: var(--background-primary);
  border-radius: 12px;
  padding: 10px;
  box-shadow: 0 4px 12px var(--shadow-color);
  flex-shrink: 0; /* 防止这些区域被压缩 */
  transition: box-shadow 0.3s ease;
}

.operation-card {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.operation-card:hover {
  box-shadow: 0 6px 16px var(--shadow-color);
}


.table-card {
  background-color: var(--background-primary);
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
  box-shadow: 0 2px 8px var(--shadow-color);
  overflow: auto; /* 修改为auto，允许表格区域滚动 */
  width: 100%;
}

/* 表格内容区域滚动 */
:deep(.el-table__body-wrapper) {
  overflow-x: auto !important;
  overflow-y: auto !important;
}

/* 固定列样式 */
:deep(.el-table) .el-table__fixed-right {
  height: 100% !important; /* 确保固定列高度正确 */
  box-shadow: -3px 0 10px rgba(0, 0, 0, 0.12);
}

:deep(.el-table) .el-table__fixed-left {
  height: 100% !important;
  box-shadow: 3px 0 10px rgba(0, 0, 0, 0.12);
}

:deep(.el-table) .el-table__fixed-right::before,
:deep(.el-table) .el-table__fixed-left::before {
  content: '';
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.table-actions {
  display: flex;
  gap: 4px;
  justify-content: center;
}

.table-action-button {
  padding: 4px 8px;
  border-radius: 4px;
  transition: all 0.2s ease;
}

.table-action-button:hover {
  background-color: var(--el-fill-color-light);
}

.table-action-button .action-text {
  margin-left: 4px;
  font-size: 12px;
}

/* 表格容器 */
.table-scroll-container {
  width: 100%;
  overflow-x: auto;
  margin-bottom: 60px; /* 为分页器留出空间 */
  -webkit-overflow-scrolling: touch; /* 增强iOS滚动体验 */
}

.table-scroll-container :deep(.el-table) {
  width: 100%;
  min-width: 650px; /* 确保表格在移动端也有最小宽度 */
}

.table-scroll-container :deep(.el-table__body-wrapper) {
  overflow-x: auto;
  overflow-y: auto;
}

/* 分页容器样式 */
.pagination-container {
  position: absolute;
  bottom: 16px;
  left: 0;
  right: 0;
  background-color: var(--background-primary);
  padding: 8px 0;
  z-index: 5;
  display: flex;
  justify-content: center;
  flex-shrink: 0; /* 防止压缩 */
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.05);
}

/* 班级管理样式 */
.class-management {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.grade-selector {
  width: 200px;
}

.class-tag-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 16px;
  background-color: var(--background-secondary);
  border-radius: 8px;
  min-height: 120px;
}

.class-tag {
  margin: 0;
}

.tag-input {
  width: 120px;
}

/* 对话框样式 */
.student-dialog :deep(.el-dialog) {
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
}

.student-dialog :deep(.el-dialog__header) {
  padding: 20px 24px;
  margin: 0;
  background-color: var(--background-primary);
  border-bottom: 1px solid var(--border-color);
}

.student-dialog :deep(.el-dialog__body) {
  padding: 24px;
}

.student-dialog :deep(.el-dialog__footer) {
  padding: 16px 24px;
  border-top: 1px solid var(--border-color);
  background-color: var(--background-primary);
}

.student-form .el-form-item {
  margin-bottom: 20px;
}


.mobile-block-visi{
  display: none;
}
.mobile-block-not-visi{
  display: block;
}


/* 移动端样式 - 使用媒体查询代替JS检测 */
@media (max-width: 768px) {
  .mobile-block-not-visi{
    display: none;
  }
  .mobile-block-visi{
    display: block;
  }
  .student-container {
    flex-direction: column;
    height: 100%;
    overflow: hidden;
  }
  
  /* 移动端单位选择器样式 */
  .mobile-unit-selector {
    display: block;
  }
  
  /* 隐藏单位树 */
  .left-tree {
    display: none;
  }
  
  /* 隐藏桌面端按钮 */
  .desktop-only-button {
    display: none !important;
  }
  
  /* 修复操作卡片和按钮 */
  .operation-card {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
    padding: 10px;
  }
  
  .progress-card {
    padding: 12px;
    margin-bottom: 12px;
    box-shadow: 0 1px 3px var(--shadow-color);
  }
  
  .action-button {
    height: 36px;
    padding: 0 10px;
    min-width: 80px;
    width: 100%;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
  }
  
  .action-button span {
    margin-left: 4px;
    display: inline-block;
  }
  
  .action-button :deep(svg) {
    width: 16px !important;
    height: 16px !important;
    flex-shrink: 0;
  }
  
  /* 表格区域移动端调整 */
  .table-scroll-container {
    margin: 0 -8px 60px -8px; /* 负边距拉伸容器并为分页器预留更多空间 */
    width: calc(100% + 16px);
    padding: 0 8px;
    overflow-x: auto;
  }
  
  .pagination-container {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 8px 0;
  }

  .right-content {
    padding: 8px; /* 减小内边距 */
    overflow: auto; /* 确保内容可滚动 */
    position: relative;
  }
}
</style>

