<template>
  <div class="account-login-container">
    <custom-input
      v-model:modelValue="loginForm.username"
      icon="fas fa-user"
      placeholder="请输入手机号或账号"
      :has-error="errors.username !== ''"
      :error-message="errors.username"
      @enter="doLogin"
      autocomplete="new-username"
    />
    
    <custom-input
      v-model:modelValue="loginForm.password"
      type="password"
      icon="fas fa-lock"
      placeholder="请输入密码"
      :has-error="errors.password !== ''"
      :error-message="errors.password"
      @enter="doLogin"
      autocomplete="new-password"
    />
    
    <div class="remember-me-container">
      <el-checkbox v-model="loginForm.rememberMe">记住我</el-checkbox>
    </div>
    
    <custom-button 
      class="login-button" 
      @click="doLogin"
    >
      登录
    </custom-button>
  </div>
</template>

<script>
import CustomInput from './CustomInput.vue'
import CustomButton from './CustomButton.vue'

export default {
  name: 'AccountLoginForm',
  components: {
    CustomInput,
    CustomButton
  },
  data() {
    return {
      loginForm: {
        username: '',
        password: '',
        rememberMe: false
      },
      errors: {
        username: '',
        password: ''
      }
    }
  },
  created() {
    // 检查本地存储中是否有保存的凭据
    this.checkSavedCredentials();
  },
  methods: {
    validate() {
      let isValid = true
      
      // Reset errors
      this.errors = {
        username: '',
        password: ''
      }
      
      if (!this.loginForm.username.trim()) {
        this.errors.username = '请输入用户名'
        isValid = false
      }
      
      if (!this.loginForm.password.trim()) {
        this.errors.password = '请输入密码'
        isValid = false
      }
      
      return isValid
    },
    doLogin() {
      if (this.validate()) {
        // Format data to match API expectations
        const loginData = {
          ua: this.loginForm.username.trim(),
          up: this.loginForm.password.trim(),
          rememberMe: this.loginForm.rememberMe
        }
        
        // 如果用户选择了"记住我"，则保存凭据
        if (this.loginForm.rememberMe) {
          this.saveCredentials(this.loginForm.username, this.loginForm.password);
        } else {
          // 如果未选择"记住我"，则清除保存的凭据
          this.clearSavedCredentials();
        }
        
        this.$emit('login', loginData)
      }
    },
    // 安全地保存凭据到localStorage
    saveCredentials(username, password) {
      try {
        // 简单加密，实际生产环境应使用更强的加密
        const encryptedData = btoa(JSON.stringify({
          username: username,
          password: password,
          timestamp: new Date().getTime()
        }));
        localStorage.setItem('rememberedCredentials', encryptedData);
      } catch (error) {
        console.error('保存凭据时出错：', error);
      }
    },
    // 检查是否有保存的凭据
    checkSavedCredentials() {
      try {
        const savedData = localStorage.getItem('rememberedCredentials');
        if (savedData) {
          const decryptedData = JSON.parse(atob(savedData));
          
          // 检查凭据是否过期（例如30天）
          const now = new Date().getTime();
          const savedTime = decryptedData.timestamp || 0;
          const thirtyDaysInMs = 30 * 24 * 60 * 60 * 1000;
          
          if (now - savedTime < thirtyDaysInMs) {
            this.loginForm.username = decryptedData.username || '';
            this.loginForm.password = decryptedData.password || '';
            this.loginForm.rememberMe = true;
          } else {
            // 凭据已过期，清除它们
            this.clearSavedCredentials();
          }
        }
      } catch (error) {
        console.error('读取凭据时出错：', error);
        this.clearSavedCredentials();
      }
    },
    // 清除保存的凭据
    clearSavedCredentials() {
      localStorage.removeItem('rememberedCredentials');
    }
  }
}
</script>

<style scoped>
.account-login-container {
  width: 100%;
  max-width: 380px;
}

.form-title {
  font-size: 24px;
  color: #333;
  margin-bottom: 32px;
  text-align: center;
}

.remember-me-container {
  margin: 16px 0;
  display: flex;
  align-items: center;
}

.login-button {
  width: 100%;
  margin-top: 16px;
}

.form-footer {
  margin-top: 24px;
  text-align: right;
}

.forgot-password {
  color: #2196f3;
  text-decoration: none;
  font-size: 14px;
  transition: color 0.3s;
}

.forgot-password:hover {
  color: #1976d2;
  text-decoration: underline;
}
</style> 