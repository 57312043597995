<template>
  <el-container class="base-container">
    <el-container class="main-container" ref="mainSectorSubject" id="mainSectorSubject">
       <el-main ref="mainscroll" id="mainscroll"
               style="position:relative;overflow: auto;padding: 10px 0 0 0;">

         <div class="zoom-control">
           <!-- <span class="zoom-value" v-show="zoom !== -1">{{ zoom }}%</span> -->
           <el-button
               type="primary"
               size="small"
               circle
               @click="canvasZoom('zoomin')">
             <el-icon><Plus /></el-icon>
           </el-button>
           <el-button
               type="primary"
               size="small"
               circle
               @click="canvasZoom('zoomout')">
             <el-icon><Minus /></el-icon>
           </el-button>
         </div>


        <canvas ref="canvas" style="width: 100%;height: 100%;"></canvas>

      </el-main>
      <el-aside class="config-aside" style="width: 400px;">
        <el-tabs type="border-card" v-model="activeTab" class="full-height-tabs" style="height: 100%;border: none;">
          <el-tab-pane label="试卷图片" name="paperTab" class="scrollable-tab-pane">
            <el-upload
                ref="paperUpload"
                action="/api/exam/mgeexamsubjectpapers/uploadexamsubjectpaper"
                name="imgfile"
                list-type="text"
                accept="image/png,image/jpeg,image/gif"
                :show-file-list="false"
                :data="search"
                :file-list="uploadFileList"
                :on-success="uploadResult"
                :on-error="uploadError">
              <!-- 添加扫描按钮 -->
              <el-button size="default" type="primary"  @click.stop="toScannerView">
                <template #icon>
                  <i-page-template theme="outline" size="16"/>
                </template>
                开始扫描</el-button>
              <el-button size="default" type="primary" icon="Plus">手动上传</el-button>
            </el-upload>
            <el-divider></el-divider>
            <el-tree
                :data="subjectPapers"
                :props="paperTreeProps"
                node-key="paper_id"
                default-expand-all

                draggable
                @node-drop="paperDrop"
                :expand-on-click-node="false"
                ref="paperTree">
              <template v-slot="{ node, data }">
                <div class="paper-row">
                      <span>
                    <i class="picture-outline"></i>
                    {{ node.label }}
                  </span>
                  <span style="padding-left:10px">
                    <el-tooltip content="删除" placement="top" effect="light" transition="" :enterable="false">
                      <el-button type="danger" class="tree-button" icon="delete"
                                 @click="() => toPaperDel(data)"></el-button>
                    </el-tooltip>
                  </span>
                </div>

              </template>
            </el-tree>
          </el-tab-pane>
          <el-tab-pane label="试卷配置" name="propTab" class="scrollable-tab-pane">
            <el-form :model="propData" :rules="propRule" ref="subForm" size="default" label-width="100px">
              <el-form-item label="试卷名称" prop="name">
                <el-input v-model="propData.name" style="width: 210px;"></el-input>
              </el-form-item>
              <el-form-item label="试卷总分" prop="total_score">
                <el-input v-model="propData.total_score" style="width: 210px;" readonly></el-input>
              </el-form-item>
              <el-form-item label="信息遮盖区域">
                <el-button size="default" :type="maskAreaAddButton" round icon="Plus"
                           @click="toAreaAdd('mask-area-add')"
                           :disabled="subjectPapers.length === 0">区域
                </el-button>
                <el-tree
                    :data="propData.maskarea"
                    default-expand-all
                    empty-text=""
                    ref="maskAreaTree">
                  <template v-slot="
/* eslint-disable vue/no-unused-vars */ { node, data }">
                    <div class="paper-row">
                         <span :class="{'area-select': activeArea===data}" @click="() => selectArea(data)">
                        {{ data.p + "-(" + data.x + "," + data.y + "):(" + data.w + "," + data.h + ")" }}
                      </span>
                      <span style="padding-left:5px">
                        <el-tooltip content="删除遮盖区域" placement="top" effect="light" transition=""
                                    :enterable="false">
                          <el-button type="danger" class="tree-button" icon="delete"
                                     @click="() => removeMaskArea(data)"></el-button>
                        </el-tooltip>
                      </span>
                    </div>

                  </template>
                </el-tree>
              </el-form-item>

              <el-form-item label="科目识别区域">
                <el-button size="default" :type="recognizeAreaAddButton" round icon="Plus"
                           @click="toAreaAdd('recognize-area-add')"
                           :disabled="subjectPapers.length === 0">区域
                </el-button>
                <el-tree
                    :data="propData.recognizearea"
                    default-expand-all
                    empty-text=""
                    ref="recognizearea">
                  <template v-slot="
/* eslint-disable vue/no-unused-vars */ { node, data }">
                    <div class="paper-row">
                         <span :class="{'area-select': activeArea===data}" @click="() => selectArea(data)">
                        {{ data.p + "-(" + data.x + "," + data.y + "):(" + data.w + "," + data.h + ")" }}
                      </span>
                      <span style="padding-left:5px">
                        <el-tooltip content="删除科目识别区域" placement="top" effect="light" transition=""
                                    :enterable="false">
                          <el-button type="danger" class="tree-button" icon="delete"
                                     @click="() => removeRecognizeArea(data)"></el-button>
                        </el-tooltip>
                      </span>
                    </div>

                  </template>
                </el-tree>
              </el-form-item>


              <el-form-item label="缺考标志区域">
                <el-button size="default" :type="absentAreaAddButton" round icon="Plus"
                           @click="toAreaAdd('absent-area-add')"
                           :disabled="subjectPapers.length === 0">区域
                </el-button>
                <el-tree
                    :data="propData.absentarea"
                    default-expand-all
                    empty-text=""
                    ref="recognizeAreaTree">
                  <template v-slot="
/* eslint-disable vue/no-unused-vars */ { node, data }">
                    <div class="paper-row">
                         <span :class="{'area-select': activeArea===data}" @click="() => selectArea(data)">
                        {{ data.p + "-(" + data.x + "," + data.y + "):(" + data.w + "," + data.h + ")" }}
                      </span>
                      <span style="padding-left:5px">
                        <el-tooltip content="删除遮盖区域" placement="top" effect="light" transition=""
                                    :enterable="false">
                          <el-button type="danger" class="tree-button" icon="delete"
                                     @click="() => removeAbsentArea(data)"></el-button>
                        </el-tooltip>
                      </span>
                    </div>

                  </template>
                </el-tree>
              </el-form-item>

              <el-form-item label="选做题标志区域">
                <el-button size="default" :type="multiChoiceAreaAddButton" round icon="Plus"
                           @click="toAreaAdd('multi-choice-area-add')"
                           :disabled="subjectPapers.length === 0">区域
                </el-button>
                <el-tree
                    :data="propData.multi_choice_area"
                    default-expand-all
                    empty-text=""
                    ref="multiChoiceAreaTree">
                  <template v-slot="
/* eslint-disable vue/no-unused-vars */ { node, data }">
                    <div class="paper-row">
                         <span :class="{'area-select': activeArea===data}" @click="() => selectArea(data)">
                        {{ data.p + "-(" + data.x + "," + data.y + "):(" + data.w + "," + data.h + ")" }}
                        <el-tooltip content="选项" placement="top" effect="light" transition=""
                                    :enterable="false">
                          <el-text type="primary" style="font-size: 12px;">{{ data.option }}</el-text>
                        </el-tooltip>
                      </span>
                      <span style="padding-left:5px">
                        <el-tooltip content="删除遮盖区域" placement="top" effect="light" transition=""
                                    :enterable="false">
                          <el-button type="danger" class="tree-button" icon="delete"
                                     @click="() =>  removeMultiChoiceArea(data)"></el-button>
                        </el-tooltip>
                      </span>
                    </div>

                  </template>
                </el-tree>
              </el-form-item>

              <el-form-item label="缺考覆盖率" v-if="propData.absentarea.length!==0">
                <el-input-number :min="0" :max="100" v-model="propData.coverage"
                                 style="width: 100px;"></el-input-number>
              </el-form-item>
              <el-form-item label="缺考未覆盖率" v-if="propData.absentarea.length!==0">
                <el-input-number :min="0" :max="100" v-model="propData.uncoverage"
                                 style="width: 100px;"></el-input-number>
              </el-form-item>
              <el-form-item label="缺考选项类型" v-if="propData.absentarea.length!==0">
                <el-select v-model="propData.absent_omrfigure">
                  <el-option
                      v-for="item in [
                    {label: '圆', value: '1'},
                    {label: '方', value: '2'},
                    {label: '开方', value: '3'},
                ]"
                      :key="item"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="缺考识别色深" v-if="propData.absentarea.length!==0">
                <el-input-number :min="0" :max="100" v-model="propData.depth"
                                 style="width: 100px;"></el-input-number>
              </el-form-item>
              <el-form-item label="考号识别方式">
                <el-radio-group v-model="propData.codeareatype">
                  <el-radio label="1">条形码</el-radio>
                  <el-radio label="2">ORM码</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="填写方向" v-show="propData.codeareatype==='2'">
                <el-radio-group v-model="propData.codeareadirection">
                  <el-radio label="1">横向</el-radio>
                  <el-radio label="2">纵向</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="识别区域">
                <el-button size="default" :type="codeAreaAddButton" round icon="Plus"
                           @click="toAreaAdd('code-area-add')"
                           :disabled="subjectPapers.length === 0">区域
                </el-button>
                <el-tree
                    :data="propData.codearea"
                    default-expand-all
                    empty-text=""
                    ref="maskAreaTree">
                  <template v-slot="{ node, data }">
                    <div class="paper-row">  <span :class="{'area-select': activeArea===data}"
                                                   @click="() => selectArea(data)">
                        {{ data.p + "-(" + data.x + "," + data.y + "):(" + data.w + "," + data.h + ")" }}
                      </span>
                      <span style="padding-left:5px">
                        <el-tooltip content="删除条码区域" placement="top" effect="light" transition=""
                                    :enterable="false">
                          <el-button type="danger" class="tree-button" icon="delete"
                                     @click="() => removeCodeArea(data)"></el-button>
                        </el-tooltip>
                      </span></div>

                  </template>
                </el-tree>
              </el-form-item>

              <el-form-item label="欧码选项类型" v-if="propData.codeareatype==='2'">
                <el-select v-model="propData.orm_omrfigure">
                  <el-option
                      v-for="item in [
                    {label: '圆', value: '1'},
                    {label: '方', value: '2'},
                    {label: '开方', value: '3'},
                ]"
                      :key="item"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="欧码覆盖率" v-if="propData.codeareatype==='2'">
                <el-input-number :min="0" :max="100" v-model="propData.orm_coverage"
                                 style="width: 100px;"></el-input-number>
              </el-form-item>
              <el-form-item label="欧码未覆盖率" v-if="propData.codeareatype==='2'">
                <el-input-number :min="0" :max="100" v-model="propData.orm_uncoverage"
                                 style="width: 100px;"></el-input-number>
              </el-form-item>

              <el-form-item label="" v-show="propData.codeareatype==='2'">
                <el-button size="default" type="primary" round icon="Plus" @click="toOrmAreaBatchAdd">ORM区域识别
                </el-button>
              </el-form-item>
              <el-form-item label="ORM坐标识别区域" v-show="propData.codeareatype==='2'">
                <el-button type="danger" icon="delete" @click="deleteOrmArea">删除ORM坐标区域</el-button>
                <!--                <el-tree-->
                <!--                    :data="propData.ormarea"-->
                <!--                    default-expand-all-->
                <!--                    ref="maskAreaTree">-->
                <!--                  <template v-slot="{ node, data }">-->
                <!--                    <div class="paper-row">  <span :class="{'area-select': activeArea===data}"-->
                <!--                                                   @click="() => selectArea(data)">-->
                <!--                        {{ data.p + "-(" + data.x + "," + data.y + "):(" + data.w + "," + data.h + ")" }}-->
                <!--                      </span>-->
                <!--                    </div>-->
                <!--                  </template>-->
                <!--                </el-tree>-->
              </el-form-item>

              <el-form-item label="考号长度">
                <el-input v-model.number="propData.scan_prop.student_id_length" @change="(id)=>{
                  propData.scan_prop.cut_end = id.toString().length - 2
                }">
                </el-input>
              </el-form-item>
              <el-form-item label="分区范围">
                <el-input-number v-model="propData.scan_prop.cut_start" :min="0"
                                 :max="propData.scan_prop.student_id_length" :controls="false" size="small"
                                 style="width: 50px"/>
                -
                <el-input-number v-model="propData.scan_prop.cut_end" :min="0"
                                 :max="propData.scan_prop.student_id_length" :controls="false" size="small"
                                 style="width: 50px"/>
              </el-form-item>

              <el-form-item>
                <template #label>
                  <el-tooltip
                      content="仅对联考生效，混合阅卷即所有老师可以拿到任意一个学校的试卷，独立阅卷即本校老师提本校的试卷"
                      placement="left-start"
                  >
                    <i-help theme="multi-color" size="16" :fill="['#409eff' ,'#a0cfff' ,'#086bfc' ,'#c6e2ff']"
                            style="margin-top: 8px"/>
                  </el-tooltip>
                  阅卷模式
                </template>
                <el-radio-group v-model="propData.markingType">
                  <el-radio label="1">混合阅卷</el-radio>
                  <el-radio label="2">独立阅卷</el-radio>
                </el-radio-group>
              </el-form-item>
              <!-- 新增严格模式配置-->
              <el-form-item>
                <template #label>
                  <el-tooltip
                      placement="left-start"
                  >
                    <template #content>
                      严格模式下: <br>
                      1、考场扫描的座位号在扫描时必须从小至大依次递增，否则无法提交 <br>
                      2、抽查成品卷时无法直接修改得分<br>
                      3、学生试题管理时无法直接修改得分<br>
                      4、阅卷时不会显示该题组的平均分、最高分、最低分<br>
                      5、阅卷时1评无法无限回评
                    </template>
                    <i-help theme="multi-color" size="16" :fill="['#409eff' ,'#a0cfff' ,'#086bfc' ,'#c6e2ff']"
                            style="margin-top: 8px"/>
                  </el-tooltip>
                  严格模式
                </template>
                <el-radio-group v-model="propData.strict_mode">
                  <el-radio label="1">是</el-radio>
                  <el-radio label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>

              <!--              <el-form-item label="识别方式">-->
              <!--                <el-radio-group v-model="propData.scan_prop.recognize_type">-->
              <!--                  <el-radio label="1">服务端识别</el-radio>-->
              <!--                  <el-radio label="2">扫描端识别</el-radio>-->
              <!--                </el-radio-group>-->
              <!--              </el-form-item>-->
              <el-form-item label="模板类型">
                <el-radio-group v-model="propData.scan_prop.template_type">
                  <el-radio label="1">标准</el-radio>
                  <el-radio label="2">非标</el-radio>
                </el-radio-group>
              </el-form-item>


              <el-form-item label="扫描类型">
                <el-radio-group v-model="propData.scan_prop.scan_type">
                  <el-radio label="1">按考场扫描</el-radio>
                  <el-radio label="2">按学校扫描</el-radio>
                  <el-radio label="3">全局扫描</el-radio>
                </el-radio-group>
              </el-form-item>

              <el-form-item label="扫描分辨率">
                <el-radio-group v-model="propData.scan_prop.scan_dpi">
                  <el-radio label="100">100dpi</el-radio>
                  <el-radio label="150">150dpi</el-radio>
                  <el-radio label="200">200dpi</el-radio>
                </el-radio-group>
              </el-form-item>

              <el-form-item label="扫描方式">
                <el-radio-group v-model="propData.scan_prop.scan_way">
                  <el-radio label="1">单面</el-radio>
                  <el-radio label="2">双面</el-radio>
                </el-radio-group>
              </el-form-item>

              <el-form-item label="扫描颜色">
                <el-radio-group v-model="propData.scan_prop.scan_color">
                  <el-radio label="1">黑白</el-radio>
                  <el-radio label="2">灰度</el-radio>
                  <el-radio label="3">真彩</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="扫描纸张大小">
                <el-radio-group v-model="propData.scan_prop.scan_paper_size">
                  <el-radio label="1">A4</el-radio>
                  <el-radio label="2">A3</el-radio>
                  <el-radio label="3">匹配</el-radio>
                </el-radio-group>
              </el-form-item>

              <el-form-item label="是否有子科目">
                <el-radio-group v-model="propData.has_sub">
                  <el-radio label="1">是</el-radio>
                  <el-radio label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>

              <el-form-item label="子科目配置" v-show="propData.has_sub === '1'">
                <el-button type="primary" @click="openSubSubjectDialog">配置子科目</el-button>
              </el-form-item>

              <!-- 新增子科目配置对话框 -->
              <el-dialog 
                v-model="subSubjectDialogVisible" 
                title="子科目配置" 
                width="600px"
              >
                <div class="sub-subject-config">
                  <div class="sub-subject-header">
                    <el-button 
                      type="primary" 
                      icon="Plus" 
                      @click="addSubSubject"
                    >
                      新增子科目
                    </el-button>
                  </div>

                  <el-table 
                    :data="propData.sub_prop" 
                    style="width: 100%"
                    max-height="400px"
                  >
                    <el-table-column label="子科目名称" prop="name">
                      <template #default="{ row, $index }">
                        <el-input 
                          v-model="row.name" 
                          placeholder="请输入子科目名称"
                        />
                      </template>
                    </el-table-column>
                    
                    <el-table-column label="题组选择" prop="questionIds">
                      <template #default="{ row }">
                        <el-select
                          v-model="row.questionIds"
                          multiple
                          placeholder="请选择题组"
                          style="width: 100%"
                        >
                          <el-option
                            v-for="question in questionList"
                            :key="question.question_id"
                            :label="question.question_name"
                            :value="question.question_id"
                          />
                        </el-select>
                      </template>
                    </el-table-column>

                    <el-table-column label="操作" width="100">
                      <template #default="{ $index }">
                        <el-button 
                          type="danger" 
                          icon="Delete" 
                          @click="removeSubSubject($index)"
                        >
                          删除
                        </el-button>
                      </template>
                    </el-table-column>
                  </el-table>

                  <div class="dialog-footer">
                    <el-button @click="subSubjectDialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="saveSubSubjects">保 存</el-button>
                  </div>
                </div>
              </el-dialog>

              <!-- <el-form-item label="子科目配置" v-show="propData.has_sub === '1'">
                <template #label>
                  <div style="margin-top: 20px">子科目配置</div>
                </template>
                <div style="margin-top: 20px">
                  <div>
                    <el-select v-model="propData.sub_prop_select" style="width: 150px;margin-right: 5px"
                               placeholder="请新增一个科目">
                      <el-option v-for="(item,index) in propData.sub_prop" :key="item.index" :label="item.name"
                                 :value="index"/>
                    </el-select>
                    <el-button siz="default" type="primary" icon="Plus" style="width: 10%" @click="() => {
                  propData.sub_prop.push({name:'子科目'+(propData.sub_prop.length+1)})
                  if(propData.sub_prop.length === 1){
                    propData.sub_prop_select = 0
                  }
                }"></el-button>
                  </div>

                  <div v-if="propData.sub_prop_select !== null">
                    <el-input v-model="propData.sub_prop[propData.sub_prop_select].name" style="width: 100%;">
                      <template #prefix>子科目名称：</template>
                    </el-input>

                    <el-select
                        v-model="propData.sub_prop[propData.sub_prop_select].questionIds"
                        multiple
                        placeholder="请选择题组"
                        style="width: 100%"
                    >
                      <el-option
                          v-for="(question,index) in questionList"
                          :key="question.question_id"
                          :label="question.question_name"
                          :value="question.question_id">
                      </el-option>
                    </el-select>
                    <el-button type="danger" icon="Delete" style="width: 100%" @click="() =>{
                      propData.sub_prop.splice(propData.sub_prop_select,1)
                      if(propData.sub_prop_select === 0){
                        propData.sub_prop_select = null
                      }else {
                        propData.sub_prop_select = propData.sub_prop_select - 1
                      }
                    }">移除此子科目
                    </el-button>
                  </div>

                </div>
              </el-form-item> -->
              <div style="text-align: center;">
                <el-button type="primary" @click="toPropSave">保 存</el-button>
              </div>
            </el-form>

          </el-tab-pane>
          <el-tab-pane label="题组配置" name="questionTab" class="scrollable-tab-pane">
            <div style="display: flex; justify-content: space-between; align-items: center; margin-bottom: 10px;">
              <el-button size="default" type="primary" icon="Plus"
                         @click="toQuestionAdd"
                         :disabled="this.subjectPapers.length === 0">
                题组
              </el-button>

              <div class="expand-mode-select">
                <span class="expand-mode-label">展开方式:</span>
                <el-select v-model="expandMode"
                           size="small"
                           style="width: 90px"
                           @change="handleExpandModeChange">
                  <el-option label="仅主观题" value="1">
                    <template #default="{ label }">
                      <i-doc-detail theme="outline" size="12" fill="#333"/>
                      <span style="margin-left: 8px">仅主观题</span>
                    </template>
                  </el-option>
                  <el-option label="所有" value="2">
                    <template #default="{ label }">
                      <i-full-screen-two theme="outline" size="12" fill="#333"/>
                      <span style="margin-left: 8px">所有</span>
                    </template>
                  </el-option>
                  <el-option label="所有不" value="3">
                    <template #default="{ label }">
                      <i-click-to-fold theme="outline" size="12" fill="#333"/>
                      <span style="margin-left: 8px">所有不</span>
                    </template>
                  </el-option>
                </el-select>
              </div>
            </div>

            <el-tree
                :data="questionList"
                :props="questionTreeProps"
                node-key="uid"
                draggable
                @node-drop="questionDrop"
                :allowDrop="questionAllowDrop"
                :expand-on-click-node="false"
                :default-expanded-keys="expandedKeys"
                v-if="treeExist"
                ref="quesTree">
              <template v-slot="{ node, data }">
                <div v-if="data.level3Flag">
                  {{ data.name }}
                  <span style="color: green"> 【{{ data.min }} - {{ data.max }}】</span>
                </div>
                <div class="paper-row" v-else>
                  <span @click="() => toQuestionEdit(data)">
                   

                    <!-- 题目名称 -->
                    <span v-if="typeof data.subquestion_name === 'undefined'">
                      {{ data.question_name }}
                    </span>
                    <span v-else>{{
                        data.content != null && data.content.is_multi ? data.subquestion_name + "(多选)" : data.subquestion_name
                      }}
                    </span>
                    <span>题</span>

                    <el-icon>
                      <document/>
                    </el-icon>


                    <!-- 满分 -->
                    <span
                        style="font-weight: bold;margin-right: 3px"
                        :style="{color: data.content.full_score == 0 ? 'red' : 'var(--el-color-success-light-3)'}"
                    >
                          {{ data.content.full_score }}
                    </span>

                    <span>分</span>

                    
                    <el-tooltip content="区域缺失" placement="top" effect="light" transition="" :enterable="false"
                                v-if="(typeof data.subquestion_name === 'undefined' && data.question_area.length === 0) || (typeof data.subquestion_name !== 'undefined' && data.subquestion_area.length === 0)">
                    </el-tooltip>

                    </span>
                  <span style="padding-left:10px">
                      <el-tooltip content="新增子题" placement="top" effect="light" transition="" :enterable="false"
                                  v-if="typeof data.subquestion_name === 'undefined' && data.question_type === '2'">
                        <el-button type="primary" size="small" class="tree-button" icon="Plus"
                                   @click="() => toSubquestionAdd(data)"></el-button>
                      </el-tooltip>
                      <el-tooltip content="删除" placement="top" effect="light" transition="" :enterable="false">
                        <el-button type="danger" size="small" class="tree-button" icon="delete"
                                   @click="() => toQuestionDel(data)"></el-button>
                      </el-tooltip>
                    </span>
                </div>
              </template>
            </el-tree>
          </el-tab-pane>
        </el-tabs>

      </el-aside>

    </el-container>

    <el-drawer
        style="position: fixed"
        ref="quesDrawer"
        :title="quesTitle"
        :append-to-body="true"
        direction="rtl"
        v-model="quesDrawerVisi"
        :size="360"
        :modal="false"
        modal-class="drawer"
        @close="drawerClose"
        @opened="() => $refs.quesForm.clearValidate()">
      <el-form :model="quesData" :rules="quesRule" ref="quesForm" size="small" label-width="100px">
        <el-form-item label="名称" prop="name">
          <el-input v-model="quesData.name" style="width: 210px;"></el-input>
        </el-form-item>
        <el-form-item label="类型" prop="type">
          <el-radio-group v-model="quesData.type">
            <el-radio label="1">客观题</el-radio>
            <el-radio label="2">主观题</el-radio>
          </el-radio-group>
        </el-form-item>


        <el-form-item label="视图类型" prop="type" v-if="quesData.type === '2'">
          <el-radio-group v-model="quesData.content.viewType">
            <el-radio label="1">普通视图</el-radio>
            <el-radio label="2">作文视图</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="区域">
          <el-button size="default" :type="quesAreaAddButton" round icon="Plus" @click="toQuestionAreaAdd">区域
          </el-button>
          <span class="el-upload__tip" style="margin-left: 10px;"
                v-if="optMode === 'ques-area-add'">请框选新区域</span>
          <el-tree
              :data="quesData.area"
              default-expand-all
              empty-text=""
              draggable
              :allowDrop="areaAllowDrop"
              @node-drop="areaDrop"
              ref="quesAreaTree">
            <template v-slot="{ node, data }">
                  <span :class="{'area-select': activeArea===data}" @click="() => selectArea(data)">
                    {{ data.p + "-(" + data.x + "," + data.y + "):(" + data.w + "," + data.h + ")" }}
                  </span>
              <span style="padding-left:5px">
                    <el-tooltip content="删除题组区域" placement="top" effect="light" transition="" :enterable="false">
                      <el-button type="danger" class="tree-button" icon="delete"
                                 @click="() => removeQuestionArea(data)"></el-button>
                    </el-tooltip>
                  </span>
            </template>
          </el-tree>
        </el-form-item>
        <el-form-item label="" v-if="quesData.type === '1' && quesData.area.length !== 0">
          <el-button size="default" type="primary" round icon="Plus" @click="toSubquestionBatchAdd">批量添加子题
          </el-button>
        </el-form-item>

        <el-form-item label="识别类型" v-if="quesData.type === '1'">
          <el-select v-model="quesData.content.marktype">
            <el-option
                v-for="item in [
                    {label: '真假', value: '0'},
                    {label: 'ABCDEFG', value: '1'},
                    {label: '0123456', value: '2'},
                ]"
                :key="item"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选项类型" v-if="quesData.type === '1'">
          <el-select v-model="quesData.content.omrfigure">
            <el-option
                v-for="item in [
                    {label: '圆', value: '1'},
                    {label: '方', value: '2'},
                    {label: '开方', value: '3'},
                ]"
                :key="item"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="评卷模式" v-if="quesData.type === '2'">
          <el-select v-model="quesData.content.mark_mode" placeholder="请选择">
            <el-option
                v-for="item in markModeList"
                :key="item"
                :label="item.label"
                :value="item">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="限制时间" v-if="quesData.type === '2'">
          <el-input v-model.number="quesData.content.limit_time" style="width: 100px;"></el-input>
        </el-form-item>
        <el-form-item label="满分">
          <el-input-number
              :min="0"
              v-model="quesData.content.full_score"
              style="width: 100px;"
              @change="generateValidScore"
              @input="handleInputChange">
          </el-input-number>
        </el-form-item>
        <el-form-item 
                      v-if="quesData.type === '2' && (quesData.content.mark_mode.value === '3' || quesData.content.mark_mode.value === '4' || quesData.content.mark_mode.value === '5')">
                      <template #label>
                        <el-text type="danger" >
                          误差分数
                        </el-text>
                      </template>
          <el-input-number :min="0" v-model="quesData.content.score_diff" style="width: 100px;"></el-input-number>
        </el-form-item>
        <el-form-item label="分数间隔" v-if="quesData.type === '2'">
          <el-input-number :min="0" v-model="quesData.content.score_interval"
                           style="width: 100px;"></el-input-number>
        </el-form-item>
        <el-form-item label="有效分数" prop="validScoreStr" v-if="quesData.type === '2'">
          <el-select
              v-model="quesData.validScoreStr"
              multiple
              filterable
              allow-create
              default-first-option
              :reserve-keyword="false"
              placeholder="请输入分数"
              style="width: 240px"
              collapse-tags
              collapse-tags-tooltip
              :max-collapse-tags="20"
          >
          </el-select>
        </el-form-item>
        <el-form-item label="快捷键" v-if="quesData.type === '2'">
          <el-popover
              placement="left"
              width="290"
              trigger="click"
          >
            <div style="max-height: 400px; overflow-y: auto;text-align: center;">
              <el-row type="flex" v-for="(item, idx) in quesData.content.shortcuts" :key="idx"
                      style="align-items: baseline; margin-bottom: 5px;">
                <el-col :span="5" style="text-align: right; padding-right: 8px;">快捷键</el-col>
                <el-col :span="4">
                  <el-input v-model="item.key" @blur="() => {
                    if(item.key.length === 1 && item.key.match(/[a-zA-Z]/) === null){
                      item.key = ''
                      $message.warning('请输入a-z的单个字母!')
                    }else if(item.key.length > 1){
                      item.key = ''
                      $message.warning('请输入a-z的单个字母!')
                    }
                  }"></el-input>
                </el-col>
                <el-col :span="4" style="text-align: right; padding-right: 8px;">分值</el-col>
                <el-col :span="8">
                  <el-input-number controls-position="right" :min="0" :max="quesData.content.full_score"
                                   v-model="item.score" style="width: 80px;"></el-input-number>
                </el-col>
                <el-col :span="3" style="padding-left: 8px;">
                  <el-button type="danger" class="tree-button" icon="delete"
                             @click="removeShortcut(idx)"></el-button>
                </el-col>
              </el-row>
              <el-button size="default" type="primary" round icon="Plus" @click="addShortcut">添加快捷键
              </el-button>
            </div>
            <template #reference>
              <el-button size="default" type="primary" round icon="edit">编辑快捷键</el-button>

            </template>

          </el-popover>
        </el-form-item>
        <el-form-item label="自评?" v-if="quesData.type === '2'">
          <el-radio-group v-model="quesData.content.remark_flag" @change="val => {
            if(val){
              quesData.content.remark_interval = 10
            }else {
              quesData.content.remark_interval = null
            }
          }">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="自评卷分差" v-if="quesData.content.remark_flag">
          <el-input-number v-model="quesData.content.self_score_diff"/>
        </el-form-item>

        <el-form-item label="标准卷分差" v-if="quesData.type === '2'">
          <el-input-number v-model="quesData.content.standard_score_diff"/>
        </el-form-item>

        <el-form-item label="自评间隔" v-if="quesData.type === '2' && quesData.content.remark_flag">
          <el-input-number :min="10" v-model="quesData.content.remark_interval"
                           style="width: 100px;"></el-input-number>
          份
        </el-form-item>
        <el-form-item label="选做题?" v-if="quesData.type === '2'">
          <el-radio-group v-model="quesData.content.choose_flag">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>

        <!-- 添加选做题区域按钮 -->
        <el-form-item label="选做题区域" v-if="quesData.type === '2' && quesData.content.choose_flag">
          <el-select v-model="quesData.content.multi_choices_area" multiple placeholder="请选择关联的标记" @change="(multi_choices_area)=>{
            if(!quesData.content.multi_choices) quesData.content.multi_choices = []

            multi_choices_area.forEach(item => {
              let index = quesData.content.multi_choices.filter(choice => choice.option === item)
              if(index.length === 0) {
                quesData.content.multi_choices.push({
                  option: item,
                  name: item
                })
              }
            })
            // 再删除quesData.content.multi_choices中不存在的选项
            quesData.content.multi_choices = quesData.content.multi_choices.filter(choice => multi_choices_area.includes(choice.option))


            // 如果quesData.content.multi_choices没有默认的，则设置第一个为默认
            if(quesData.content.multi_choices.length > 0 && !quesData.content.multi_choices.find(choice => choice.is_default)) {
              quesData.content.multi_choices[0].is_default = true
            }


          }">
            <el-option v-for="item in propData.multi_choice_area" :key="item.option" :label="item.option" :value="item.option"></el-option>
          </el-select>
          <div v-for="item in quesData.content.multi_choices" :key="item.option">
            <el-text type="primary" style="margin-right: 10px;">{{ item.option }}</el-text>
            <el-input v-model="item.name" style="width: 100px;"></el-input>

            <el-tag v-if="item.is_default" type="success">默认</el-tag>
            <!-- 设为默认的按钮 -->
            <el-button type="primary" @click="()=>{
              quesData.content.multi_choices.forEach(choice => {
                choice.is_default = false
              })
              item.is_default = true
            }"
            v-show="!item.is_default"
            >设为默认</el-button>
          </div>
        </el-form-item>

        <el-form-item v-if="quesData.type === '1'">
          <template #label>
            <el-tooltip
                content="推荐：25"
                placement="top-start"
            >
              <i-help theme="multi-color" size="16" :fill="['#409eff' ,'#a0cfff' ,'#086bfc' ,'#c6e2ff']"/>
            </el-tooltip>
            单选覆盖率
          </template>
          <el-input-number :min="0" :max="100" v-model="quesData.content.coverage"
                           style="width: 100px;"></el-input-number>
        </el-form-item>
        <el-form-item v-if="quesData.type === '1'">
          <template #label>
            <el-tooltip
                content="推荐：55"
                placement="top-start"
            >
              <i-help theme="multi-color" size="16" :fill="['#409eff' ,'#a0cfff' ,'#086bfc' ,'#c6e2ff']"/>
            </el-tooltip>
            多选覆盖率
          </template>
          <el-input-number :min="0" :max="100" v-model="quesData.content.coverage2"
                           style="width: 100px;"></el-input-number>
        </el-form-item>

        <el-form-item v-if="quesData.type === '1'">
          <template #label>
            <el-tooltip
                content="推荐：9"
                placement="top-start"
            >
              <i-help theme="multi-color" size="16" :fill="['#409eff' ,'#a0cfff' ,'#086bfc' ,'#c6e2ff']"/>
            </el-tooltip>
            单选未覆盖率
          </template>
          <el-input-number :min="0" :max="100" v-model="quesData.content.uncoverage"
                           style="width: 100px;"></el-input-number>
        </el-form-item>
        <el-form-item v-if="quesData.type === '1'">
          <template #label>
            <el-tooltip
                content="推荐：15"
                placement="top-start"
            >
              <i-help theme="multi-color" size="16" :fill="['#409eff' ,'#a0cfff' ,'#086bfc' ,'#c6e2ff']"/>
            </el-tooltip>
            多选未覆盖率
          </template>
          <el-input-number :min="0" :max="100" v-model="quesData.content.uncoverage2"
                           style="width: 100px;"></el-input-number>
        </el-form-item>
        <el-form-item label="识别色深" v-if="quesData.type === '1'">
          <el-input-number :min="0" :max="100" v-model="quesData.content.depth"
                           style="width: 100px;"></el-input-number>
        </el-form-item>
      </el-form>
      <div style="text-align: center;">
        <el-button @click="$refs.quesDrawer.handleClose()">取 消</el-button>
        <el-button type="primary" @click="toQuestionSave">保 存</el-button>
      </div>
    </el-drawer>
    <el-drawer
        style="position: fixed"
        ref="subDrawer"
        :title="subTitle"
        :append-to-body="true"
        direction="rtl"
        v-model="subDrawerVisi"
        :size="380"
        :modal="false"
        modal-class="drawer"
        @close="drawerClose"
        @opened="() => $refs.subForm.clearValidate()">
      <el-form :model="subData" :rules="subRule" ref="subForm" size="default" label-width="80px">

        <el-form-item label="名称" prop="name">
          <el-input v-model="subData.name" style="width: 210px;"></el-input>
        </el-form-item>
        <el-form-item label="区域" v-show="subData.viewType != '2'">
          <el-button size="default" :type="subAreaAddButton" :disabled="this.subData.area.length > 0" round icon="Plus"
                     @click="toSubquestionAreaAdd"
                     v-if="subData.type === '2'">区域
          </el-button>
          <span class="el-upload__tip" style="margin-left: 10px;"
                v-if="optMode === 'sub-area-add'">请在题组区域内框选新区域</span>
          <el-tree
              :data="subData.area"
              default-expand-all
              empty-text=""
              draggable
              :allowDrop="areaAllowDrop"
              @node-drop="areaDrop"
              ref="subquesAreaTree">
            <template v-slot="{ node, data }">
              <div class="paper-row"> <span :class="{'area-select': activeArea===data}"
                                            @click="() => selectArea(data)">
                    {{ data.p + "-(" + data.x + "," + data.y + "):(" + data.w + "," + data.h + ")" }}
                  </span>
                <span style="padding-left:5px">
                    <el-tooltip content="删除子题区域" placement="top" effect="light" transition="" :enterable="false"
                                v-if="subData.type === '2'">
                      <el-button type="danger" class="tree-button" icon="delete"
                                 @click="() => removeSubquestionArea(data)"></el-button>
                    </el-tooltip>
                  </span></div>

            </template>
          </el-tree>
        </el-form-item>
        <el-form-item label="满分" prop="content.full_score">
          <el-input-number :min="0"
                           v-model="subData.content.full_score"
                           style="width: 100px;"
                           :disabled="subData.type == 1"
          ></el-input-number>
          <el-text v-if="subData.type == 1" type="info">请在客观题答案配置页面修改</el-text>
        </el-form-item>

        <el-form-item label="项" v-show="subData.viewType == '2'" prop="subData.content.list">
          <el-select v-model="subQuestionContentListSelect" style="width: 100px;margin-right: 5px">
            <el-option v-for="(item,index) in subData.content.list" :key="item.index" :label="item.name"
                       :value="index"/>
          </el-select>
          <el-button :type="pointAddButton" icon="Plus" @click="addNewPoint">
            新增项
          </el-button>
        </el-form-item>

        <el-form-item label="项名称" prop="content.start_score"
                      v-if="subQuestionContentListSelect !== null && subData.viewType == '2'">
          <el-input v-model="subData.content.list[subQuestionContentListSelect].name"></el-input>
        </el-form-item>
        <el-form-item label="起始分数" prop="content.start_score"
                      v-if="subQuestionContentListSelect !== null && subData.viewType == '2'">
          <el-input-number :min="0" v-model="subData.content.list[subQuestionContentListSelect].min"></el-input-number>
        </el-form-item>
        <el-form-item label="结束分数" prop="content.end_score"
                      v-if="subQuestionContentListSelect !== null && subData.viewType == '2'">
          <el-input-number :min="0" v-model="subData.content.list[subQuestionContentListSelect].max"></el-input-number>
        </el-form-item>
        <el-form-item label="分数间隔" prop="content.interval"
                      v-if="subQuestionContentListSelect !== null && subData.viewType == '2'">
          <el-input-number :min="0" v-model="subData.content.list[subQuestionContentListSelect].interval"
                           style="width: 100px;"></el-input-number>
        </el-form-item>

        <el-form-item label="打分点" v-show="subData.type === '2' && subData.viewType != '2'">
          <el-button size="default" :type="pointAddButton"
                     round
                     icon="Plus"
                     @click="toSubquestionPointAdd">
            打分点
          </el-button>
          <span class="el-upload__tip" style="margin-left: 10px;"
                v-if="optMode === 'sub-point-add'">请在子题区域内点击添加打分点</span>
          <el-tree
              :data="subData.content.points"
              default-expand-all
              empty-text=""
              draggable
              :allowDrop="areaAllowDrop"
              @node-drop="areaDrop"
              ref="pointTree">
            <template v-slot="{ node, data }">
              <div class="paper-row"><span
                  :class="{'area-select': activePointIdx===subData.content.points.indexOf(data)}"
                  @click="() => selectArea(data)">
                    {{ data.p + "-(" + data.x + "," + data.y + ")" }}
                  </span>
                <span style="padding-left:10px">
                    <el-tooltip content="删除打分点" placement="top" effect="light" transition="" :enterable="false">
                      <el-button type="danger" class="tree-button" icon="delete"
                                 @click="() => removeSubquestionPoint(data)"></el-button>
                    </el-tooltip>
                  </span></div>

            </template>
          </el-tree>
        </el-form-item>
        <el-form-item label="多选题?" v-if="subData.type === '1'">
          <el-radio-group v-model="subData.content.is_multi">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div class="row-button-center">
        <el-button @click="$refs['subDrawer'].handleClose()">取 消</el-button>
        <el-button type="primary" @click="toSubquestionSave">保 存</el-button>
      </div>
    </el-drawer>
    <el-dialog :title="subDialogTitle" v-model="subDialogVisi" width="90%" class="v-center_dialog"
               :close-on-click-modal="false" :close-on-press-escape="false">
      <div style="display: flex;flex-direction: row;flex-wrap: nowrap;max-height: 600px; min-height: 300px;">
        <div style="width: calc(100% - 400px);min-height: 300px;overflow: auto">
          <canvas ref="optcanvas" style="width: 100%;height: 100%;"></canvas>
        </div>
        <div style="margin: 0; width: 400px; height: 100%;display: flex;flex-direction: row;flex-wrap: wrap">
          <div style="padding-left: 20px;">
            <el-button-group>
              <el-button size="default" @click="optcanvasZoom('zoomin')">
                <el-icon><Plus /></el-icon>
                放大
              </el-button>
              <el-button size="default" @click="optcanvasZoom('zoomout')">
                <el-icon><Minus /></el-icon>
                缩小
              </el-button>
            </el-button-group>
          </div>
          <div style="margin-top: 10px;padding-left: 20px;">
            <el-button-group>
              <el-button size="default" :type="setModelButton" icon="refresh" @click="refindOptions">重新识别选项
              </el-button>
              <el-button size="default" :type="setPersonModelButton" icon="refresh" @click="setModel('person')">人工设置选项
              </el-button>
            </el-button-group>
          </div>
          <div style="margin-top: 10px;padding-left: 20px;" v-show="this.optRecognizeMode==='auto'">
            <el-button-group>
              <el-button size="default" :type="optColDelButton" icon="Close" @click="toOptionColRemove">移除列
              </el-button>
              <el-button size="default" :type="optRowDelButton" icon="Close" @click="toOptionRowRemove">移除行
              </el-button>
            </el-button-group>
          </div>
          <div style="margin-top: 10px;padding-left: 20px;" v-show="this.optRecognizeMode!=='auto'">
            <el-button-group>
              <el-button size="default" :type="optColSetButton" icon="Close" @click="toOptionColSet">人工设定首列
              </el-button>
              <el-button size="default" :type="optRowSetButton" icon="Close" @click="toOptionRowSet">人工设定首行
              </el-button>

            </el-button-group>
            <el-row style="margin-top: 10px;padding-left: 20px;">
              <el-col :span="12">
                选项高度：
                <el-input-number :min="1" :max="50" v-model="rectHeight" style="width: 100px;"></el-input-number>
              </el-col>
              <el-col :span="12">
                选项宽度：
                <el-input-number :min="1" :max="50" v-model="rectWidth" style="width: 100px;"></el-input-number>
              </el-col>
            </el-row>
          </div>

          <div style="margin-top: 10px;padding-left: 20px;" v-show="this.optRecognizeMode!=='auto'">
            <el-button-group>
              <el-button size="default" icon="refresh" @click="toResetColAndRow">重置行列数据
              </el-button>
              <el-button size="default" icon="Expand" @click="toGenerateOptionArea">生成选项
              </el-button>
            </el-button-group>

          </div>
          <div style="margin-top: 10px;padding-left: 20px;" v-show="subDialogMode!=='orm'">
            <el-button-group>
              <el-button size="default" :type="firstOptSwitchButton" icon="collection-tag"
                         @click="toFirstOptSwitch">设置首选项
              </el-button>
              <el-button size="default" :type="setMultiButton" icon="notebook-2" @click="toSetMultiOpt">设置多选题
              </el-button>
            </el-button-group>
          </div>

          <el-form :model="subDialogData" :rules="objRule" ref="objectiveForm" size="default" label-width="180px">
            <el-form-item label="选项<排列>排序类型" prop="direction" v-show="subDialogMode!=='orm'">
              <el-radio-group v-model="subDialogData.questionDirection">
                <el-radio label="1">横向</el-radio>
                <el-radio label="2">竖向</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="选项<题号>排序类型" prop="direction" v-show="subDialogMode!=='orm'">
              <el-radio-group v-model="subDialogData.direction">
                <el-radio label="1">横向</el-radio>
                <el-radio label="2">竖向</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="开始序号" prop="startnum" v-show="subDialogMode!=='orm'">
              <el-input-number v-model="subDialogData.startnum" :min="1" :step="2"></el-input-number>
            </el-form-item>
            <el-form-item label="识别包容宽度" prop="startnum" v-show="this.optRecognizeMode==='auto'">
              <el-input-number v-model="subDialogData.optMinBlank" :min="1" :step="1"></el-input-number>
            </el-form-item>
            <el-form-item label="识别色深" v-show="this.optRecognizeMode==='auto'">
              <el-input-number v-model="colorDepth" :min="1" :max="100" :step="5"></el-input-number>
            </el-form-item>
          </el-form>

          <div style="margin-top: 10px;padding-left: 20px;" v-show="subDialogMode!=='orm'">
            <el-button-group>
              <el-button size="default" type="primary" icon="notebook-2" @click="generateSubNum">
                <el-icon>
                  <notebook/>
                </el-icon>
                生成子题号
              </el-button>
            </el-button-group>
          </div>
          <div class="row row-button-center">
            <el-button type="primary" @click="batchAddSubquestions">保 存</el-button>
            <el-button @click="closeBatchOptionDialog">关 闭</el-button>
          </div>
        </div>

      </div>
    </el-dialog>

  </el-container>

  <!-- 在 template 中添加，放在最外层组件内 -->
  <el-dialog 
    v-model="scannerDialogVisible"
    title="扫描"
    width="90%"
    :destroy-on-close="true"
    :close-on-click-modal="false"
    @close="loadSubjectPaper"
  >
    <scanner-view 
      :enabled-scan-template="['normal']"
    />
  </el-dialog>

  <!-- 新增子科目配置对话框 -->
  <el-dialog 
    v-model="subSubjectDialogVisible" 
    title="子科目配置" 
    width="600px"
  >
    <div class="sub-subject-config">
      <div class="sub-subject-header">
        <el-button 
          type="primary" 
          icon="Plus" 
          @click="addSubSubject"
        >
          新增子科目
        </el-button>
      </div>

      <el-table 
        :data="propData.sub_prop" 
        style="width: 100%"
        max-height="400px"
      >
        <el-table-column label="子科目名称" prop="name">
          <template #default="{ row, $index }">
            <el-input 
              v-model="row.name" 
              placeholder="请输入子科目名称"
            />
          </template>
        </el-table-column>
        
        <el-table-column label="题组选择" prop="questionIds">
          <template #default="{ row }">
            <el-select
              v-model="row.questionIds"
              multiple
              placeholder="请选择题组"
              style="width: 100%"
            >
              <el-option
                v-for="question in questionList"
                :key="question.question_id"
                :label="question.question_name"
                :value="question.question_id"
              />
            </el-select>
          </template>
        </el-table-column>

        <el-table-column label="操作" width="100">
          <template #default="{ $index }">
            <el-button 
              type="danger" 
              icon="Delete" 
              @click="removeSubSubject($index)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <div class="dialog-footer">
        <el-button @click="subSubjectDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveSubSubjects">保 存</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import "@/assets/css/common.css"
import {fabric} from "fabric"
import {useAppStoreWithOut} from "@/store/modules/app";
import { Plus, Minus } from '@element-plus/icons-vue'
import ScannerView from './ScannerView.vue'

let cvs = null
let optcvs = null
export default {
  name: "NewManageExamSubjectPaper",
  components: {
    Plus,
    Minus,
    ScannerView
  },
  computed: {
    quesAreaAddButton() {
      return this.optMode === "ques-area-add" ? "success" : "primary"
    },
    subAreaAddButton() {
      return this.optMode === "sub-area-add" ? "success" : "primary"
    },
    pointAddButton() {
      return this.optMode === "sub-point-add" ? "success" : "primary"
    },
    maskAreaAddButton() {
      return this.optMode === "mask-area-add" ? "success" : "primary"
    },
    recognizeAreaAddButton() {
      return this.optMode === "recognize-area-add" ? "success" : "primary"
    },
    absentAreaAddButton() {
      return this.optMode === "absent-area-add" ? "success" : "primary"
    },
    multiChoiceAreaAddButton() {
      return this.optMode === "multi-choice-area-add" ? "success" : "primary"
    },
    codeAreaAddButton() {
      return this.optMode === "code-area-add" ? "success" : "primary"
    },
    optColDelButton() {
      return this.optObjectiveMode === "col-remove" ? "success" : "primary"
    },
    optRowDelButton() {
      return this.optObjectiveMode === "row-remove" ? "success" : "primary"
    },
    firstOptSwitchButton() {
      return this.optObjectiveMode === "first-opt-switch" ? "success" : "primary"
    },
    setMultiButton() {
      return this.optObjectiveMode === "multi-opt-switch" ? "success" : "primary"
    },
    setModelButton() {
      return this.optRecognizeMode === "auto" ? "success" : "primary"
    },
    setPersonModelButton() {
      return this.optRecognizeMode !== "auto" ? "success" : "primary"
    },
    optColSetButton() {
      return this.optRecognizeMode === "col" ? "success" : "primary"
    },
    optRowSetButton() {
      return this.optRecognizeMode === "row" ? "success" : "primary"
    },
  },
  watch: {
    "rectHeight"() {
      this.toGenerateOptionArea()
    },
    "rectWidth"() {
      this.toGenerateOptionArea()
    },
    "quesData.validScoreStr"(val) {
      this.validScoreStr = val
      this.quesData.content.valid_scores = this.validScoreRegex.test(val) ?
          this.quesData.content.valid_scores = JSON.parse("[" + val + "]") : []
    },
    "propData.ormarea"(val) {
      let areas = val
      if (areas instanceof Array)
        areas.forEach(area => {
          this.drawOptFabricGroup(area, "orm_subquestion_area", "orm_subquestion_area_mark", this.optColor, "", -1)
        })
      this.setRectAttributes()
    },
    "quesData.content.score_interval": {
      handler(interval) {
        this.generateValidScore()
      },
      deep: true
    }
  },

  data() {
    return {
      examID: null,
      cvs: cvs,
      optcvs: null,
      downPoint: null,
      upPoint: null,
      downPos: null,
      downMousePos: null,
      lastMousePos: {x: 0, y: 0},
      maskColor: "#000000",          // 遮盖区域框线颜色：纯黑色，用于遮盖或突显某些区域
      codeColor: "#000000",          // 条码区域框线颜色：纯白色，在黑白背景上都能清晰可见
      quesColor: "#FF0000",            // 题组框线颜色：红色，非常醒目，确保在黑白背景上明显
      subquesColor: "#a0d911",       // 子题框线颜色：绿色，与题组颜色形成对比，易于区分
      optColor: "#a0d911",           // 选项框线颜色：蓝色，在黑白背景上有良好对比度
      optTxtColor: "#ff0000",        // 单选题题号颜色：黄色，突出显示单选题
      optMultiTxtColor: "#1890ff",   // 多选题题号颜色：青色，与单选题号颜色区分开
      firstOptColor: "#c41d7f",      // 首选项框线颜色：紫色，确保与其他框线颜色不同
      multiOptColor: "#2f54eb",      // 多选项框线颜色：橙色，提供与选项框线颜色的区分
      textColor: "#000000",            // 文本颜色：白色，保证在黑色或深色背景上可读
      fontSize: 16,                  // 字体大小：保持为16
      fillColor: "#000000",          // 填充颜色：黑色，在白色背景上填充区域
      selectedFillColor: "#02b6ed",
      //zzq
      areaImageList: [],
      radius: 30,
      circleText: 20,
      zoom: -1,
      optzoom: -1,
      totalWidth: 0,
      totalWidth2: 0,


      polygonStrokeWidth: 2,
      markStrokeWidth: 16,
      selectedTargerOriginalData: {x: 0, y: 0, uid: -1},
      temporaryObjectRecordList: [],
      recordOriginalList: [],
      transparent: "transparent",
      subjectName: "",
      exam: {
        exam_id: null,
        exam_name: "",
        unit_id: null,
        unit_name: "",
        exam_state: "",
        subjects: []
      },
      search: {
        examid: null,
        subjectid: null
      },
      subjectPapers: [],
      paperTreeProps: {
        children: 'subpaper',
        label: 'file_name'
      },
      fitPaper: true, //为true是渲染试卷后自动缩放适应宽度
      paperLoaded: false,
      propLoaded: false,
      questionLoaded: false,
      questionList: [],
      questionTreeProps: {
        children: 'sub_question',
        label: 'question_name'
      },
      questionTreeHeight: 400,
      validAreas: [],
      optMode: "",  //操作模式
      optObjectiveMode: "", //客观题操作模式
      activeMaskIdx: null,  //当前选中遮盖区域索引
      activeCodeIdx: null,  //当前选中条码区域索引
      activeQuesIdx: null,  //当前选中题组索引
      activeSubquesIdx: null, //当前选中子题索引
      activePointIdx: null, //当前选中打分点索引
      activeArea: null,
      paperVisi: false,
      uploadFileList: [],
      activeTab: "paperTab",
      subDialogTitle: "批量添加客观子题",
      propData: {
        examid: null,
        subjectid: null,
        name: "",
        maskarea: [],
        codearea: [],
        recognizearea: [],
        strict_mode: '0',
        absentarea: [],
        //1 条形码 2 ORM码
        codeareatype: "1",
        //横向1 纵向2
        codeareadirection: "2",
        markingType: "1", // 阅卷模式 1-混合阅卷，2-独立阅卷
        ormarea: [],
        fillarea: [],
        multi_choice_area: [],
        coverage: 50,
        uncoverage: 9,// 缺考未覆盖率
        absent_omrfigure: "2", //1-圆 2-方，3-开方
        orm_omrfigure: "2", // 1-圆 2-方，3-开方
        orm_coverage: 25,
        orm_uncoverage: 9,
        total_score: 0,
        depth: 50,
        scan_prop: {
          scan_type: "3", // 扫描类型： 1-按考场扫描 2-按学校扫描，3-全局扫描
          scan_dpi: "200", // 分辨率：150dpi,100dpi,200dpi
          scan_way: "2", // 扫描方式：1-单面 2-双面
          scan_color: "1", //扫描颜色：1-黑白 2-灰度 3-真彩
          scan_paper_size: "3", // 扫描纸张大小：1-A4 2-A3,3-其他
          student_id_length: 0,
          cut_start: 0,
          cut_end: 0,
          recognize_type: "1", // 识别方式：1-服务端识别 2-扫描端识别
          template_type: "1", // 模板类型：1-标准 2-非标准
        },
        has_sub: "0", // 是否有子科目
        sub_prop: [], // 子科目配置
        sub_prop_select: null, // 选中的子科目
      },
      quesTitle: "题组配置",
      quesDrawerVisi: false,
      subTitle: "子题配置",
      subDrawerVisi: false,
      drawerCloseClear: true,  //关闭抽屉时是否清除选中数据
      subDialogVisi: false,   //批量添加子题弹窗是否显示
      validScoreRegex: /^\d+(.\d+)?(\s*,\s*\d+(.\d+)?)*$/,
      subDialogMode: "",
      colorDepth: 20,
      quesData: {
        quesid: null,
        examid: null,
        subjectid: null,
        name: "",
        type: "",
        area: [],
        validScoreStr: "",
        content: {
          viewType: '1', //1. 普通视图 2.作文视图
          full_score: null,
          score_interval: 1,
          valid_scores: [],
          shortcuts: [],
          mark_mode: "",
          limit_time: 0,
          score_diff: null,
          remark_flag: true,
          remark_interval: 100,
          choose_flag: false,
          coverage: 25, // 单选覆盖率
          coverage2: 55, // 扫描仪需要此参数：多选覆盖率
          depth: 70,
          self_score_diff: 0, // 自评分差，用于统计个人一致性
          standard_score_diff: 1, // 标准卷分差，用于统计专家一致性
          marktype: "1", // 扫描仪端需要此参数： 0-真假 1-ABCDEFG 2-0123456
          uncoverage: 9, // 扫描仪端需要此参数:单选未覆盖率
          uncoverage2: 15, // 扫描仪端需要此参数:多选未覆盖率
          omrfigure: "2", // 扫描仪端需要此参数： 1-圆 2-方，3-开方
          multi_choices: [], // 多选题选项列表，存的是{option: "A", name: "物理A"}
          multi_choices_area: [] // 多选题区域列表,
        }
      },
      subData: {
        viewType: "1",
        subid: null,
        quesid: null,
        examid: null,
        subjectid: null,
        type: "",
        num: null,
        name: "",
        area: [],
        content: {
          full_score: null,
          points: [],
          is_multi: false,
          list: [] // 作文的项
        }
      },
      subQuestionContentListSelect: null, // 选择作文的项
      subDialogData: {
        direction: "1",  //选项《题号》的排序方向，1-横向，2-纵向
        questionDirection: "1", // 选项《排列》的排序方向1-横向，2-纵向
        startnum: 1,//子题开始序号,
        //选项最小空宽度
        optMinBlank: 1,
      },
      objectiveLayers: [],
      objectiveOptionList: [],
      objectiveAreaList: [],  //弹窗中各区域的范围列表
      markModeList: [
        {
          value: '1',
          label: '1评'
        }, {
          value: '3',
          label: '2+1'
        },{
          value: '2',
          label: '2评'
        } , {
          value: '4',
          label: '1+1+1'
        }, {
          value: '5',
          label: '3+1'
        }
      ],
      shortCutsPopVisi: false,
      rowPointList: [],
      colPointList: [],
      optRecognizeMode: "auto",
      rectHeight: 10,
      rectWidth: 10,
      isOptionGenerate: false,
      propRule: {
        name: [{required: true, message: "请填写试卷名称", trigger: "blur"}, {
          max: 50,
          message: "不能超过50个字",
          trigger: "blur"
        }]
      },
      quesRule: {
        name: [{required: true, message: "请填写题组名称", trigger: "blur"}, {
          max: 50,
          message: "不能超过50个字",
          trigger: "blur"
        }],
        type: [{required: true, message: "请选择题组类型", trigger: "change"}],
        validScoreStr: [{
          pattern: /^\d+(.\d+)?(\s*,\s*\d+(\.\d+)?)*$/,
          message: "有效分数格式必须是：1,2.5,3",
          trigger: "blur"
        }]
      },
      subRule: {
        name: [{required: true, message: "请填写子题名称", trigger: "blur"}, {
          max: 50,
          message: "不能超过50个字",
          trigger: "blur"
        }],
        "content.full_score": [{required: true, message: "请填写满分", trigger: "blur"}]
      },
      objRule: {
        direction: [{required: true, message: "请选择方向类型", trigger: "change"}],
        startnum: [{required: true, message: "请选输入开始序号", trigger: "blur"}]
      },
      lastQuestionParams: { // 记住上一次添加题组的参数，自动填入
        quesid: null,
        examid: null,
        subjectid: null,
        name: "",
        type: "",
        area: [],
        validScoreStr: "",
        content: {
          viewType: '1', //1. 普通视图 2.作文视图
          full_score: null,
          score_interval: 1,
          valid_scores: [],
          shortcuts: [],
          mark_mode: "",
          limit_time: 0,
          score_diff: 0,
          remark_flag: true,
          remark_interval: 100,
          choose_flag: false,
          coverage: 25, // 单选覆盖率
          coverage2: 55, // 扫描仪需要此参数：多选覆盖率
          depth: 70,
          self_score_diff: 0, // 自评分差，用于统计个人一致性
          standard_score_diff: 1, // 标准卷分差，用于统计专家一致性
          marktype: "1", // 扫描仪端需要此参数： 0-真假 1-ABCDEFG 2-0123456
          uncoverage: 9, // 扫描仪端需要此参数:单选未覆盖率
          uncoverage2: 15, // 扫描仪端需要此参数:多选未覆盖率
          omrfigure: "2" // 扫描仪端需要此参数： 1-圆 2-方，3-开方
        }
      },
      th: 0, // 图片高度
      tw: 0, // 图片宽度
      page1h: 0, // 页面1高度
      expandMode: '1', // 1: 仅展开主观题, 2: 展开所有, 3: 不展开
      expandedKeys: [],
      treeExist: false,  // 树组件是否存在
      scannerDialogVisible: false,
      // 新增对话框控制变量
      subSubjectDialogVisible: false,
      chooseAreaList: [], // 存储选做题区域列表
    }
  },
  setup() {
    const appStore = useAppStoreWithOut()
    return {appStore}
  },
  mounted() {
    this.lastQuestionParams.content.mark_mode = this.markModeList[0]
    this.examID = this.appStore.exam_id
    this.search.subjectid = this.appStore.subject_id
    if (this.examID != null) {
      this.examID = Number(this.examID)
      this.search.examid = this.examID
    }
    this.$nextTick(() => {
      window.removeEventListener("resize", this.calcZoom)
      window.addEventListener("resize", this.calcZoom)
      window.removeEventListener("resize", this.calcZoomOpt)
      window.addEventListener("resize", this.calcZoomOpt)
    })
    this.init()
    this.loadExamInfo()

  },
  beforeUnmount() {
    window.removeEventListener("resize", this.calcZoom)
  },
  //计算相关高度
  methods: {
    handleInputChange(currentValue) {
      // el-input-number的input事件直接传递当前值
      if(currentValue !== null && currentValue !== undefined && currentValue > 0) {
        // 临时更新full_score用于生成有效分数
        const tempFullScore = this.quesData.content.full_score
        this.quesData.content.full_score = Number(currentValue)
        this.generateValidScore()
        // 恢复原值,等待el-input-number自己的更新
        this.quesData.content.full_score = tempFullScore
      }
    },
    generateValidScore() {
      const fullScore = this.quesData.content.full_score
      const interval = this.quesData.content.score_interval
      console.log("interval ",interval)
      if (fullScore && interval) {
        // 生成分数范围进quesData.validScoreStr
        const arr = []
        for (let i = 0; i <= parseFloat(fullScore); i++) {
          arr.push(i)
          i += parseFloat(interval) - 1
        }
        this.quesData.validScoreStr = arr
        // 根据arr自动生成常用快捷键
        const ct = this.quesData.content
        ct.shortcuts = []
        // 常用字母数组
        const al = ['Q', 'W', 'E', 'R', 'A', 'S', 'D', 'F']
        for (let i = 0; i < arr.length; i++) {
          if (i < al.length) ct.shortcuts.push({key: al[i], score: arr[i]})
        }
      }
    },


    addNewPoint() {
      const size = this.subData.content.list.length
      this.subData.content.list.push({name: '项' + size, min: null, max: null, interval: 1, level3Flag: true})
      this.subQuestionContentListSelect = size
    },
    init() {
      if (cvs) {
        cvs.clear(); // 清空画布
        cvs.dispose(); // 释放与画布关联的所有资源
      }
      cvs = new fabric.Canvas(this.$refs.canvas)
      cvs.skipTargetFind = false
      cvs.selectionColor = 'rgba(0,0,0,0.2)'
      cvs.selectionBorderColor = this.quesColor
      cvs.selection = 'selection';
      this.cvs = cvs
      cvs.on('mouse:move', options => {
        this.mouseMove(options)
      })
      cvs.on('mouse:down', options => {
        this.mouseDown(options)
      })
      cvs.on('mouse:up', options => {
        this.mouseUp(options)
      })
    },
    //客观子题画布对象初始化
    optInit() {
      if (optcvs) {
        optcvs.clear(); // 清空画布
        optcvs.dispose(); // 释放与画布关联的所有资源
      }

      optcvs = new fabric.Canvas(this.$refs.optcanvas)
      optcvs.skipTargetFind = false
      optcvs.selectionColor = 'rgba(0,0,0,0.2)'
      optcvs.selectionBorderColor = this.quesColor
      optcvs.selection = 'selection';
      optcvs.on('mouse:move', options => {
        this.mouseMove(options)
      })
      optcvs.on('mouse:down', options => {
        if (this.optRecognizeMode !== 'auto') {
          const pointer = optcvs.getPointer(event.e);
          const x = pointer.x - 10 / 2.0;
          const y = pointer.y - 10 / 2.0;
          console.log({x: x, y: y});

          if (this.optRecognizeMode === 'col') {
            // 列模式：保持 x 坐标一致，更新 y 坐标
            if (this.colPointList.length > 0) {
              let firstX = this.colPointList[0].x;
              this.colPointList.push({x: firstX, y: y, w: 10, h: 10, uid: this.uuid()});
            } else {
              this.colPointList.push({x: x, y: y, w: 10, h: 10, uid: this.uuid()});
            }
          } else if (this.optRecognizeMode === 'row') {
            // 行模式：保持 y 坐标一致，更新 x 坐标
            let firstY = this.colPointList[0].y;
            this.rowPointList.push({x: x, y: firstY, w: 10, h: 10, uid: this.uuid()});

          }
          if (this.optRecognizeMode === 'col' || this.optRecognizeMode === 'row') {
            // 将列和行的点合并到 objectiveOptionList 中
            this.colPointList.forEach(area => {
              if (!this.objectiveOptionList.find(it => it.uid === area.uid)) {
                this.objectiveOptionList.push(area);
              }
            });
            this.rowPointList.forEach(area => {
              if (!this.objectiveOptionList.find(it => it.uid === area.uid)) {
                this.objectiveOptionList.push(area);
              }
            });
            // 重新渲染画布上的选项
            this.renderPersonObjectiveOption();
          }
        }
      })
      optcvs.on('mouse:up', options => {
        this.objectiveMouseUp(options)
      })

    },
    //更新当前操作对象信息,遍历所有数据找到匹配项进行内容修改。
    updateAreaData(o) {
      let type = o.object_type
      if (type === "question") {
        for (let key in this.quesData) {
          if (this.quesData[key] instanceof Array)
            this.quesData[key].filter((area) => {
              return area.uid === o.uid
            }).forEach(area => {
              const range = this.getRangeByPaperID(area.p)
              area.x = parseInt(o.left)
              area.y = parseInt(o.top - range.thrange1)
              area.w = parseInt(o.width)
              area.h = parseInt(o.height)
            })
        }
      }
      if (type === "subquestion_area") {
        for (let key in this.subData) {
          if (this.subData[key] instanceof Array)
            this.subData[key].filter((area) => {
              return area.uid === o.uid
            }).forEach(area => {
              const range = this.getRangeByPaperID(area.p)
              area.x = parseInt(o.left)
              area.y = parseInt(o.top - range.thrange1)
              area.w = parseInt(o.width)
              area.h = parseInt(o.height)
            })
        }
      }
      if (type === "subquestion_area_point") {
        for (let key in this.subData) {
          if (this.subData[key]?.points instanceof Array)
            this.subData[key].points.filter((area) => {
              return area.uid === o.uid
            }).forEach(area => {
              const range = this.getRangeByPaperID(area.p)
              area.x = parseInt(o.left)
              area.y = parseInt(o.top - range.thrange1)
            })
        }
      }
      if (type === "opt_subquestion_area") {
        for (let key in this.quesData) {
          if (this.quesData[key] instanceof Array)
            this.quesData[key].filter((area) => {
              return area.uid === o.uid
            }).forEach(area => {
              const range = this.getRangeByPaperID(area.p)
              area.x = parseInt(o.left)
              area.y = parseInt(o.top - range.thrange1)
              area.w = parseInt(o.width)
              area.h = parseInt(o.height)
            })
        }
      }
      if (type === "mask" || type === "code" || type === "absent" || type === "recognize") {
        for (let key in this.propData) {
          if (this.propData[key] instanceof Array)
            this.propData[key].filter((area) => {
              return area.uid === o.uid
            }).forEach(area => {
              const range = this.getRangeByPaperID(area.p)
              area.x = parseInt(o.left)
              area.y = parseInt(o.top - range.thrange1)
              area.w = parseInt(o.width)
              area.h = parseInt(o.height)
            })
        }
      }
      if (type === "choose_area") {
        this.quesData.content.choose_area = this.quesData.content.choose_area || []
        this.quesData.content.choose_area.filter((area) => {
          return area.uid === o.uid
        }).forEach(area => {
          const range = this.getRangeByPaperID(area.p)
          area.x = parseInt(o.left)
          area.y = parseInt(o.top - range.thrange1)
          area.w = parseInt(o.width)
          area.h = parseInt(o.height)
        })
      }
      if (o.selectable === true)
        this.selectArea(o)

    },

    uuid() {
      var s = [];
      var hexDigits = "0123456789abcdef";
      for (var i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
      }
      s[14] = "4";
      s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);
      s[8] = s[13] = s[18] = s[23] = "-";

      this.uuidA = s.join("");
      return this.uuidA;
    },
    calcZoom() {
      //获取父元素高宽
      let canvasContainer = cvs.wrapperEl.parentNode;
      let parentWidth = canvasContainer.clientWidth
      //计算缩放比
      let originZoom = 1
      if (cvs.getWidth() > parentWidth) {
        originZoom = 1 / (cvs.getWidth() / parentWidth)
        cvs.setZoom(originZoom)
      }
      this.zoom = cvs.getZoom().toFixed(2)
    },


    calcZoomOpt() {

      if (this.subDialogVisi === true) {
        //获取父元素高宽
        let canvasContainer = optcvs.wrapperEl.parentNode;
        let parentWidth = canvasContainer.clientWidth
        //计算缩放比
        let originZoom = 1
        if (optcvs.getWidth() > parentWidth) {
          originZoom = 1 / (optcvs.getWidth() / parentWidth)
          optcvs.setZoom(originZoom)
        } else {
          optcvs.setWidth(parentWidth)
        }
        if (optcvs.getHeight() < canvasContainer.clientHeight)
          optcvs.setHeight(canvasContainer.clientHeight)
        this.optzoom = optcvs.getZoom().toFixed(2)
        optcvs.setHeight(500)
      }
    },


    //载入考试科目信息
    loadExamInfo() {
      this.axios.post("/api/common/loadexaminfo", {examid: this.examID})
          .then((response) => {
            if (response.data.success) {
              this.exam = response.data.result
              this.subjectName = this.exam.subjects.filter(sub => {
                return sub.subject_id + "" === this.search.subjectid + ""
              })[0]["subject_name"]
              if (this.exam.subjects.length > 0) {
                this.search.examid = this.examID
                this.loadSubjectPaper()
              }
            }
          })
    },
    //载入模板图片
    loadSubjectPaper() {
      this.paperLoaded = false
      this.axios.post("/api/exam/mgeexamsubjectpapers/loadexamsubjectpaper", this.search)
          .then((response) => {
            if (response.data.success) {
              let papers = response.data.result
              papers.forEach((paper, index) => {
                // 保存原始 base64 数据用于下载
                const base64Data = paper.paper_image;
                
                // 创建图片对象用于显示
                let img = new Image()
                img.src = base64Data
                paper.paper_image = img


                 //this.downloadBase64Image(base64Data, `试卷${index + 1}.png`)
              })
              cvs.clear()
              this.subjectPapers = papers
              this.paperLoaded = true
              this.loadSubjectProp()
              this.loadSubjectQuestion()
              this.waitImageLoaded()
            }
          })
    },
    
    //载入模板图片
    loadSubjectProp() {
      this.propLoaded = false
      this.axios.post("/api/exam/mgeexamsubjectpapers/loadexamsubjectprop", this.search)
          .then((response) => {
            if (response.data.success) {
              let prop = response.data.result
              this.propData.examid = this.search.examid
              this.propData.subjectid = this.search.subjectid
              if (prop != null) {
                this.propData.markingType = prop.marking_type
                this.propData.name = prop.paper_name
                this.propData.ormarea = prop.orm_area
                this.propData.codeareatype = prop.code_type
                this.propData.codeareadirection = prop.code_direction
                this.propData.maskarea = prop.mask_area == null ? [] : prop.mask_area
                this.propData.codearea = prop.code_area == null ? [] : prop.code_area
                this.propData.fillarea = prop.fill_area == null ? [] : prop.fill_area
                this.propData.multi_choice_area = prop.multi_choice_area == null ? [] : prop.multi_choice_area
                this.propData.recognizearea = prop.recognize_area == null ? [] : prop.recognize_area

                this.propData.absentarea = prop.absent_area == null ? [] : prop.absent_area
                this.propData.depth = parseInt(prop.absent_area_depth)
                this.propData.coverage = parseInt(prop.absent_area_rate)
                this.propData.scan_prop = prop.scan_prop
                this.propData.has_sub = prop.has_sub
                this.propData.uncoverage = prop.uncoverage ? prop.uncoverage : 9
                this.propData.sub_prop = prop.sub_prop === null ? [] : prop.sub_prop
                this.propData.absent_omrfigure = prop.absent_omrfigure ? prop.absent_omrfigure : "2"
                this.propData.orm_omrfigure = prop.orm_omrfigure ? prop.orm_omrfigure : "2"
                this.propData.orm_coverage = prop.orm_coverage ? prop.orm_coverage : 25
                this.propData.orm_uncoverage = prop.orm_uncoverage ? prop.orm_uncoverage : 9
                this.propData.total_score = prop.total_score ? prop.total_score : 0
                this.propData.strict_mode = prop.strict_mode

                if (this.propData.has_sub === "1" && prop.sub_prop.length !== 0) {
                  this.propData.sub_prop_select = 0
                }
                this.addQuestionUUID(this.propData.maskarea)
                this.addQuestionUUID(this.propData.codearea)
                this.addQuestionUUID(this.propData.fillarea)
                this.addQuestionUUID(this.propData.absentarea)
                this.addQuestionUUID(this.propData.recognizearea)
                this.addQuestionUUID(this.propData.multi_choice_area)

              } else {
                this.propData.name = ""
                this.propData.maskarea = []
                this.propData.codearea = []
                this.propData.fillarea = []
                this.propData.absentarea = []
                this.propData.recognizearea = []
                this.propData.multi_choice_area = []

              }
              this.propLoaded = true
            }
          })
    },
    //载入题组配置
    loadSubjectQuestion(redraw) {
      this.questionLoaded = false
      this.optMode = ""
      this.activeArea = null
      this.activeQuesIdx = null
      this.activeSubquesIdx = null
      this.activePointIdx = null
      this.axios.post("/api/exam/mgeexamsubjectpapers/loadexamsubjectquestion", this.search)
          .then((response) => {
            if (response.data.success) {
              let qlist = response.data.result
              this.addQuestionUniID(qlist)
              this.questionList = qlist

              // 给每个子题都添加父题的viewType
              this.questionList.forEach((ques) => {
                const type = ques.content.viewType
                ques.sub_question.forEach((subQues) => {
                  subQues["viewType"] = type
                  if (subQues["viewType"] == "2") {
                    subQues["sub_question"] = subQues.content.list
                    subQues.content.list ? subQues.content.list.forEach((item) => {
                      item["level3Flag"] = true
                    }) : ''
                  }
                })
              })
              this.questionLoaded = true
              if (redraw === true)
                this.removeFabricObjectByType(['question_area', 'question', 'subquestion_area', 'subquestion_area_point', 'opt_subquestion_area', 'subquestion_area_mark','choose_area'])
              this.drawQuestion()
            }
            if (this.subjectPapers.length > 0 && this.questionList.length > 0)
              this.activeTab = "questionTab"
            else if (this.activeTab === "questionTab")
              this.activeTab = "paperTab"

            this.setRectAttributes()
            // 在数据加载完成后更新展开状态
            this.$nextTick(() => {
              this.updateExpandedKeys()
            })
          })
    },
    //对遮盖填涂区域生成唯一ID
    addQuestionUUID(list) {
      list.forEach(data => {
        data.uid = this.uuid()
      })
    },
    //对题组和子题生成唯一ID
    addQuestionUniID(list) {
      list.forEach(ques => {
        ques.uid = this.uuid()
        if (typeof ques.sub_question !== "undefined") {
          ques.question_area.forEach(area => {
            area.uid = this.uuid()
          })
          ques.sub_question.forEach(sub => {
            sub.uid = this.uuid()
            sub.subquestion_area.forEach(sub2 => {
              sub2.uid = this.uuid()
            })
            if (sub.content.points instanceof Array) {
              sub.content.points.forEach((point) => {
                point.uid = this.uuid()
              })
            }
          })
        }
        // 添加选做题区域的唯一标识
        if (ques.content.choose_area && ques.content.choose_area.length > 0) {
          ques.content.choose_area.forEach(area => {
            if (!area.uid) {
              area.uid = this.uuid()
            }
          })
        }
      })
    },

    //选择科目重新加载
    selectSubject() {
      if (this.search.subjectid != null) {
        cvs.clear()
        this.subjectPapers = []
        this.questionList = []
        this.propData = {
          examid: null,
          subjectid: null,
          name: "",
          maskarea: [],
          codearea: [],
          fillarea: [],
          recognizearea: []
        }
        this.optMode = ""
        this.activeArea = null
        this.validAreas = []
        this.quesDrawerVisi = false
        this.subDrawerVisi = false
        this.loadSubjectPaper()
      }
    },
    //模板图片上传成功以后执行方法
    uploadResult(response) {
      if (response.success) {
        this.loadSubjectPaper()
      } else
        this.$message.error(response.result)
      this.$refs.paperUpload.clearFiles()
    },
    //模板图片上传出错以后执行方法
    uploadError(error) {
      this.$message.error(error.message)
    },
    //等待全部试卷图片加载完成
    waitImageLoaded() {
      let allLoaded = true
      this.subjectPapers.forEach(paper => {
        if (!paper.paper_image.complete)
          allLoaded = false
      })
      if (!allLoaded || !this.paperLoaded || !this.propLoaded || !this.questionLoaded)
        setTimeout(this.waitImageLoaded, 33)
      else {
        this.renderSubject()
        if (this.subjectPapers.length > 0 && this.questionList.length > 0)
          this.activeTab = "questionTab"
        else if (this.activeTab === "questionTab")
          this.activeTab = "paperTab"
      }
    },
    //保存试卷配置
    toPropSave() {
      this.$refs.subForm.validate(valid => {
        if (valid) {
          if (this.propData.codeareatype === "1") {
            this.propData.ormarea.forEach(area => {
              this.removeFabricObjectByUid(area)
            })
            this.propData.ormarea = []
          }
          if (this.propData.sub_prop.length > 0) {
            this.propData.sub_prop.forEach((item, idx) => {
              item['index'] = idx
            })
          }
          this.axios.post("/api/exam/mgeexamsubjectpapers/savesubjectprop", this.propData)
              .then((response) => {
                if (response.data.success) {
                  this.$message({message: "试卷配置保存成功", type: "success"})
                }
              })

        } else
          return valid
      })
    },
    canvasZoom(model) {
      // 获取画布当前缩放值
      let zoom = cvs.getZoom()
      if (0.1 <= zoom <= 2.0)
        if (model === "zoomout") {
          zoom -= 0.1
        } else {
          zoom += 0.1
        }
      // 控制缩放范围在 0.01~20 的区间内
      if (zoom > 2) zoom = 2
      if (zoom < 0.1) zoom = 0.1
      // 设置画布缩放比例
      cvs.setZoom(zoom)
      this.zoom = zoom.toFixed(2)
      // 设置画布大小
      cvs.setWidth((this.tw + 100) * this.zoom)
      cvs.setHeight((this.th + 100) * this.zoom)
    },
    optcanvasZoom(model) {
      // 获取画布当前缩放值
      let zoom = optcvs.getZoom()
      if (0.1 <= zoom <= 2.0)
        if (model === "zoomout") {
          zoom -= 0.1
        } else {
          zoom += 0.1
        }
      // 控制缩放范围在 0.01~20 的区间内
      if (zoom > 2) zoom = 2
      if (zoom < 0.1) zoom = 0.1
      // 设置画布缩放比例
      optcvs.setZoom(zoom)
    },

    getPaperRange() {
      //tw = 0, th = 0, 为总体有效范围，thrange1 = 0,thrange2 = 0为当前点击页有效范围
      let tw = 0, th = 0, paperIndex = -1, thrange1 = 0, thrange2 = 0
      this.subjectPapers.forEach((paper, idx) => {
        if (paper.paper_image.width > tw)
          tw = paper.paper_image.width
        th += paper.paper_image.height
        if (paperIndex === -1) {
          if ((th - paper.paper_image.height) <= this.downPoint.y && this.downPoint.y < th) {
            thrange1 = th - paper.paper_image.height
            thrange2 = th
            paperIndex = idx
          }
        }

      })
      return {tw: tw, th: th, p: paperIndex, thrange1: thrange1, thrange2: thrange2}
    },
    getRangeByPaperID(pid) {
      let th = 0, thrange1 = 0, thrange2 = 0, width = 0, height = 0
      this.subjectPapers.forEach((paper, idx) => {
        th += paper.paper_image.height
        if (idx === pid) {
          width = paper.paper_image.width
          height = paper.paper_image.height
          thrange1 = th - paper.paper_image.height
          thrange2 = th
        }
      })
      return {thrange1: thrange1, thrange2: thrange2, width: width, height: height}
    },
    renderSubject() {
      if (this.subjectPapers.length > 0) {
        // 计算最大宽度和总高度
        let maxWidth = 0, totalHeight = 0;
        this.subjectPapers.forEach(paper => {
          if (paper.paper_image.width > maxWidth) {
            maxWidth = paper.paper_image.width;
          }
          totalHeight += paper.paper_image.height;
        });

        this.th = totalHeight;
        this.tw = maxWidth;
        this.page1h = this.subjectPapers[0].paper_image.height;

        // 获取容器宽度
        const container = this.$refs.mainscroll.$el;
        const containerWidth = container.clientWidth;

        // 计算缩放比例,使图片宽度适应容器
        const scale = containerWidth / maxWidth;
        
        // 设置画布尺寸和缩放
        cvs.setWidth(maxWidth);
        cvs.setHeight(totalHeight); 
        cvs.setZoom(scale);

        // 渲染图片
        let currentHeight = 0;
        this.subjectPapers.forEach(paper => {
          let imgInstance = new fabric.Image(paper.paper_image, {
            top: currentHeight,
            left: 0,
            width: paper.paper_image.width,
            height: paper.paper_image.height,
            stroke: this.multiOptColor,
            strokeWidth: 2,
            hoverCursor: "auto",
            selectable: false,
            evented: false,
            scaleX: 1,
            scaleY: 1
          });

          currentHeight += paper.paper_image.height;
          cvs.add(imgInstance);
          imgInstance.sendToBack();
        });

        // 更新zoom显示
        this.zoom = (scale * 100).toFixed(0);
      }

      this.renderPaperConfigure();
    },

    mouseMove(options) {
      let position = options.pointer

      // 如果是在框选状态下
      if (this.downPoint && (
          this.optMode === "ques-area-add" ||
          this.optMode === "sub-area-add" ||
          this.optMode === "recognize-area-add" ||
          this.optMode === "absent-area-add" ||
          this.optMode === "code-area-add" ||
          this.optMode === "multi-choice-area-add"
      )) {
        const mainscroll = this.$refs.mainscroll
        const container = mainscroll.$el
        const rect = container.getBoundingClientRect()
        const mouseEvent = options.e

        // 获取容器的可滚动范围
        const maxScrollTop = container.scrollHeight - container.clientHeight
        const maxScrollLeft = container.scrollWidth - container.clientWidth

        // 定义边缘敏感区域大小(px)
        const edgeThreshold = 50
        // 定义滚动速度(px)
        const scrollSpeed = 15

        // 处理垂直方向滚动
        if (mouseEvent.clientY > rect.bottom - edgeThreshold) {
          // 向下滚动
          container.scrollTop = Math.min(container.scrollTop + scrollSpeed, maxScrollTop)
        } else if (mouseEvent.clientY < rect.top + edgeThreshold) {
          // 向上滚动
          container.scrollTop = Math.max(container.scrollTop - scrollSpeed, 0)
        }

        // 处理水平方向滚动
        if (mouseEvent.clientX > rect.right - edgeThreshold) {
          // 向右滚动
          container.scrollLeft = Math.min(container.scrollLeft + scrollSpeed, maxScrollLeft)
        } else if (mouseEvent.clientX < rect.left + edgeThreshold) {
          // 向左滚动
          container.scrollLeft = Math.max(container.scrollLeft - scrollSpeed, 0)
        }
      }
    },

    //对试卷配置区域进行绘画
    renderPaperConfigure() {
      if (this.propData.maskarea.length > 0) {
        const areaList = this.propData.maskarea
        areaList.forEach((area, idx) => {
          let title = "遮盖区域" + (idx + 1)
          this.drawFabricGroup(area, "mask", this.maskColor, title)
        })
      }
      if (this.propData.multi_choice_area.length > 0) {
        const areaList = this.propData.multi_choice_area
        areaList.forEach((area, idx) => {
          let title = "选做题标志区域-" + area.option
          this.drawFabricGroup(area, "multi_choice", this.optColor, title)
        })
      }
      if (this.propData.recognizearea.length > 0) {
        const areaList = this.propData.recognizearea
        areaList.forEach((area, idx) => {
          let title = "科目识别区域" + (idx + 1)
          this.drawFabricGroup(area, "recognize", this.maskColor, title)
        })
      }
      if (this.propData.codearea.length > 0) {
        const areaList = this.propData.codearea
        areaList.forEach((area, idx) => {
          let title = "条码区域" + (idx + 1)
          this.drawFabricGroup(area, "code", this.codeColor, title)
        })
      }

      if (this.propData.absentarea.length > 0) {
        const areaList = this.propData.absentarea
        areaList.forEach((area, idx) => {
          let title = "缺考区域" + (idx + 1)
          this.drawFabricGroup(area, "absent", this.codeColor, title)
        })
      }


      this.setRectAttributes()
    },
    renderPaperConfigureUnadd() {
      this.optMode = ""
      if (this.propData.maskarea.length > 0) {
        const areaList = this.propData.maskarea
        areaList.forEach((area, idx) => {
          if (!('uid' in area)) {
            area.uid = this.uuid()
            let title = "遮盖区域" + (idx + 1)
            this.drawFabricGroup(area, "mask", this.maskColor, title)
          }
        })
      }
      if (this.propData.multi_choice_area.length > 0) {
        const areaList = this.propData.multi_choice_area
        areaList.forEach((area, idx) => {
          let title = "选做题标志区域-" + area.option
          // 要先判断是否画过了，画过了就不画了 
          if (!area.isDraw) {
            this.drawFabricGroup(area, "multi_choice", this.optColor, title)
            area.isDraw = true
          }
        })
      }

      if (this.propData.recognizearea.length > 0) {
        const areaList = this.propData.recognizearea
        areaList.forEach((area, idx) => {
          let title = "科目识别区域" + (idx + 1)
          this.drawFabricGroup(area, "recognize", this.maskColor, title)
        })
      }
      if (this.propData.codearea.length > 0) {
        const areaList = this.propData.codearea
        areaList.forEach((area, idx) => {
          if (!('uid' in area)) {
            area.uid = this.uuid()
            let title = "条码区域" + (idx + 1)
            this.drawFabricGroup(area, "code", this.codeColor, title)
          }
        })
      }
      if (this.propData.absentarea.length > 0) {
        const areaList = this.propData.absentarea
        areaList.forEach((area, idx) => {
          if (!('uid' in area)) {
            area.uid = this.uuid()
            let title = "缺考区域" + (idx + 1)
            this.drawFabricGroup(area, "absent", this.maskColor, title)
          }
        })
      }
      if (this.quesData.area.length > 0) {
        const areaList = this.quesData.area
        areaList.forEach((area, idx) => {
          if (!('uid' in area)) {
            area.uid = this.uuid()
            if (this.quesData.name === undefined)
              this.quesData.name = ""
            let title = this.quesData.name + "-" + (idx + 1)
            this.drawFabricGroup(area, "question", this.quesColor, title)
          }
        })
      }
      if (this.subData.area.length > 0) {
        const areaList = this.subData.area
        areaList.forEach((area, idx) => {
          if (!('uid' in area)) {
            area.uid = this.uuid()
            if (this.subData.name === undefined)
              this.subData.name = ""
            let title = this.subData.name + "-" + (idx + 1)
            this.drawFabricGroup(area, "subquestion_area", this.subquesColor, title)
          }
        })
      }
      if (this.subData.content.points.length > 0) {
        const areaList = this.subData.content.points
        areaList.forEach((area) => {
          if (!('uid' in area)) {
            area.uid = this.uuid()
            this.drawPointFabricGroup(area, "subquestion_area_point")
          }
        })
      }
      // 渲染选做题区域
      if(this.quesData.content.choose_area) {
        this.quesData.content.choose_area.forEach(area => {
          this.drawFabricGroup(area, "choose_area", this.optColor, area.option)
        })
      }
      this.setRectAttributes()
    },
    optcvsRemoveObjectByType(type) {
      optcvs.getObjects().filter((it) => {
        return (it instanceof Object && it.object_type === type)
      }).forEach(object => {
        optcvs.remove(object)
      })
      optcvs.renderAll()
    },
    drawOptFabricGroup(area, type, mark, stroke, title, idx, direction) {
      const maskarea = new fabric.Rect({
        top: area.y + this.getRangeByPaperID(area.p).thrange1,
        left: area.x,
        width: area.w,
        height: area.h,
        fill: this.transparent,
        object_type: type,
        lockRotation: true,
        uid: area.uid,
        stroke: stroke,
        strokeWidth: this.polygonStrokeWidth
      })
      if (idx === 0) {
        if (idx === 0) {
          if (direction === "1") {
            // 横向排列时,文本显示在左边
            const text = new fabric.Text(title, {
              top: area.y + this.getRangeByPaperID(area.p).thrange1 + (area.h / 2) - this.fontSize / 2, // 垂直居中
              left: area.x - this.fontSize * 2, // 向左偏移两个字体大小的距离
              uid: area.uid,
              fill: this.optTxtColor,
              object_type: mark,
              lockRotation: true,
              fontSize: this.fontSize + 8,
            })
            cvs.add(text)
          } else {
            // 纵向排列时,文本显示在上面
            const text = new fabric.Text(title, {
              top: area.y + this.getRangeByPaperID(area.p).thrange1 - this.fontSize - 5, // 向上偏移
              left: area.x + (area.w / 2) - (this.fontSize + 8) / 2, // 水平居中
              uid: area.uid,
              fill: this.optTxtColor,
              object_type: mark,
              lockRotation: true,
              fontSize: this.fontSize + 8,
            })
            cvs.add(text)
          }
        }
      }
      cvs.add(maskarea)
      cvs.renderAll()

    },
    // eslint-disable-next-line no-unused-vars
    drawOptFabricGroup2(area, type, mark, stroke, title, idx) {
      const maskarea = new fabric.Rect({
        top: area.y,
        left: area.x,
        width: area.w,
        height: area.h,
        fill: this.transparent,
        object_type: type,
        lockRotation: true,
        uid: area.uid,
        stroke: stroke,
        strokeWidth: this.polygonStrokeWidth
      })
      optcvs.add(maskarea)
      optcvs.renderAll()
    },
    drawSubNum(area, type, direciton) {

      if (direciton === "1") {
        const text = new fabric.Text(area.sq.toString(), {
          top: area.y + (area.h / 2) - this.fontSize / 2,
          left: area.x - this.fontSize,
          uid: area.uid,
          fill: this.optTxtColor,
          object_type: type,
          lockRotation: true,
          fontSize: this.fontSize + 8,
        })
        optcvs.add(text)
        optcvs.renderAll()
      } else {
        const text = new fabric.Text(area.sq.toString(), {
          top: area.y - this.fontSize - 5,
          left: area.x + (area.w / 4), // 水平居中
          uid: area.uid,
          fill: this.optTxtColor,
          object_type: type,
          lockRotation: true,
          fontSize: this.fontSize + 8,
        })
        optcvs.add(text)
        optcvs.renderAll()
      }

    },
    drawFabricGroup(area, type, stroke, title) {
      const maskarea = new fabric.Rect({
        top: area.y + this.getRangeByPaperID(area.p).thrange1,
        left: area.x,
        width: area.w,
        height: area.h,
        fill: this.transparent,
        object_type: type,
        lockRotation: true,
        uid: area.uid,
        stroke: stroke,
        strokeWidth: this.polygonStrokeWidth,

      })
      const text = new fabric.Text(title, {
        top: area.y + this.getRangeByPaperID(area.p).thrange1 - this.fontSize,
        left: area.x,
        uid: area.uid,
        fill: this.textColor,
        object_type: type,
        lockRotation: true,
        fontSize: this.fontSize,


      })
      const group = new fabric.Group([maskarea], {
        top: area.y + this.getRangeByPaperID(area.p).thrange1,
        left: area.x,
        uid: area.uid,
        object_type: type,
        lockRotation: true,
        hasBorders: true,
        cornerSize: 5,

      })
      cvs.add(group)
      cvs.add(text)
      cvs.renderAll()

    },
    drawPointFabricGroup(area, type) {
      // 创建黄色的圆
      var circle = new fabric.Circle({
        radius: this.radius,
        fill: 'yellow',
        object_type: type,
        originX: 'center',
        originY: 'center',
        uid: area.uid,
        top: area.y + this.getRangeByPaperID(area.p).thrange1,
        left: area.x,
        scalable: false,
        hasControls: false,  // 禁用控制点
        lockScalingX: true,  // 禁用 X 方向的缩放
        lockScalingY: true   // 禁用 Y 方向的缩放
      });

      // 创建灰色的M字母
      var textM = new fabric.Text('M', {
        fontSize: this.circleText,
        fill: 'gray',
        object_type: type,
        originX: 'center',
        originY: 'center',
        uid: area.uid,
        top: area.y + this.getRangeByPaperID(area.p).thrange1,
        left: area.x,
        scalable: false,
        hasControls: false,  // 禁用控制点
        lockScalingX: true,  // 禁用 X 方向的缩放
        lockScalingY: true   // 禁用 Y 方向的缩放

      });
      const group = new fabric.Group([circle, textM], {
        top: area.y + this.getRangeByPaperID(area.p).thrange1,
        left: area.x,
        uid: area.uid,
        object_type: type,
        lockRotation: true,
        hasBorders: true,
        scalable: false,
        hasControls: false,  // 禁用控制点
        lockScalingX: true,  // 禁用 X 方向的缩放
        lockScalingY: true   // 禁用 Y 方向的缩放

      })
      cvs.add(group)
      cvs.renderAll()
    },
    drawQuestion() {
      this.questionList.forEach(question => {
        if (question.question_area instanceof Array) {
          question.question_area.forEach((area, idx) => {
            let title = question.question_name + "-" + (idx + 1)
            this.drawFabricGroup(area, "question", this.quesColor, title)
          })
        }
        // 渲染选做题区域
        if (question.content && question.content.choose_area) {
          question.content.choose_area.forEach(area => {
            this.drawFabricGroup(area, "choose_area", this.optColor, area.option)
          })
        }
        question.sub_question.forEach((sub) => {
          sub.subquestion_area.forEach((area, idx) => {
            if (question.question_type === "1")
              this.drawOptFabricGroup(area, "opt_subquestion_area", "subquestion_area_mark", this.optColor, sub.subquestion_name, idx, area.direction)
            else {
              this.drawFabricGroup(area, "subquestion_area", this.subquesColor, sub.subquestion_name, idx)
            }
          })
          if (sub.content.points instanceof Array) {
            sub.content.points.forEach((point) => {
              this.drawPointFabricGroup(point, "subquestion_area_point")
            })
          }

        })
      })
      this.setRectAttributes()
    },

    //设置画布内对象属性
    setRectAttributes() {
      cvs.getObjects().forEach(object => {
        if (!(object instanceof fabric.Image)) {
          if (object instanceof fabric.Group || object instanceof fabric.Rect) {
            object.selectable = false
            object.hoverCursor = "default"
            object.hasBorders = false
            object.hasControls = false
          } else if (object instanceof fabric.Text) {
            object.fill = this.fillColor
            object.selectable = false
            object.hoverCursor = "default"
            object.hasBorders = false
            object.hasControls = false
          }

        }
      })
      cvs.requestRenderAll()
      cvs.setZoom(cvs.getZoom() - 0.000000001)
      cvs.setZoom(cvs.getZoom() - 0.000000001)

      this.makeRectInValidArea()
    },
    //让绘画矩形在合理范围之内
    makeRectInValidArea() {
      // 作用在矩形的事件：松开鼠标
      cvs.forEachObject(function (obj) {
        obj.off('mouseup');
      });
      cvs.getObjects().forEach(object => {
        if (object instanceof fabric.Group || object instanceof fabric.Rect) {
          object.on('mouseup', ev => {
            this.calclateValidRect(ev)
          })
        }
      })
    },
    calclateValidRect(ev, rect) {
      let objBoundingRect = {
        left: 0,
        top: 0,
        height: 0,
        width: 0,
        valid: false
      }
      // 获取画布视口边界
      let canvasBoundaries = {tl: {x: 0, y: 0}, tr: {x: 0, y: 0}, bl: {x: 0, y: 0}, br: {x: 0, y: 0}}
      //获取有效范围，将画布边界替换为图片边界
      let range = this.getPaperRange()
      //  tl,tr,bl,br.
      canvasBoundaries.tl.y = range.thrange1
      canvasBoundaries.tr.y = range.thrange1
      canvasBoundaries.tr.x = range.tw
      canvasBoundaries.bl.y = range.thrange2
      canvasBoundaries.br.y = range.thrange2
      canvasBoundaries.br.x = range.tw
      if (ev) {
        const target = ev.target
        if ("object_type" in target && (target.object_type === "subquestion_area" || target.object_type === "subquestion_area_point") && target.selectable === true) {
          //找出其所属的父级区域,对子题区域进行处理
          let parent = this.getValidAreasByObjectType(this.selectedTargerOriginalData, target.object_type)
          if (parent !== null) {
            canvasBoundaries.tl.y = parent.top
            canvasBoundaries.tl.x = parent.left
            canvasBoundaries.tr.y = parent.top
            canvasBoundaries.tr.x = parent.left + parent.width
            canvasBoundaries.bl.x = parent.left
            canvasBoundaries.bl.y = parent.top + parent.height
            canvasBoundaries.br.x = parent.left + parent.width
            canvasBoundaries.br.y = parent.top + parent.height
          }
        }

        if (target.scaleY !== 1 || target.scaleX !== 1) {
          if (target.left < 0)
            target.left = 0
          if (target.top < 0)
            target.top = 0
          const range2 = this.getRangeByPaperID(range.p)
          if (target.left + target.width * target.scaleX > range2.width) {
            target.width = range2.width - target.left
          } else {
            target.width = target.width * target.scaleX
          }
          if (target.top + target.height * target.scaleY > range2.height) {
            target.height = range2.height - target.top
          } else {
            target.height = target.height * target.scaleY
          }
          objBoundingRect = {
            left: target.left,
            top: target.top,
            height: target.height,
            width: target.width
          }
          let title = cvs.getObjects().filter((it) => {
            return it instanceof fabric.Text && it.uid === target.uid
          })[0].text
          let stroke = ""
          if (target.getObjects().filter((it) => {
            return it instanceof fabric.Rect;
          }).length > 0) {
            stroke = target.getObjects().filter((it) => {
              return it instanceof fabric.Rect;
            })[0].stroke
          }

          const type = target.object_type
          const area = {
            y: target.top,
            x: target.left,
            w: target.width,
            h: target.height,
            uid: target.uid,
            p: this.getPaperRange().p
          }
          cvs.remove(target)
          cvs.renderAll()
          if (type === "opt_subquestion_area") {
            this.drawOptFabricGroup(area, type, stroke, title)
          } else if (type === "subquestion_area_point") {
            this.drawPointFabricGroup(area, type)
          } else {
            this.drawFabricGroup(area, type, stroke, title)
          }
          this.selectArea(area)
          this.makeRectInValidArea()
          cvs.renderAll()
        } else {
          objBoundingRect = {
            left: target.left,
            top: target.top,
            height: target.height,
            width: target.width
          }
        }
      } else {
        objBoundingRect = {left: rect.left, top: rect.top, height: rect.height, width: rect.width}
      }
      // 矩形的边界
      if (objBoundingRect.left < canvasBoundaries.tl.x) {
        objBoundingRect.left = canvasBoundaries.tl.x
      }
      if (objBoundingRect.left + objBoundingRect.width > canvasBoundaries.br.x) {
        objBoundingRect.left = canvasBoundaries.br.x - objBoundingRect.width
      }
      if (objBoundingRect.top < canvasBoundaries.tl.y) {
        objBoundingRect.top = canvasBoundaries.tl.y
      }
      if (objBoundingRect.top + objBoundingRect.height > canvasBoundaries.br.y) {
        objBoundingRect.top = canvasBoundaries.br.y - objBoundingRect.height
      }
      if (ev) {
        const target = ev.target
        target.top = objBoundingRect.top
        target.left = objBoundingRect.left
        target.width = objBoundingRect.width
        target.height = objBoundingRect.height
        this.updateAreaData(ev.target)
        // 刷新画布
        cvs.renderAll()
      }
      return objBoundingRect

    },

    selectArea(data) {
      this.activeArea = data
      const uid = data.uid
      let scroll = document.getElementById("mainscroll")
      cvs.getObjects().forEach(object => {
        if (!(object instanceof fabric.Image)) {
          //这里专门针对选中单选框做处理
          if (object instanceof fabric.Rect && 'object_type' in object) {
            if (object.object_type === "opt_subquestion_area" || object.object_type === "orm_subquestion_area" ) {
              if (object.uid === uid) {
                object.fill = this.subquesColor
              } else {
                object.fill = this.transparent
              }
            }
          }
          if (object instanceof fabric.Group) {
            if (object.uid === uid) {
              if (object.top * cvs.getZoom() > scroll.clientHeight || scroll.scrollTop > object.top * cvs.getZoom())
                scroll.scrollTop = object.top * cvs.getZoom()
              if (object.top * cvs.getZoom() > scroll.clientWidth || scroll.scrollLeft > object.left * cvs.getZoom())
                scroll.scrollLeft = object.left * cvs.getZoom() / 2

              object.selectable = true
              object.hoverCursor = "move"
              object.hasBorders = true
              object.hasControls = true
              object.transparentCorners = false
              object.cornerColor = this.multiOptColor
              
              // 移除对子题区域的特殊限制
              // if (object.object_type === "subquestion_area" && this.subData.content.points.length !== 0) {
              //   object.selectable = false
              //   object.hoverCursor = "default"
              // }

              // 为子题区域添加事件监听
              if (object.object_type === "subquestion_area") {
                // 移动开始时记录初始状态
                object.on('moving', () => {
                  if (this.subData.content.points.length > 0) {
                    this.$message.warning("由于您修改了子题目区域，打分点已自动删除！")
                    // 清除打分点数据
                    this.subData.content.points = []
                      // 移除打分点的视觉元素
                      this.removeFabricObjectByType(["subquestion_area_point"])
                      cvs.renderAll()
                  }
                })

                // 缩放开始时也进行同样的处理
                object.on('scaling', () => {
                  if (this.subData.content.points.length > 0) {
                    this.$message.warning("由于您修改了子题目区域，打分点已自动删除！")

                    // 清除打分点数据
                    this.subData.content.points = []
                    // 移除打分点的视觉元素
                    this.removeFabricObjectByType(["subquestion_area_point"])
                    cvs.renderAll()
                  }
                })
              }
            } else {
              object.selectable = false
              object.hoverCursor = "default"
              object.hasBorders = false
              object.hasControls = false
            }
          }

        }
      })
      cvs.renderAll()
      cvs.setZoom(cvs.getZoom() - 0.000000001)
    },
    removeMaskArea(data) {
      const idx = this.propData.maskarea.indexOf(data)
      this.propData.maskarea.splice(idx, 1)
      this.removeFabricObjectByUid(data)
    },
    removeRecognizeArea(data) {
      const idx = this.propData.recognizearea.indexOf(data)
      this.propData.recognizearea.splice(idx, 1)
      this.removeFabricObjectByUid(data)
    },
    removeAbsentArea(data) {
      const idx = this.propData.absentarea.indexOf(data)
      this.propData.absentarea.splice(idx, 1)
      this.removeFabricObjectByUid(data)
    },
    removeMultiChoiceArea(data) {
      const idx = this.propData.multi_choice_area.indexOf(data)
      this.propData.multi_choice_area.splice(idx, 1)
      this.removeFabricObjectByUid(data)
    },
    removeCodeArea(data) {
      const idx = this.propData.codearea.indexOf(data)
      this.propData.codearea.splice(idx, 1)
      this.removeFabricObjectByUid(data)
    },
    removeQuestionArea(data) {
      const idx = this.quesData.area.indexOf(data)
      this.quesData.area.splice(idx, 1)
      this.removeFabricObjectByUid(data)
    },
    removeFabricObjectByUid(data) {
      let objects = cvs.getObjects()
      objects.forEach(obj => {
        if ("uid" in obj && obj.uid === data.uid) {
          cvs.remove(obj)
        }
      })
    },
    removeFabricObjectByType(typelist) {
      const objects = cvs.getObjects()
      if (typelist instanceof Array) {
        typelist.forEach(type => {
          objects.forEach(obj => {
            if ('object_type' in obj && obj.object_type === type) {
              cvs.remove(obj)
            }
          })
        })
      }
    },
    mouseDown(options) {
      this.downPoint = cvs.getPointer(options.e)
      this.isMouseDown = true // 添加这行
      if (options.target) {
        if ("uid" in options.target
            && "selectable" in options.target
            && options.target.selectable === true
            && options.target.uid !== this.selectedTargerOriginalData.uid) {
          let target = null
          //通过键值对questionList进行遍历找到其中uid与options.target.uid相同的对象
          this.questionList.forEach((item) => {
            if (target === null)
              target = this.findObjectByUID(item, options.target.uid)
          })
          //如果没找到，则其数据在新增状态，还未在questionList中
          if (target === null) {
            target = options.target
            this.selectedTargerOriginalData.x = target.left
            this.selectedTargerOriginalData.y = target.top
            this.selectedTargerOriginalData.uid = target.uid
          } else {
            this.selectedTargerOriginalData.x = target.x
            this.selectedTargerOriginalData.y = target.y + this.getRangeByPaperID(target.p).thrange1
            this.selectedTargerOriginalData.uid = target.uid
          }
          console.log(JSON.stringify(this.selectedTargerOriginalData))
        }

      }
    },
    mouseUp(options) {
      this.upPoint = cvs.getPointer(options.e)
      this.isMouseDown = false // 添加这行
      // 调用 创建矩形 的方法
      if (this.optMode === "ques-area-add"
          || this.optMode === "sub-area-add"
          || this.optMode === "sub-point-add"
          || this.optMode === "mask-area-add"
          || this.optMode === "absent-area-add"
          || this.optMode === "multi-choice-area-add"
          || this.optMode === "recognize-area-add"
          || this.optMode === "choose-area-add"
          || this.optMode === "code-area-add") {
        if (this.optMode === "ques-area-add" && this.quesData.type === "1" && this.quesData.area.length === 1) {
          this.$message.warning("客观题只能有一个区域")
          return
        }
        if (this.optMode === "sub-area-add" || this.optMode === "sub-point-add") {
          let valid = this.calculateSubquestionOrPointValidArea()
          if (valid)
            this.drawerRect()
        } else {
          this.drawerRect()
        }
      }
    },
    findObjectByUID(jsonObject, targetUID) {

      let foundObject = null

      function recursiveTraversal(obj) {
        if (obj instanceof Object) {
          if ("uid" in obj && obj.uid === targetUID) {
            foundObject = obj;
          }
          for (let key in obj) {
            if (obj[key] instanceof Array) {
              obj[key].forEach((item) => {
                recursiveTraversal(item)
              })
            } else {
              recursiveTraversal(obj[key])
            }
          }
        }
      }

      recursiveTraversal(jsonObject)
      return foundObject
    },

    drawerRect() {
      // 如果点击和松开鼠标，都是在同一个坐标点，不会生成矩形
      if (JSON.stringify(this.downPoint) === JSON.stringify(this.upPoint) && this.optMode !== "sub-point-add") {
        return
      }

      // 创建矩形
      let top = Math.min(this.downPoint.y, this.upPoint.y)
      let bottom = Math.max(this.downPoint.y, this.upPoint.y)
      let left = Math.min(this.downPoint.x, this.upPoint.x)
      let width = Math.abs(this.downPoint.x - this.upPoint.x)
      let height = Math.abs(this.downPoint.y - this.upPoint.y)

      // 检查是否跨页面
      let currentPageTop = 0
      for(let i = 0; i < this.subjectPapers.length; i++) {
        const pageHeight = this.subjectPapers[i].paper_image.height
        const pageBottom = currentPageTop + pageHeight

        // 如果矩形的顶部和底部跨越了页面边界
        if (top < pageBottom && bottom > pageBottom) {
          this.$message.warning("不能跨页面框选区域，请在同一页面内框选")
          return
        }
        currentPageTop += pageHeight
      }

      // 其他有效性检查
      let valid = true
      let range = this.getPaperRange()
      if (top > range.th || left > range.tw || top + height > range.th || left + width > range.tw) {
        valid = false
        this.$messageBox.alert("框选超界，请在有效区域内进行框选！")
      }

      let rect = {top: top, left: left, width: width, height: height}
      const targetRect = {
        x: parseInt(rect.left),
        y: parseInt(rect.top - this.getRangeByPaperID(range.p).thrange1),
        p: range.p,
        w: parseInt(rect.width),
        h: parseInt(rect.height)
      }

      if (valid) {
        switch (this.optMode) {
          case "recognize-area-add": {
            this.propData.recognizearea.push(targetRect)
          }
            break 
          case "mask-area-add": {
            this.propData.maskarea.push(targetRect)
          }
            break
          case "absent-area-add": {
            this.propData.absentarea.push(targetRect)
          }
            break
          case "multi-choice-area-add": {

            // 先看看this.propData.multi_choice_area最后一个option是什么
            let lastOption = this.propData.multi_choice_area[this.propData.multi_choice_area.length - 1]?.option
            let offset = 0
            if (lastOption) {
              offset = lastOption.charCodeAt(0) - 65
            }
            let index = 65 + offset
            if(this.propData.multi_choice_area.length !== 0)  index += 1
            if(index > 90) {
              // 超出Z，则从A开始,并且前面加一个Z
              index = 65
              targetRect.option = "Z" + String.fromCharCode(index)
            } else {
              targetRect.option = String.fromCharCode(index)
            }
            this.propData.multi_choice_area.push(targetRect)
          }
            break
          case "code-area-add": {
            this.propData.codearea.push(targetRect)
          }
            break
          case "ques-area-add": {
            this.quesData.area.push(targetRect)
          }
            break
          case "sub-area-add": {
            this.subData.area.push(targetRect)
            //绘制子题区域时，自动绘制一个打分点
            let point = {
              x: targetRect.x + 15,
              y: targetRect.y + 15,
              p: targetRect.p,
              w: 0,
              h: 0
            }
            this.subData.content.points.push(point)
          }
            break
          case "sub-point-add": {
            this.subData.content.points.push(targetRect)
          }
            break
          case "choose-area-add": {
            // 初始化选做题区域数组
            this.quesData.content.choose_area = this.quesData.content.choose_area || []
            
            // 添加新区域,并标记为A/B选项
            const chooseArea = {
              ...targetRect,
              uid: this.uuid(),
              option: String.fromCharCode(65 + this.quesData.content.choose_area.length) // A, B, C...
            }
            this.quesData.content.choose_area.push(chooseArea)
            
            
          }
            break
          default:
            "";
        }
        this.renderPaperConfigureUnadd()
        this.optMode = ""
      }

    },
    //新增信息遮盖区域
    toAreaAdd(type) {
      this.selectArea({"uid": -9999})
      this.optMode = type
      if (this.quesDrawerVisi || this.subDrawerVisi) {
        this.quesDrawerVisi = false
        this.subDrawerVisi = false
      }
    }
    ,
    //关闭右边抽屉
    drawerClose() {
      this.loadSubjectQuestion(true)
      this.quesTitle = "题组配置"
      this.subTitle = "子题配置"
      if (this.drawerCloseClear) {
        this.optMode = ""
        this.activeArea = null
        this.activeQuesIdx = null
        this.activeSubquesIdx = null
        this.activePointIdx = null
        this.validAreas = []
      }
    },
    //新增题组
    toQuestionAdd() {
      this.quesData = {
        quesid: null,
        examid: this.search.examid,
        subjectid: this.search.subjectid,
        name: "",
        type: "2", // 默认主观题
        area: [],
        validScoreStr: "",
        content: {
          viewType: this.lastQuestionParams.content.viewType,
          full_score: 0,
          score_interval: 1,
          valid_scores: [],
          shortcuts: [],
          mark_mode: this.lastQuestionParams.content.mark_mode,
          limit_time: this.lastQuestionParams.content.limit_time,
          score_diff: this.lastQuestionParams.content.score_diff,
          remark_flag: this.lastQuestionParams.content.remark_flag,
          remark_interval: this.lastQuestionParams.content.remark_interval,
          choose_flag: this.lastQuestionParams.content.choose_flag,

          coverage: this.lastQuestionParams.content.coverage,
          coverage2: this.lastQuestionParams.content.coverage2,
          depth: this.lastQuestionParams.content.depth,
          self_score_diff: this.lastQuestionParams.content.self_score_diff,
          marktype: this.lastQuestionParams.content.marktype,
          uncoverage: this.lastQuestionParams.content.uncoverage,
          uncoverage2: this.lastQuestionParams.content.uncoverage2,
          omrfigure: this.lastQuestionParams.content.omrfigure
        }
      }
      this.quesTitle = "新增题组"
      this.quesDrawerVisi = true
    },
    //编辑题组
    toQuestionEdit(data) {
      if (typeof data.subquestion_name === "undefined") { //编辑题组
        const quesData = this.quesData
        quesData.content.viewType = data.content.viewType
        quesData.quesid = data.question_id
        quesData.examid = data.exam_id
        quesData.subjectid = data.subject_id
        quesData.name = data.question_name
        quesData.type = data.question_type
        const areastr = JSON.stringify(data.question_area)
        quesData.area = JSON.parse(areastr)
        quesData.content.mark_mode = data.content.mark_mode
        quesData.content.full_score = data.content.full_score
        quesData.content.score_interval = data.content.score_interval
        quesData.content.limit_time = data.content.limit_time
        quesData.content.marktype = data.content.marktype
        quesData.content.omrfigure = data.content.omrfigure
        quesData.content.uncoverage = data.content.uncoverage
        quesData.content.uncoverage2 = data.content.uncoverage2
        quesData.content.coverage2 = data.content.coverage2
        quesData.content.choose_area = data.content.choose_area || []
        quesData.content.multi_choices = data.content.multi_choices || []
        quesData.content.multi_choices_area = data.content.multi_choices_area || []
        if (typeof data.content.valid_scores !== "undefined") {
          this.quesData.validScoreStr = data.content.valid_scores
        }
        if (typeof data.content.shortcuts !== "undefined")
          quesData.content.shortcuts = data.content.shortcuts
        if (typeof data.content.score_diff !== "undefined")
          quesData.content.score_diff = data.content.score_diff
        if (typeof data.content.remark_flag !== "undefined")
          quesData.content.remark_flag = data.content.remark_flag
        if (typeof data.content.remark_interval !== "undefined")
          quesData.content.remark_interval = data.content.remark_interval
        if (typeof data.content.choose_flag !== "undefined")
          quesData.content.choose_flag = data.content.choose_flag
        if (typeof data.content.coverage !== "undefined")
          quesData.content.coverage = data.content.coverage
        if (typeof data.content.depth !== "undefined")
          quesData.content.depth = data.content.depth
        if (this.$refs["quesForm"])
          this.$refs["quesForm"].clearValidate()
        this.activeQuesIdx = this.questionList.indexOf(data)
        this.subDrawerVisi = false
        this.quesDrawerVisi = true
      } else {  //编辑子题
        this.subDrawerVisi = true
        const subData = this.subData
        subData.viewType = data.viewType
        subData.subid = data.subquestion_id
        subData.quesid = data.question_id
        subData.examid = data.exam_id
        subData.subjectid = data.subject_id
        subData.num = data.subquestion_num
        subData.name = data.subquestion_name
        const areastr = JSON.stringify(data.subquestion_area)
        subData.area = JSON.parse(areastr)
        if (data.content != null) {
          subData.content.full_score = data.content.full_score
          subData.content.list = data.content.list
          if (subData.content.list && subData.content.list.length != 0) {
            // 默认选中第一个
            this.subQuestionContentListSelect = 0
          }

          if (typeof data.content.points !== "undefined")
            subData.content.points = data.content.points
          if (typeof data.content.is_multi !== "undefined")
            subData.content.is_multi = data.content.is_multi
        } else {
          subData.content.full_score = null
          subData.content.points = []
          subData.content.is_multi = false
          subData.content.list = []
        }
        if (this.$refs["subForm"])
          this.$refs["subForm"].clearValidate()
        const parent = this.$refs["quesTree"].getNode(data).parent.data
        subData.type = parent.question_type
        this.activeQuesIdx = this.questionList.indexOf(parent)
        this.activeSubquesIdx = parent.sub_question.indexOf(data)
        this.quesDrawerVisi = false
      }
    },
    getValidAreas(areas) {
      const varea = []
      areas.forEach(a => {
        const range = this.getRangeByPaperID(a.p)
        varea.push({x: a.x, y: a.y + range.thrange1, w: a.w, h: a.h, p: a.p})
      })
      return varea
    },
    //新增题组区域
    toQuestionAreaAdd() {
      this.optMode = "ques-area-add"
    },
    //保存题组
    toQuestionSave() {
      this.$refs.quesForm.validate(valid => {
        if (valid) {
          // 检查选做题区域
          if (this.quesData.content.choose_flag && 
              (!this.quesData.content.multi_choices || this.quesData.content.multi_choices.length < 2)) {
            this.$message.warning("选做题必须包含至少两个选做题标记位")
            return
          }

          // 检查是否有区域
          if (this.quesData.area.length === 0) {
            this.$message.warning("请添加题组区域")
            return
          }

          this.axios.post("/api/exam/mgeexamsubjectpapers/savesubjectquestion", this.quesData)
              .then((response) => {
                if (response.data.success) {
                  this.lastQuestionParams = this.quesData
                  this.$refs.quesDrawer.handleClose()
                  this.loadSubjectQuestion(true)
                  this.loadSubjectProp()
                }
              })
        } else
          return valid
      })
    },
    //新增子题
    toSubquestionAdd(parent) {
      this.subData = {
        subid: null,
        viewType: parent.content.viewType,
        quesid: parent.question_id,
        examid: parent.exam_id,
        subjectid: parent.subject_id,
        type: parent.question_type,
        num: null,
        name: "",
        area: [],
        content: {
          full_score: null,
          points: [],
          is_multi: false,
          list: []
        }
      }
      this.subQuestionContentListSelect = null
      this.activeQuesIdx = this.questionList.indexOf(parent)
      this.subTitle = "新增子题"
      this.subDrawerVisi = true
    },
    //保存子题
    toSubquestionSave() {
      this.$refs.subForm.validate((valid, invalidFields) => {
        if (valid) {
          this.axios.post("/api/exam/mgeexamsubjectpapers/savesubjectsubquestion", this.subData)
              .then((response) => {
                if (response.data.success) {
                  this.$refs['subDrawer'].handleClose()
                  this.loadSubjectQuestion(true)
                }
              })

        } else {
          console.log(invalidFields)
          return valid
        }
      })
    },
    //新增子题区域
    toSubquestionAreaAdd() {
      if (this.optMode === "sub-area-add") {
        this.validAreas = []
        this.optMode = ""
      } else {
        this.validAreas = this.getValidAreas(this.questionList[this.activeQuesIdx].question_area)
        this.optMode = "sub-area-add"
      }
    },
    getValidAreasByObjectType(downpoint, type) {
      if (downpoint !== undefined) {
        if (type === "subquestion_area")
          this.validAreas = this.getValidAreas(this.questionList[this.activeQuesIdx].question_area)
        else
          this.validAreas = this.getValidAreas(this.subData.area)
        let parentArea = null
        this.validAreas.forEach(area => {
          if (parentArea === null)
            if (area.x < downpoint.x && downpoint.x < area.x + area.w && area.y < downpoint.y && downpoint.y < area.y + area.h) {
              parentArea = {
                left: area.x,
                top: area.y,
                width: area.w,
                height: area.h,
                p: area.p
              }
            }
        })
        return parentArea
      } else {
        if (this.optMode === "sub-area-add") {
          this.validAreas = this.getValidAreas(this.questionList[this.activeQuesIdx].question_area)
        }
        if (this.optMode === "sub-point-add") {
          this.validAreas = this.getValidAreas(this.subData.area)

        }
      }
    },
    // 计算子问题有效区域
    calculateSubquestionOrPointValidArea() {
      this.getValidAreasByObjectType()
      let valid = false
      let top = Math.min(this.downPoint.y, this.upPoint.y)
      let left = Math.min(this.downPoint.x, this.upPoint.x)
      let width = Math.abs(this.downPoint.x - this.upPoint.x)
      let height = Math.abs(this.downPoint.y - this.upPoint.y)
      let rect = {top: top, left: left, width: width, height: height}
      this.validAreas.forEach(area => {
        if (!valid)
          if (area.x < rect.left && rect.left < area.x + area.w && area.x < rect.left + rect.width && rect.left + rect.width < area.x + area.w
              && area.y < rect.top && rect.top < area.y + area.h && area.y < rect.top + rect.height && rect.top + rect.height < area.y + area.h
          ) {
            valid = true
          }
      })
      return valid
    },
    //移除子题指定区域
    removeSubquestionArea(data) {
      const idx = this.subData.area.indexOf(data)
      this.subData.area.splice(idx, 1)
      this.removeFabricObjectByUid(data)
    },
    //新增子题打分点
    toSubquestionPointAdd() {
      if (this.optMode === "sub-point-add") {
        this.validAreas = []
        this.optMode = ""
      } else {
        this.validAreas = this.getValidAreas(this.subData.area)
        this.optMode = "sub-point-add"
      }
    },
    //移除子图指定打分点
    removeSubquestionPoint(data) {
      const idx = this.subData.content.points.indexOf(data)
      this.subData.content.points.splice(idx, 1)
      this.removeFabricObjectByUid(data)
    },
    //模板图片删除
    toPaperDel(data) {
      this.$confirm("确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.axios.post("/api/exam/mgeexamsubjectpapers/removesubjectpaper", {
          examid: data.exam_id,
          paperid: data.paper_id
        })
            .then((response) => {
              if (response.data.success) {
                this.$message({message: "模板图片删除成功", type: "success"})
                this.loadSubjectPaper()
              }
            })

      })
    },
    questionDrop(dragging, drop, type) {
      if (typeof dragging.data.subquestion_name === "undefined") {
        this.axios.post("/api/exam/mgeexamsubjectpapers/changesubjectquestion", {
          examid: this.search.examid,
          srcid: dragging.data.question_id,
          tgtid: drop.data.question_id,
          type: type
        }).then((response) => {
          if (response.data.success) {
            this.loadSubjectQuestion(true)
          }
        }).catch(() => {
          this.loadSubjectQuestion(true)
        })
      } else {
        this.axios.post("/api/exam/mgeexamsubjectpapers/changesubjectsubquestion", {
          examid: this.search.examid,
          srcid: dragging.data.subquestion_id,
          tgtid: drop.data.subquestion_id,
          type: type
        }).then((response) => {
          if (!response.data.success) {
            this.loadSubjectQuestion(true)
          }
        }).catch(() => {
          this.loadSubjectQuestion(true)
        })
      }
    },
    //是否允许题组拖拽
    questionAllowDrop(dragging, drop, type) {
      if (type === "inner")
        return false
      else
        return (typeof dragging.data.subquestion_id === "undefined" && typeof drop.data.subquestion_id === "undefined")
            || (typeof dragging.data.subquestion_id !== "undefined" && typeof drop.data.subquestion_id !== "undefined" && dragging.data.question_id === drop.data.question_id);
    },
    //是否允许区域拖拽
    areaAllowDrop(dragging, drop, type) {
      return type !== "inner"
    },
    areaDrop(dragging, drop, type) {
      if (type === "inner")
        return false
      else {
      }
      // this.drawEditQuestion()
    },
    //题组删除
    toQuestionDel(data) {
      if (typeof data.subquestion_name === "undefined") { //删除题组
        this.$confirm("确认删除题组?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(() => {
          this.axios.post("/api/exam/mgeexamsubjectpapers/delsubjectquestion", {
            examid: data.exam_id,
            quesid: data.question_id
          })
              .then((response) => {
                if (response.data.success) {
                  this.$message({message: "题组删除成功", type: "success"})
                  this.loadSubjectQuestion(true)
                  this.loadSubjectProp()
                }
              })

        })
      } else {  //删除子题
        this.$confirm("确认删除子题?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(() => {
          this.axios.post("/api/exam/mgeexamsubjectpapers/delsubjectsubquestion", {
            examid: data.exam_id,
            subid: data.subquestion_id
          })
              .then((response) => {
                if (response.data.success) {
                  this.$message({message: "子题删除成功", type: "success"})
                  this.loadSubjectQuestion(true)
                }
              })

        })
      }
    },
    //添加新快捷键
    addShortcut() {
      const ct = this.quesData.content
      if (typeof ct.shortcuts === "undefined")
        ct.shortcuts = []
      ct.shortcuts.push({key: "", score: ""})
    },
    //移除快捷键
    removeShortcut(idx) {
      const sc = this.quesData.content.shortcuts
      sc.splice(idx, 1)
    },
    //拖拽��板图片
    paperDrop(dragging, drop, type) {
      this.$confirm("确认调换?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.axios.post("/api/exam/mgeexamsubjectpapers/changesubjectpaper", {
          srcid: dragging.data.paper_id,
          tgtid: drop.data.paper_id,
          type: type,
          examid: this.search.examid,
          subjectid: this.search.subjectid
        }).then((response) => {
          if (response.data.success) {
            this.loadSubjectPaper()
          }

        }).catch(() => {
          this.loadSubjectPaper()
        })
      })
    },
    //激活批量添加答题卡客观子题
    toOrmAreaBatchAdd() {
      this.subDialogMode = "orm"
      this.subDialogTitle = "识别ORM填涂区域"
      if (this.propData.codearea.length === 0) {
        this.$message.warning("请先添加ORM区域")
      } else {
        this.subDialogVisi = true
        this.getOptionImages()
      }
    },
    //激活批量添加答题卡客观子题
    toSubquestionBatchAdd() {
      this.optObjectiveMode = ""
      this.subDialogMode = "opt"
      this.subDialogTitle = "批量添加客观子题"
      if (this.quesData.name === "") {
        this.$message.warning("题组名称不能为空！")
      }
      if (this.quesData.area.length === 0) {
        this.$message.warning("请先添加题组区域")
      } else {
        this.subDialogVisi = true
        this.getOptionImages()
      }
    }
    ,
    getOptionImages() {
      let areas = []
      let examid = "", subjectid = ""
      if (this.subDialogMode === "orm") {
        areas = this.propData.codearea
        examid = this.propData.examid
        subjectid = this.propData.subjectid
      } else {
        areas = this.quesData.area
        examid = this.quesData.examid
        subjectid = this.quesData.subjectid
      }
      this.colorDepth = this.quesData.content.depth
      this.axios.post("/api/exam/mgeexamsubjectpapers/getoptionimages", {
        examid: examid,
        subjectid: subjectid,
        areas: areas
      }).then((response) => {
        if (response.data.success) {
          let papers = response.data.result
          papers.forEach(paper => {
            let img = new Image()
            img.src = paper.img
            paper.paper_image = img
          })
          this.areaImageList = papers

          this.$nextTick(() => {
            this.optInit()
            this.subDialogData = {
              direction: "1",
              questionDirection: "1",
              startnum: 1,
              optMinBlank: 1
            }
            //拼接题组区域图片
            let tw = 0, th = 0;
            areas.forEach(area => {
              if (area.w > tw)
                tw = area.w
              th += area.h
            })
            optcvs.setWidth(tw)
            optcvs.setHeight(th)
            let ch = 0
            const arealist = []
            this.areaImageList.forEach(area => {
              let imgInstance = new fabric.Image(area.paper_image, {
                top: ch,
                left: 0,
                width: parseInt(area.w),
                height: parseInt(area.h),
                stroke: this.multiOptColor,
                strokeWidth: 2,
                hoverCursor: "auto",
                selectable: false,
                evented: false
              })
              optcvs.add(imgInstance)
              imgInstance.sendToBack()
              arealist.push({x: 0, y: ch, w: area.w, h: area.h})
              ch += parseInt(area.h)
            })
            optcvs.renderAll()
            this.calcZoomOpt()
            this.refindOptions()
          })

        }
      })
    },
    //获取题组区域内选项列表
    refindOptions() {
      this.setModel("auto")
      let areas = []
      let examid = "", subjectid = ""
      if (this.subDialogMode === "orm") {
        areas = this.propData.codearea
        examid = this.propData.examid
        subjectid = this.propData.subjectid
      } else {
        areas = this.quesData.area
        examid = this.quesData.examid
        subjectid = this.quesData.subjectid
      }

      this.objectiveOptionList = []
      this.axios.post("/api/exam/mgeexamsubjectpapers/getoptionpos", {
        examid: examid,
        subjectid: subjectid,
        areas: areas,
        minblank: this.subDialogData.optMinBlank,
        depth: this.colorDepth
      })
          .then((response) => {
            if (response.data.success) {
              const poslist = response.data.result
              const optlist = []
              let curheight = 0
              areas.forEach((area, aidx) => {
                const areapos = poslist[aidx]
                if (areapos != null) {
                  areapos.forEach(pos => {
                    const opt = {
                      x: pos.x,
                      y: pos.y + curheight,
                      w: pos.w,
                      h: pos.h,
                      p: area.p,
                      ox: pos.x + area.x,
                      oy: pos.y + area.y,
                    }
                    optlist.push(opt)
                  })
                }
                curheight += area.h
              })
              this.objectiveOptionList = optlist
              this.renderObjectiveOption()
            }
          })
    }
    ,
    renderObjectiveOption() {

      this.optcvsRemoveObjectByType("opt_subquestion_area")
      this.optcvsRemoveObjectByType("subquestion_area_mark")
      // if (this.subDialogMode!=="orm")
      this.objectiveOptionList.forEach(area => {
        let color = this.optColor
        if (area.f === true)
          color = this.firstOptColor
        if (area.m === true)
          color = this.multiOptColor
        this.drawOptFabricGroup2(area, "opt_subquestion_area", "subquestion_area_mark", color, "", -1)
        if (area.sq !== undefined)
          this.drawSubNum(area, "subquestion_area_mark", this.subDialogData.direction)


      })
      optcvs.getObjects().forEach(object => {
        object.selectable = false
        object.hoverCursor = "default"
      })
    },

    renderPersonObjectiveOption() {
      this.optcvsRemoveObjectByType("opt_subquestion_area")
      this.optcvsRemoveObjectByType("subquestion_area_mark")
      this.objectiveOptionList.forEach(area => {
        let color = this.optColor
        if (area.f === true)
          color = this.firstOptColor
        if (area.m === true)
          color = this.multiOptColor
        this.drawOptFabricGroup2(area, "opt_subquestion_area", "subquestion_area_mark", color, "", -1)
        if (area.sq !== undefined)
          this.drawSubNum(area, "subquestion_area_mark", this.subDialogData.direction)
      })


      optcvs.getObjects().forEach(object => {
        object.selectable = false
        object.hoverCursor = "default"
      })
    },


    toOptionColSet() {
      this.optObjectiveMode = ""
      this.optRecognizeMode = "person"


      this.isOptionGenerate = false
      if (this.optRecognizeMode === "col")
        this.optRecognizeMode = "person"
      else
        this.optRecognizeMode = "col"
    },

    toOptionRowSet() {
      this.optObjectiveMode = ""
      if (this.colPointList.length === 0) {
        this.$message.warning("请先设置列选项")
      } else {
        this.isOptionGenerate = false

        if (this.optRecognizeMode === "row")
          this.optRecognizeMode = "person"
        else
          this.optRecognizeMode = "row"

      }

    },
    //切换到移除选项列模式
    toOptionColRemove() {
      if (this.optObjectiveMode !== "col-remove")
        this.optObjectiveMode = "col-remove"
      else
        this.optObjectiveMode = ""
    },
    //切换到移除选项行模式
    toOptionRowRemove() {
      if (this.optObjectiveMode !== "row-remove")
        this.optObjectiveMode = "row-remove"
      else
        this.optObjectiveMode = ""
    },
    //切换到设置首选项模式
    toFirstOptSwitch() {
      if (this.optRecognizeMode !== "auto") {
        this.optRecognizeMode = "person"
      }
      if (this.optRecognizeMode !== "auto") {
        if (!this.isOptionGenerate) {
          this.$message.warning("人工设置模式下请先生成选项后再进行该操作")
          return
        }

      }

      if (this.optObjectiveMode !== "first-opt-switch")
        this.optObjectiveMode = "first-opt-switch"
      else
        this.optObjectiveMode = ""
    },

    toResetColAndRow() {
      this.isOptionGenerate = false
      this.optRecognizeMode = "person"
      this.rowPointList = []
      this.colPointList = []
      this.objectiveOptionList = []
      this.renderPersonObjectiveOption()
    },
    toGenerateOptionArea() {
      this.optRecognizeMode = "person"
      this.isOptionGenerate = true
      this.objectiveOptionList = this.generateGridFromRowCol(this.rowPointList, this.colPointList)
      this.renderPersonObjectiveOption()
    },
    generateGridFromRowCol(rowPointList, colPointList) {
      const grid = [];
      const area = (this.subDialogMode === "orm") ? this.propData.codearea[0] : this.quesData.area[0]
      rowPointList.forEach((rowPoint) => {
        colPointList.forEach((colPoint) => {
          // 计算每个矩形的中心点，并生成矩形
          const rect = {
            x: Math.round(rowPoint.x + rowPoint.w / 2 - this.rectWidth / 2),
            y: Math.round(colPoint.y + colPoint.h / 2 - this.rectHeight / 2),
            w: Math.round(this.rectWidth),
            h: Math.round(this.rectHeight),
            uid: `grid-${rowPoint.uid}-${colPoint.uid}`,
            p: area.p,
            ox: Math.round(rowPoint.x + rowPoint.w / 2 - this.rectWidth / 2 + area.x),
            oy: Math.round(colPoint.y + colPoint.h / 2 - this.rectHeight / 2 + area.y)
          };

          // 将生成的矩形添加到网格中
          grid.push(rect);
        });
      });
      //列的第一个点也是行的第一个点，需要再这里加上
      colPointList.forEach((colPoint) => {
        // 计算每个矩形的中心点，并生成矩形
        const rect = {
          x: Math.round(colPoint.x + colPoint.w / 2 - this.rectWidth / 2),
          y: Math.round(colPoint.y + colPoint.h / 2 - this.rectHeight / 2),
          w: Math.round(this.rectWidth),
          h: Math.round(this.rectHeight),
          uid: `grid-${colPoint.uid}-${colPoint.uid}`,
          p: area.p,
          ox: Math.round(colPoint.x + colPoint.w / 2 - this.rectWidth / 2 + area.x),
          oy: Math.round(colPoint.y + colPoint.h / 2 - this.rectHeight / 2 + area.y)
        };

        // 将生成的矩形添加到网格中
        grid.push(rect);
      });
      return grid;
    }
    ,
    //切换到设置多选题模式
    toSetMultiOpt() {

      if (this.optRecognizeMode !== "auto") {
        this.optRecognizeMode = "person"
      }
      if (this.optRecognizeMode !== "auto") {
        if (!this.isOptionGenerate) {
          this.$message.warning("人工设置模式下请先生成选项后再进行该操作")
          return
        }

      }

      if (this.optObjectiveMode !== "multi-opt-switch")
        this.optObjectiveMode = "multi-opt-switch"
      else
        this.optObjectiveMode = ""
    },
    //生成子题号
    generateSubNum() {
      let isFirstSet = false
      const firstList = []
      const epsilonH = this.objectiveOptionList[0].h / 2; // 定义一个容差值，例如 5 个像素
      const epsilonW = this.objectiveOptionList[0].w / 2; // 定义一个容差值，例如 5 个像素

      this.objectiveOptionList.sort((a, b) => {
        if (Math.abs(a.y - b.y) <= epsilonH) {
          return a.x - b.x; // 如果 y 坐标差异在容差范围内，则按 x 排序
        }
        return a.y - b.y; // 否则按 y 排序
      });


      this.objectiveOptionList.forEach(option => {
        option.direction = this.subDialogData.direction
        if (typeof option.f !== "undefined") {
          isFirstSet = true
          firstList.push(option)
        }
        if (typeof option.sq !== "undefined") {
          delete option.sq
        }
      })
      if (isFirstSet) {
        //首选项排序
        if (this.subDialogData.direction === "1") { //横向
          firstList.sort((a, b) => {
            if (Math.abs(a.y - b.y) < epsilonH)
              return a.x - b.x
            else
              return a.y - b.y

          })
        } else {
          firstList.sort((a, b) => {  //纵向
            if (Math.abs(a.x - b.x) < epsilonW)
              return a.y - b.y
            else
              return a.x - b.x

          })
        }
        let n = this.subDialogData.startnum
        console.log("firstList", firstList)
        firstList.forEach(opt => {
          opt.sq = n
          n++
        })
        this.renderObjectiveOption()
      } else
        this.$message.warning("请先设置首选项")
    },
    //关闭添加子题对话框
    closeBatchOptionDialog() {
      this.subDialogVisi = false
    },
    //客观题画布鼠标抬起事件
    objectiveMouseUp(ev) {
      const mpos = optcvs.getPointer(ev.e)
      const aidx = this.findPosAreaIndex(mpos)
      if (this.optObjectiveMode === "col-remove") {
        this.objectiveOptionList = this.objectiveOptionList.filter(opt => {
          const idx = this.findPosAreaIndex(opt)
          return opt.x > mpos.x || opt.x + opt.w < mpos.x || aidx !== idx
        })
        this.renderObjectiveOption()
      } else if (this.optObjectiveMode === "row-remove") {
        this.objectiveOptionList = this.objectiveOptionList.filter(opt => {
          const idx = this.findPosAreaIndex(opt)
          return opt.y > mpos.y || opt.y + opt.h < mpos.y || aidx !== idx
        })
        this.renderObjectiveOption()
      } else if (this.optObjectiveMode === "first-opt-switch") {
        const opt = this.findPosOption(mpos)
        if (opt != null) {
          const first = opt.f
          if (typeof first === "undefined")
            opt["f"] = true
          else {
            delete opt.f
            delete opt.sq
          }

          this.objectiveOptionList.forEach(option => {
            const idx = this.findPosAreaIndex(option)
            if (this.subDialogData.questionDirection === "1") { // 横向
              // 同一列(x坐标相近)的选项
              if (Math.abs(option.x - opt.x) <= opt.w / 2 && aidx === idx) {
                if (typeof option.f === "undefined") {
                  if (typeof first === "undefined")
                    option["f"] = true
                } else if (typeof first !== "undefined") {
                  delete option.f
                  delete option.sq
                }
              }
            } else if (this.subDialogData.questionDirection === "2") { // 纵向
              // 同一行(y坐标相近)的选项
              if (Math.abs(option.y - opt.y) <= opt.h / 2 && aidx === idx) {
                if (typeof option.f === "undefined") {
                  if (typeof first === "undefined")
                    option["f"] = true
                } else if (typeof first !== "undefined") {
                  delete option.f
                  delete option.sq
                }
              }
            }
          })

          this.renderObjectiveOption()
        }
      }else if (this.optObjectiveMode === "multi-opt-switch") {
          const opt = this.findPosOption(mpos)
          if (opt != null) {
            if (typeof opt.f !== "undefined") {
              const ismulti = opt.m
              if (typeof ismulti === "undefined")
                opt["m"] = true
              else
                delete opt.m
              this.renderObjectiveOption()
            } else {
              this.$message.warning("请点击多选选项")
            }
          }
        }
    }
    ,
    //查找指定位置在客观题区域列表中的索引
    findPosAreaIndex(mpos) {
      let areaIdx = -1
      this.objectiveAreaList.forEach((area, idx) => {
        if (mpos.y >= area.y && mpos.y < area.y + area.h)
          areaIdx = idx
      })
      return areaIdx
    },
    //查找选中客观题选项
    findPosOption(mpos) {
      let find = null
      this.objectiveOptionList.forEach(option => {
        if (mpos.x >= option.x && mpos.x <= option.x + option.w && mpos.y >= option.y && mpos.y <= option.y + option.h)
          find = option
      })
      return find
    },
    //批量保存客观子题
    batchAddSubquestions() {
      const params = this.quesData;
      const epsilonH = this.objectiveOptionList[0].h / 2;
      const epsilonW = this.objectiveOptionList[0].w / 2;

      // 根据题目方向对选项进行排序
      if (this.subDialogData.questionDirection === "1") { // 横向题目排列
        this.objectiveOptionList.sort((a, b) => {
          if (Math.abs(a.y - b.y) <= epsilonH) {
            return a.x - b.x; // 同一行按x坐标排序
          }
          return a.y - b.y; // 不同行按y坐标排序
        });
      } else { // 纵向题目排列
        this.objectiveOptionList.sort((a, b) => {
          if (Math.abs(a.x - b.x) <= epsilonW) {
            return a.y - b.y; // 同一列按y坐标排��
          }
          return a.x - b.x; // 不同列按x坐标排序
        });
      }

      // 找出所有首选项
      const firstOptions = this.objectiveOptionList.filter(opt => typeof opt.f !== "undefined");

      // 生成子题列表
      const subquesList = [];
      
      // 先检查所有首选项是否都有子题号
      for (let firstOpt of firstOptions) {
        if (firstOpt.sq === undefined) {
          this.$message.warning('请先生成子题号再保存')
          return; // 直接返回，结束整个方法
        }
      }

      // 如果所有首选项都有子题号，再继续处理
      firstOptions.forEach(firstOpt => {
        // 根据题目方向筛选关联选项
        const relatedOptions = this.objectiveOptionList.filter(opt => {
          if (this.subDialogData.questionDirection === "1") { // 横向题目
            // 找到同一行中的下一个首选项（如果存在）
            const nextFirst = this.objectiveOptionList.find(next =>
                typeof next.f !== "undefined" &&
                Math.abs(next.y - firstOpt.y) <= epsilonH &&
                next.x > firstOpt.x
            );

            // 选择同一行的选项，直到下一个首选项（不包含）或行尾
            return Math.abs(opt.y - firstOpt.y) <= epsilonH &&
                opt.x >= firstOpt.x &&
                (!nextFirst || opt.x < nextFirst.x);
          } else { // 纵向题目
            // 找到同一列中的下一个首选项（如果存在）
            const nextFirst = this.objectiveOptionList.find(next =>
                typeof next.f !== "undefined" &&
                Math.abs(next.x - firstOpt.x) <= epsilonW &&
                next.y > firstOpt.y
            );

            // 选择同一列的选项，直到下一个首选项（不包含）或列尾
            return Math.abs(opt.x - firstOpt.x) <= epsilonW &&
                opt.y >= firstOpt.y &&
                (!nextFirst || opt.y < nextFirst.y);
          }
        });
        // 创建子题对象
        const subques = {
          sq: firstOpt.sq,
          areas: relatedOptions.map(opt => ({
            x: opt.ox,
            y: opt.oy,
            w: opt.w,
            h: opt.h,
            p: opt.p
          })),
          content: {
            full_score: 0,
            is_multi: typeof firstOpt.m !== "undefined"
          }
        };
        subquesList.push(subques);
      });

      // 根据显示方向排序
      if (this.subDialogData.direction === "2") { // 纵向显示
        subquesList.sort((a, b) => a.sq - b.sq);
      }

      params.subques = subquesList;
      //params.direction后端用来判断选项的排序类型
      params.direction = this.subDialogData.questionDirection;

      // 处理不同模式
      if (this.subDialogMode === "orm") {
        const ormAreaList = this.objectiveOptionList.map(opt => ({
          x: opt.ox,
          y: opt.oy,
          w: opt.w,
          h: opt.h,
          p: opt.p,
          uid: this.uuid(),
          object_type: "orm"
        }));

        this.removeFabricObjectByType(["orm_subquestion_area", "orm_subquestion_area_mark"]);
        this.propData.ormarea = ormAreaList;
        this.subDialogVisi = false;
      } else {
        // 发送请求保存子题
        this.axios.post("/api/exam/mgeexamsubjectpapers/batchaddsubquestion", params)
            .then((response) => {
              if (response.data.success) {
                this.subDialogVisi = false;
                if (this.quesDrawerVisi === true) {
                  this.quesDrawerVisi = false;
                }
                this.loadSubjectQuestion(true);
              }
            });
      }
    },
    deleteOrmArea() {
      this.propData.ormarea.forEach(area => {
        this.removeFabricObjectByUid(area)
      })
      this.propData.ormarea = []
    },
    setModel(model) {
      this.optRecognizeMode = model
      this.objectiveOptionList = []
      this.rowPointList = []
      this.colPointList = []
      this.renderPersonObjectiveOption()
    },
    toScannerView() {
      // 打开包含扫描组件的对话框
      this.scannerDialogVisible = true
    },
    handleExpandModeChange(value) {
      this.expandMode = value
      this.updateExpandedKeys()
    },
    updateExpandedKeys() {
      const keys = []
      const processNode = (node) => {
        if (!node) return
        // 根据不同模式决定是否展开
        if (this.expandMode === '1') {
          // 仅展开主观题
          if (node.question_type === '2') {
            keys.push(node.uid)
          }else{
          }
        } else if (this.expandMode === '2') {
          // 展开所有
          keys.push(node.uid)
        }else{
        }
        // expandMode === '3' 时不添加任何key，实现全部收起
      }

      // 处理所有题组
      this.questionList.forEach(processNode)

      // 更新expandedKeys
      this.expandedKeys = keys
      // 要让tree组件重新渲染，否则default-expanded-keys不生效
      this.treeExist = false
      this.$nextTick(() => {
        this.treeExist = true
      })

    },
    // 下载base64图片
    downloadBase64Image(base64Data, fileName = 'image.png') {
      try {
        // 移除 base64 头部信息（如果存在）
        const base64Content = base64Data.includes('base64,') 
          ? base64Data.split('base64,')[1] 
          : base64Data;

        // 将 base64 转换为 Blob
        const byteCharacters = atob(base64Content);
        const byteNumbers = new Array(byteCharacters.length);
        
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'image/png' });

        // 创建下载链接
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = fileName;
        
        // 触发下载
        document.body.appendChild(link);
        link.click();
        
        // 清理
        document.body.removeChild(link);
        URL.revokeObjectURL(link.href);
      } catch (error) {
        console.error('下载图片失败:', error);
        this.$message.error('下载图片失败');
      }
    },
    // 打开子科目配置对话框
    openSubSubjectDialog() {
      // 创建副本，避免直接修改原数据
      this.subSubjectDialogVisible = true
    },

    // 新增子科目
    addSubSubject() {
      this.propData.sub_prop.push({
        name: `子科目${this.propData.sub_prop.length + 1}`,
        questionIds: []
      })
    },

    // 移除子科目
    removeSubSubject(index) {
      this.propData.sub_prop.splice(index, 1)
    },

    // 保存子科目配置
    saveSubSubjects() {
      // 可以在这里添加校验逻辑
      const hasEmptyName = this.propData.sub_prop.some(item => !item.name.trim())
      
      if (hasEmptyName) {
        this.$message.warning('请填写所有子科目名称')
        return
      }
      this.toPropSave()
      // 关闭对话框
      this.subSubjectDialogVisible = false
    },
    // 添加选做题区域的方法
    toChooseAreaAdd() {
      if(this.quesData.content.choose_flag) {
        this.optMode = "choose-area-add"
      } else {
        this.$message.warning("请先开启选做题功能")
      }
    },
    // 在 methods 中添加
    removeChooseArea(data) {
      const idx = this.quesData.content.choose_area.findIndex(area => area.uid === data.uid)
      if (idx > -1) {
        this.quesData.content.choose_area.splice(idx, 1)
        this.removeFabricObjectByUid(data)
      }
    }
  },

}
</script>

<style scoped>
/* 主容器样式优化 */
.base-container {
  height: calc(100% - 100px);
}

/* 缩放控制区样式优化 */
.zoom-control {
  position: fixed;
  top: 140px;
  left: 20px;
  z-index: 100;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;

  
  .zoom-value {
    min-width: 50px;
    text-align: center;
    color: #606266;
  }
}

/* 树形控件样式优化 */
.paper-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 8px 0;
  

  
  .tree-button {
    padding: 4px;
    margin-left: 4px;
  }
}

/* 区域选中样式优化 */
.area-select {
  color: var(--el-color-primary);
  font-weight: bold;
  cursor: pointer;

}

/* 标签页样式优化 */
.full-height-tabs {
  height: 100%;
  display: flex;
  flex-direction: column;
}

/* 滚动面板样式 */
.scrollable-tab-pane {
  height: 100%;
  overflow: auto;
  padding: 20px;
  
  &::-webkit-scrollbar {
    width: 6px;
  }
  
  &::-webkit-scrollbar-thumb {
    background: #dcdfe6;
    border-radius: 3px;
  }
}

/* 展开模式选择器样式 */
.expand-mode-select {
  display: flex;
  align-items: center;
  gap: 8px;
  
  .expand-mode-label {
    color: #606266;
    font-size: 14px;
  }
}

.config-aside {
  border-left: 1px solid #e6e6e6;
  background: #fff;
  box-shadow: -2px 0 8px rgba(0, 0, 0, 0.05);
  padding: 0;
  
  :deep(.el-tabs) {
    height: 100%;
    
    .el-tabs__header {
      margin: 0;
      padding: 10px 10px 0;
      background: #f5f7fa;
      border-bottom: 1px solid #e4e7ed;
    }
    
    .el-tabs__content {
      padding: 15px;
    }
  }
  
  :deep(.el-form) {
    .el-form-item {
      margin-bottom: 18px;
      
      &:last-child {
        margin-bottom: 0;
      }
      
      .el-form-item__label {
        font-weight: 500;
        color: #606266;
      }
      
      .el-form-item__content {
        .el-input,
        .el-select,
        .el-radio-group {
          width: 100%;
        }
        
        .el-button {
          margin: 0 8px 8px 0;
        }
      }
    }
  }
  
  :deep(.el-tree) {
    margin: 8px 0;
    background: transparent;
    
    .el-tree-node__content {
      height: 32px;
      border-radius: 4px;
      
      &:hover {
        background-color: #f5f7fa;
      }
    }
  }
  
  :deep(.el-divider) {
    margin: 16px 0;
  }
  
  :deep(.el-upload) {
    width: 80%;  /* 修改宽度为80% */
    margin: 0 auto;  /* 居中显示 */
    text-align: center;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    padding: 20px;
    margin-bottom: 10px;
    
    &:hover {
      border-color: var(--el-color-primary);
    }
    
    .el-upload__tip {
      color: #909399;
      margin-top: 8px;
    }
  }
}

/* 响应式调整 */
@media screen and (max-width: 1366px) {
  .config-aside {
    width: 400px;
  }
}

@media screen and (max-width: 1200px) {
  .config-aside {
    width: 350px;
  }
}

.sub-subject-config {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.sub-subject-header {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}




</style>

<!-- 全局样式 -->
<style>
/* 控制popper组件宽度 */
.el-popper{
  max-width: 230px ;
}
</style>
