<template>
  <div class="custom-input-container">
    <div class="custom-input-wrapper" :class="{ 'focus': isFocused, 'error': hasError }">
      <i v-if="icon" :class="icon" class="input-icon"></i>
      <input
        ref="input"
        :type="type"
        :value="modelValue"
        @input="handleInput"
        :placeholder="placeholder"
        @focus="handleFocus"
        @blur="handleBlur"
        @keyup.enter="$emit('enter')"
        class="custom-input"
        :autocomplete="$props.type"
      />
    </div>
    <span v-if="hasError" class="error-message">{{ errorMessage }}</span>
  </div>
</template>

<script>
export default {
  name: 'CustomInput',
  props: {
    modelValue: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    placeholder: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    errorMessage: {
      type: String,
      default: ''
    },
    hasError: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue', 'enter'],
  data() {
    return {
      isFocused: false
    }
  },
  methods: {
    handleInput(e) {
      this.$emit('update:modelValue', e.target.value);
    },
    handleFocus() {
      this.isFocused = true;
    },
    handleBlur() {
      this.isFocused = false;
    }
  }
}
</script>

<style scoped>
.custom-input-container {
  width: 100%;
  margin-bottom: 16px;
}

.custom-input-wrapper {
  display: flex;
  align-items: center;
  background-color: #f8f9fa;
  border: 1px solid #dcdfe6;
  border-radius: 8px;
  padding: 0 12px;
  height: 48px;
  transition: all 0.3s ease;
  overflow: hidden;
}

.custom-input-wrapper:hover {
  border-color: #2196f3;
}

.custom-input-wrapper.focus {
  border-color: #2196f3;
  box-shadow: 0 0 0 2px rgba(33, 150, 243, 0.2);
  background-color: #fff;
}

.custom-input-wrapper.error {
  border-color: #f44336;
}

.input-icon {
  color: #909399;
  font-size: 18px;
  margin-right: 8px;
}

.custom-input {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  background: transparent;
  color: #333;
  font-size: 16px;
  padding: 0;
}

.error-message {
  display: block;
  font-size: 12px;
  color: #f44336;
  margin-top: 4px;
}
</style> 