<script>
import { useAppStoreWithOut } from "@/store/modules/app";

export default {
  name: "ScannerViewRealTimeAnswerDialog",
  components: { },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    studentId: {
      type: String,
      default: ""
    },
    frontImage: {
      type: String,
      default: ""
    },
    backImage: {
      type: String,
      default: ""
    },
    page1Res: {
      type: Array,
      default: () => []
    },
    page2Res: {
      type: Array,
      default: () => []
    },
    questionInfo: {
      type: Object,
      default: () => ({
        questions: [],
        subQuestions: []
      })
    }
  },
  setup() {
    const appStore = useAppStoreWithOut();
    return { appStore };
  },
  data() {
    return {
      activeTab: "page1",
      currentPage: "1", // 当前查看的页面：1或2
      tableSelectNum: "", // 当前选中的题号
      paperCanvasLines: [], // 画布上的线条
      alphabets: "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
      showAll: true, // 默认看全卷
      paperHeight: 0, // 试卷高度
      imageLoaded: false, // 图片是否已加载
      imageError: false, // 图片加载失败
      loadingImage: false, // 正在加载图片
      
      // 图片操作相关
      scale: 1, // 缩放比例
      rotation: 0, // 旋转角度
      isDragging: false, // 是否正在拖动
      startX: 0,
      startY: 0,
      translateX: 0,
      translateY: 0
    };
  },
  mounted() {
    // 设置默认的试卷高度，可以根据实际情况调整
    this.paperHeight = 1200;
    // 加载默认图片
    this.loadImage();
    // 添加键盘事件监听
    window.addEventListener('keydown', this.handleKeyPress);
  },
  unmounted() {
    // 清理键盘事件监听
    window.removeEventListener('keydown', this.handleKeyPress);
  },
  watch: {
    visible(val) {
      if (val) {
        // 对话框打开时，重置状态
        this.activeTab = "page1";
        this.currentPage = "1";
        this.imageLoaded = false;
        this.imageError = false;
        this.loadingImage = true; // 设置为加载中状态
        this.paperCanvasLines = [];
        this.tableSelectNum = "";
        
        // 延迟加载以确保DOM已更新和对话框完全打开
        setTimeout(() => {
          this.loadImage();
          this.selectFirstQuestion();
        }, 300);
      }
    },
    currentPage() {
      // 页面切换时重新加载图片
      this.$nextTick(() => {
        this.loadImage();
      });
    }
  },
  computed: {
    // 获取当前页的答案列表
    currentPageAnswers() {
      return this.currentPage === "1" ? this.page1Res : this.page2Res;
    },
    // 获取当前显示的图片
    currentImage() {
      return this.currentPage === "1" ? this.frontImage : this.backImage;
    },
    // 计算题目区域，用于在试卷上显示框图
    questionArea() {
      const res = [];
      if (!this.showAll) {
        return res;
      }

      // 添加题组区域
      this.questionInfo.questions.forEach(q => {
        q.question_area.forEach(item_ => {
          const item = { ...item_ };
          item.width = item.w;
          item.height = item.h;
          if (item.p == 1) {
            item.y += parseInt(this.paperHeight);
          }
          res.push({
            ...item,
            fullScore: q.content.full_score,
            subQuestionName: q.question_name,
            subQuestionId: q.question_id
          });
        });
      });

      // 添加选中的子题区域
      if (this.tableSelectNum) {
        const subQuestion = this.questionInfo.subQuestions.find(
          q => q.subquestion_num == this.tableSelectNum && 
               (parseInt(this.currentPage) - 1) == q.subquestion_area[0].p
        );
        
        if (subQuestion) {
          subQuestion.subquestion_area.forEach((item_, index) => {
            if ((parseInt(this.currentPage) - 1) == item_.p) {
              const item = { ...item_ };
              item.width = item.w;
              item.height = item.h;
              if (item.p == 1) {
                item.y += parseInt(this.paperHeight);
              }
              res.push({
                ...item,
                fullScore: subQuestion.content.full_score,
                subQuestionName: subQuestion.subquestion_num + "",
                subQuestionId: subQuestion.subquestion_id + "-" + index,
                color: "red"
              });
            }
          });
        }
      }
      
      return res;
    }
  },
  methods: {
    // 自定义表格行类名
    rowClassName({ row }) {
      return row.subnum === this.tableSelectNum ? 'current-row' : '';
    },
    
    // 重置组件状态
    resetComponent() {
      this.imageLoaded = false;
      this.imageError = false;
      this.paperCanvasLines = [];
      this.tableSelectNum = "";
      
      // 延迟加载以确保DOM已更新
      this.$nextTick(() => {
        this.loadImage();
        // 默认选中第一题
        this.selectFirstQuestion();
      });
    },
    
    // 默认选中第一题
    selectFirstQuestion() {
      this.$nextTick(() => {
        const currentAnswers = this.currentPage === "1" ? this.page1Res : this.page2Res;
        if (currentAnswers && currentAnswers.length > 0) {
          // 默认选中第一题
          this.answerRowClick(currentAnswers[0]);
          
          // 设置表格当前行
          const tableRef = this.currentPage === "1" ? "answerTable1" : "answerTable2";
          if (this.$refs[tableRef]) {
            this.$refs[tableRef].setCurrentRow(currentAnswers[0]);
          }
        }
      });
    },
    
    // 关闭对话框
    handleClose() {
      this.$emit("update:visible", false);
    },
    
    // 切换页面
    switchPage(page) {
      this.currentPage = page;
      this.paperCanvasLines = [];
      this.tableSelectNum = "";
      
      // 延迟加载以确保DOM已更新
      this.$nextTick(() => {
        this.loadImage();
        // 切换后默认选中第一题
        this.selectFirstQuestion();
      });
    },
    
    // 点击答案行
    answerRowClick(row) {
    /* 试卷是未对齐的，框选肯定错位 */
      return;
      this.tableSelectNum = row.subnum;
      
      // 查找对应的子题
      const subQuestion = this.questionInfo.subQuestions.find(
        q => q.subquestion_num == row.subnum && 
             (parseInt(this.currentPage) - 1) == q.subquestion_area[0].p
      );
      
      if (!subQuestion) {
        console.warn("未找到对应的子题信息");
        return;
      }
      
      // 查找对应的题组
      const questionId = subQuestion.question_id;
      const question = this.questionInfo.questions.find(q => q.question_id == questionId);
      
      if (!question) {
        console.warn("未找到对应的题组信息");
        return;
      }
      
      const questionArea = question.question_area[0];
      const questionX = questionArea.x;
      const questionY = questionArea.y;
      
      // 计算高度偏移
      let questionHeight = 0;
      if (questionArea.p == 1) {
        questionHeight = this.paperHeight;
      }
      
      const subquestionAreas = subQuestion.subquestion_area;
      
      // 计算选项区域的边界
      let minX = 0;
      let maxX = 0;
      subquestionAreas.forEach(area => {
        if (minX == 0 || area.x < minX) {
          minX = area.x;
        }
        if (maxX == 0 || area.x > maxX) {
          maxX = area.x;
        }
      });
      
      const offsetY = subquestionAreas[0].h + 5;
      const offsetWidth = subquestionAreas[0].w;
      const width = maxX - minX;
      const y = subquestionAreas[0].y;
      
      // 创建线条对象
      const line = {
        x: minX,
        y: y + questionHeight + offsetY,
        width: width + offsetWidth
      };
      
      this.paperCanvasLines = [line];
      
      // 设置滚动条位置
      this.$nextTick(() => {
        if (this.$refs.paperCanvas) {
          this.$refs.paperCanvas.setScrollLeft(line.x - 300);
          this.$refs.paperCanvas.setScrollTop(line.y - 300);
        }
      });
    },
    
    // 获取答案选项的标签
    getAnswerLabel(answer) {
      if (!answer) return "空";
      return answer;
    },
    
    // 获取答案类型的标签样式
    getAnswerType(answer) {
      if (!answer) return "warning";
      return "success";
    },

    // 处理键盘事件
    handleKeyPress(e) {
      if (!this.visible) return;
      
      switch(e.key) {
        case '+': // 放大
        case '=': // 放大（不需要按Shift的+）
          this.zoomIn();
          e.preventDefault();
          break;
        case '-': // 缩小
          this.zoomOut();
          e.preventDefault();
          break;
        case 'r': // 旋转
        case 'R':
          this.rotate();
          e.preventDefault();
          break;
        case 'Escape': // ESC键关闭对话框
          this.handleClose();
          e.preventDefault();
          break;
        case '0': // 重置图片
          this.resetImage();
          e.preventDefault();
          break;
      }
    },

    // 加载图片
    loadImage() {
      if (!this.currentImage) {
        this.imageLoaded = false;
        this.imageError = true;
        this.loadingImage = false;
        console.error("无图片数据");
        return;
      }
      
      // 设置加载状态
      this.loadingImage = true;
      
      // 重置状态
      this.imageLoaded = false;
      this.imageError = false;
      
      // 预加载图片验证
      const img = new Image();
      img.onload = () => {
        this.imageLoaded = true;
        this.loadingImage = false;
        // 重置图片变换
        this.resetImage();
        console.log("图片已成功加载，尺寸:", img.width, "x", img.height);
      };
      
      img.onerror = (err) => {
        this.imageError = true;
        this.loadingImage = false;
        console.error("图片加载失败:", err);
      };
      
      // 尝试加载图片
      img.src = this.currentImage;
    },
    
    // 放大图片
    zoomIn() {
      this.scale += 0.1;
    },
    
    // 缩小图片
    zoomOut() {
      if (this.scale > 0.2) {
        this.scale -= 0.1;
      }
    },
    
    // 旋转图片
    rotate() {
      this.rotation = (this.rotation + 90) % 360;
    },
    
    // 重置图片
    resetImage() {
      this.scale = 1;
      this.rotation = 0;
      this.translateX = 0;
      this.translateY = 0;
    },
    
    // 处理鼠标按下事件，准备拖动
    handleMouseDown(e) {
      if (e.button !== 0) return; // 只处理左键点击
      this.isDragging = true;
      this.startX = e.clientX;
      this.startY = e.clientY;
      
      // 添加鼠标移动和松开事件监听
      document.addEventListener('mousemove', this.handleMouseMove);
      document.addEventListener('mouseup', this.handleMouseUp);
      
      // 阻止默认行为和冒泡
      e.preventDefault();
      e.stopPropagation();
    },
    
    // 处理鼠标移动事件，实现拖动
    handleMouseMove(e) {
      if (!this.isDragging) return;
      
      const deltaX = e.clientX - this.startX;
      const deltaY = e.clientY - this.startY;
      
      this.translateX += deltaX;
      this.translateY += deltaY;
      
      this.startX = e.clientX;
      this.startY = e.clientY;
    },
    
    // 处理鼠标松开事件，结束拖动
    handleMouseUp() {
      this.isDragging = false;
      
      // 移除事件监听
      document.removeEventListener('mousemove', this.handleMouseMove);
      document.removeEventListener('mouseup', this.handleMouseUp);
    }
  }
};
</script>

<template>
  <el-dialog
    :model-value="visible"
    @update:model-value="handleClose"
    title="试卷答案详情"
    width="90%"
    :before-close="handleClose"
    destroy-on-close
  >
    <el-container style="height: 70vh">
      <el-aside width="250px" style="height: 100%; border-right: 1px solid #eee">
        <el-tabs v-model="activeTab" @tab-change="switchPage">
          <el-tab-pane label="正面" name="page1" v-if="frontImage && page1Res && page1Res.length > 0">
            <el-table
              ref="answerTable1"
              :data="page1Res"
              style="width: 100%; height: calc(70vh - 70px)"
              row-key="subnum"
              :current-row-key="tableSelectNum"
              border
              highlight-current-row
              @row-click="answerRowClick"
              :row-class-name="rowClassName"
            >
              <el-table-column prop="subnum" label="题号" width="60" />
              <el-table-column prop="answers" label="答案">
                <template #default="{ row }">
                  <el-tag :type="getAnswerType(row.answers)" size="small">
                    {{ getAnswerLabel(row.answers) }}
                  </el-tag>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane label="背面" name="page2" v-if="backImage && page2Res && page2Res.length > 0">
            <el-table
              ref="answerTable2"
              :data="page2Res"
              style="width: 100%; height: calc(70vh - 70px)"
              row-key="subnum"
              :current-row-key="tableSelectNum"
              border
              highlight-current-row
              @row-click="answerRowClick"
              :row-class-name="rowClassName"
            >
              <el-table-column prop="subnum" label="题号" width="60" />
              <el-table-column prop="answers" label="答案">
                <template #default="{ row }">
                  <el-tag :type="getAnswerType(row.answers)" size="small">
                    {{ getAnswerLabel(row.answers) }}
                  </el-tag>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
        </el-tabs>
      </el-aside>
      <el-main style="height: 100%; padding: 0; overflow: hidden; position: relative;">
        <div v-if="imageError" class="empty-image">
          <el-empty description="图片加载失败" />
        </div>
        <div v-else-if="loadingImage" class="empty-image">
          <el-icon class="is-loading"><Loading /></el-icon>
          <span style="margin-left: 8px">加载中...</span>
        </div>
        <div 
          v-else-if="currentImage && imageLoaded" 
          class="image-container"
          @mousedown="handleMouseDown"
        >
          <img 
            :src="currentImage" 
            class="paper-image" 
            :style="{
              transform: `translate(${translateX}px, ${translateY}px) scale(${scale}) rotate(${rotation}deg)`,
              cursor: isDragging ? 'grabbing' : 'grab'
            }"
            @load="imageLoaded = true"
            draggable="false"
            :alt="'试卷图片'"
          />
          
          <div class="image-controls">
            <el-button-group>
              <el-button type="primary" plain @click="zoomIn">
                <el-icon><ZoomIn /></el-icon>
              </el-button>
              <el-button type="primary" plain @click="zoomOut">
                <el-icon><ZoomOut /></el-icon>
              </el-button>
              <el-button type="primary" plain @click="rotate">
                <el-icon><RefreshRight /></el-icon>
              </el-button>
              <el-button type="primary" plain @click="resetImage">
                <el-icon><RefreshLeft /></el-icon>
              </el-button>
            </el-button-group>
          </div>
        </div>
        <div v-else class="empty-image">
          <el-empty description="暂无图片" />
        </div>
      </el-main>
    </el-container>
    <template #footer>
      <el-button @click="handleClose">关闭</el-button>
    </template>
  </el-dialog>
</template>

<style scoped>
.empty-image {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: #f5f7fa;
}

.image-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f7fa;
}

.paper-image {
  max-width: 100%;
  max-height: 100%;
  transform-origin: center center;
  transition: transform 0.1s ease-out;
  user-select: none;
}

.image-controls {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 5px;
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

:deep(.el-tabs__content) {
  height: calc(100% - 40px);
  overflow: hidden;
}

:deep(.el-table__body-wrapper) {
  overflow-y: auto;
}

:deep(.el-dialog__body) {
  padding: 10px;
}

/* 表格行样式 */
:deep(.el-table tr) {
  cursor: pointer;
}

:deep(.el-table__row.current-row) {
  --el-table-tr-bg-color: var(--el-color-primary-light-8) !important;
  font-weight: bold;
}

:deep(.el-table__row:hover > td) {
  background-color: var(--el-color-primary-light-9) !important;
}

.is-loading {
  font-size: 24px;
  color: var(--el-color-primary);
  animation: rotating 2s linear infinite;
}

@keyframes rotating {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}

.el-tag {
  margin: 2px 0;
}
</style> 