/**
 * 主题管理工具类
 * 负责管理应用的主题切换、存储和更新
 */

// 主题类型枚举
export const ThemeMode = {
  LIGHT: 'light',
  DARK: 'dark',
  SYSTEM: 'system', // 跟随系统
}

// 主题状态存储键
const THEME_STORAGE_KEY = 'theme'

// 主题切换类
class ThemeManager {
  constructor() {
    this.currentTheme = localStorage.getItem(THEME_STORAGE_KEY) || ThemeMode.LIGHT
    this.systemDarkMediaQuery = window.matchMedia('(prefers-color-scheme: dark)')
    this.themeChangeCallbacks = []
    
    // 初始化系统主题变化监听
    this.setupSystemThemeListener()
  }
  
  /**
   * 初始化主题 - 应在应用启动时调用
   */
  init() {
    // 应用保存的主题或默认主题
    this.applyTheme(this.getEffectiveTheme())
  }
  
  /**
   * 设置系统主题变化监听
   */
  setupSystemThemeListener() {
    // 监听系统主题变化
    const handleSystemThemeChange = (e) => {
      if (this.currentTheme === ThemeMode.SYSTEM) {
        this.applyTheme(e.matches ? ThemeMode.DARK : ThemeMode.LIGHT, false)
        this.notifyThemeChanged()
      }
    }
    
    // 添加系统主题变化监听器
    this.systemDarkMediaQuery.addEventListener('change', handleSystemThemeChange)
  }
  
  /**
   * 获取当前生效的主题
   * @returns {string} - 当前实际生效的主题
   */
  getEffectiveTheme() {
    // 如果设置为跟随系统，则检查系统主题
    if (this.currentTheme === ThemeMode.SYSTEM) {
      return this.systemDarkMediaQuery.matches ? ThemeMode.DARK : ThemeMode.LIGHT
    }
    return this.currentTheme
  }
  
  /**
   * 获取用户设置的主题偏好
   * @returns {string} - 用户设置的主题
   */
  getThemePreference() {
    return this.currentTheme
  }
  
  /**
   * 切换主题
   * @param {string} theme - 要切换到的主题
   */
  setTheme(theme) {
    if (!Object.values(ThemeMode).includes(theme)) {
      console.error(`无效的主题: ${theme}`)
      return
    }
    
    this.currentTheme = theme
    localStorage.setItem(THEME_STORAGE_KEY, theme)
    
    const effectiveTheme = this.getEffectiveTheme()
    this.applyTheme(effectiveTheme)
    this.notifyThemeChanged()
  }
  
  /**
   * 切换到下一个主题
   * 顺序是: light -> dark -> system -> light
   */
  toggleTheme() {
    const themes = Object.values(ThemeMode)
    const currentIndex = themes.indexOf(this.currentTheme)
    const nextIndex = (currentIndex + 1) % themes.length
    this.setTheme(themes[nextIndex])
  }
  
  /**
   * 应用主题到DOM
   * @param {string} theme - 要应用的主题
   * @param {boolean} animate - 是否使用动画过渡
   */
  applyTheme(theme, animate = true) {
    if (theme === ThemeMode.DARK) {
      // 使用requestAnimationFrame进行渲染优化
      if (!animate) {
        this.disableAnimations()
      }
      
      requestAnimationFrame(() => {
        document.documentElement.classList.add('dark')
        document.body.setAttribute('data-theme', 'dark')
        
        if (!animate) {
          // 在下一帧重新启用动画
          requestAnimationFrame(() => {
            setTimeout(() => {
              this.enableAnimations()
            }, 0)
          })
        }
      })
    } else {
      if (!animate) {
        this.disableAnimations()
      }
      
      requestAnimationFrame(() => {
        document.documentElement.classList.remove('dark')
        document.body.setAttribute('data-theme', 'light')
        
        if (!animate) {
          requestAnimationFrame(() => {
            setTimeout(() => {
              this.enableAnimations()
            }, 0)
          })
        }
      })
    }
  }
  
  /**
   * 临时禁用动画过渡效果
   */
  disableAnimations() {
    const style = document.createElement('style')
    style.id = 'disable-animations'
    style.innerHTML = '* { transition: none !important; animation: none !important; }'
    document.head.appendChild(style)
  }
  
  /**
   * 重新启用动画过渡效果
   */
  enableAnimations() {
    const style = document.getElementById('disable-animations')
    if (style) {
      style.remove()
    }
  }
  
  /**
   * 注册主题变化回调函数
   * @param {Function} callback - 主题变化时调用的函数
   * @returns {Function} - 用于注销回调的函数
   */
  onThemeChange(callback) {
    if (typeof callback === 'function') {
      this.themeChangeCallbacks.push(callback)
      
      // 返回取消订阅函数
      return () => {
        const index = this.themeChangeCallbacks.indexOf(callback)
        if (index !== -1) {
          this.themeChangeCallbacks.splice(index, 1)
        }
      }
    }
  }
  
  /**
   * 通知所有已注册的回调主题已变化
   */
  notifyThemeChanged() {
    const effectiveTheme = this.getEffectiveTheme()
    this.themeChangeCallbacks.forEach(callback => {
      try {
        callback(effectiveTheme)
      } catch (error) {
        console.error('主题变化回调执行出错:', error)
      }
    })
    
    // 触发自定义事件，方便各组件监听
    window.dispatchEvent(new CustomEvent('theme-change', { 
      detail: { theme: effectiveTheme } 
    }))
  }
  
  /**
   * 检测是否为暗色模式
   * @returns {boolean} - 当前是否是暗色模式
   */
  isDarkMode() {
    return this.getEffectiveTheme() === ThemeMode.DARK
  }
}

// 创建单例实例
const themeManager = new ThemeManager()

export default themeManager 